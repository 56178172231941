import React, { useState, useEffect } from 'react';
import { Card, Avatar, Input, Select, Row, Col, Tooltip } from 'antd';
import { EyeOutlined, DownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from './styles';
import pdfIconImage from '../../../assets/pdf-icon.jpg';
import { fetchBrochureAsync } from '../../../actions/adminBrochure';

const { Meta } = Card;
const { Option } = Select;

const Forms = (props) => {
  const [filterFormsArray, setFilterFormsArray] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('All');
  const adminBrochure = useSelector((state) => state.adminBrochure);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchBrochureAsync(setLoading));
  }, []);

  useEffect(() => {
    let filterArray = language !== 'All' ? adminBrochure.filter(obj => obj.language === language) : adminBrochure;
    filterArray = filterArray.filter(obj => obj.title.toLowerCase().includes(searchValue.toLowerCase()));
    setFilterFormsArray(filterArray);
  }, [searchValue, language, adminBrochure]);

  // const printPDF = (selectedFile) => {
  //   var content = document.getElementById("divcontents");
  //   var pri = document.getElementById("ifmcontentstoprint").contentWindow;
  //   pri.document.open();
  //   pri.document.write(selectedFile);
  //   pri.document.close();
  //   pri.focus();
  //   pri.print();

  //   var pdf = "data:application/pdf;base64,"+selectedFile;
  //   window.open(pdf, '_blank');
  // };

  return (
    <>
      <div style={{ marginTop: 16 }}>
        <Input.Search
          style={{ width: 300, marginRight: 16 }}
          placeholder="Search Form"
          enterButton
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Select defaultValue="All" style={{ width: 120 }} onChange={setLanguage}>
          <Option value="All">All</Option>
          <Option value="ENGLISH">English</Option>
          <Option value="FRENCH">French</Option>
        </Select>
      </div>
      <div className={classes.formWrapper}>
        <Row gutter={[16, 16]}>
          {filterFormsArray.map((obj, index) => (
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xxl={{ span: 4 }} key={index}>
              <Card
                hoverable
                actions={[
                  <Tooltip title='View'>
                    <EyeOutlined onClick={(event) => { event.stopPropagation(); window.open(`${process.env.REACT_APP_API_BASE_URL}/brochure/${obj.id}/view/${obj.fileName}`, '_blank') }} />
                  </Tooltip>,
                  <Tooltip title='Download'>
                    <DownloadOutlined onClick={(event) => { event.stopPropagation(); window.open(`${process.env.REACT_APP_API_BASE_URL}/brochure/${obj.id}/download/${obj.fileName}`, '_blank') }} />
                  </Tooltip>
                ]}
              >
                <a href={obj.location} target="_blank" rel="noreferrer">
                  <Tooltip title={obj.title}>
                    <Meta
                      avatar={<Avatar src={pdfIconImage} size="large" />}
                      title={obj.title}
                      description={obj.language}
                      className={classes.meta}
                    />
                  </Tooltip>
                </a>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default Forms;