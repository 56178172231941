import axios from "../api/instance";
import { message } from "antd";

export const setClient = (client) => ({
  type: "SET_CLIENT",
  client
});

export const createClient = (clientData) => ({
  type: "ADD_CLIENT",
  clientData
});

export const createClientAsync = (client, setIsClientModalVisible, setLoading, setClientIdInAppointmentForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/client', client);
      const { data } = response;
      dispatch(createClient(data));
      setClientIdInAppointmentForm && setClientIdInAppointmentForm(data.id)
      message.success("Client has been created successfully!");
      setIsClientModalVisible && setIsClientModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create client');
      setLoading && setLoading(false);
    }
  };
};

export const updateClient = (clientData) => ({
  type: "UPDATE_CLIENT",
  clientData
});

export const updateClientAsync = (client, setIsClientModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/client/${client.id}`, client);
      const { data } = response;
      dispatch(updateClient(data));
      message.success("Client has been updated successfully!");
      setIsClientModalVisible && setIsClientModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update client');
      setLoading && setLoading(false);
    }
  };
};