import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Button } from '@material-ui/core';
import { message } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import queryString from 'query-string';
import { useStyles } from "./styles";
import axios from "../../api/instance";
import { history } from "../../App";
import ShopManagerLogo from '../../assets/ShopManagerLogoBlue.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`© ${new Date().getFullYear()} `}
      <Link color="inherit" href="https://www.corrosiondefence.com/" target="_blank" >
        Corrosion Defense Inc. &nbsp;
      </Link>
      All Rights Reserved
    </Typography>
  );
}

const ResetPassword = (props) => {
  const [password, setPassword] = useState(null);
  const [confirmpassword, setConfirmPassword] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const params = queryString.parse(props.location.search);
  const { email, token } = params;

  const resetPassword = async () => {
    setLoading(true);
    try {
      const response = await axios.put('/auth/reset-password', { email, newPassword: password, resetPasswordToken: token });
      const { data } = response;   
      message.success('Password successfully reset'); 
      console.log(data);
      history.push('/');
    } catch(err) {
      console.log(err.message);
      message.error('Not able to reset password');
      setLoading(false);
    }
  };

  return (
    <div className={classes.rootContainer}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            className={classes.avatar}
            src={ShopManagerLogo} alt="logo" >
          </img>
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          <form 
            className={classes.form} 
            validate="true" 
            onSubmit={(event) => {
              event.preventDefault();
              password === confirmpassword ? resetPassword() : message.info('Password are not same');
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              label="Email Address"
              value={email}
              disabled
            />
             <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              type="password"
              label={password !== "" ? "Password" : "Please enter password"}
              onChange={(event) => setPassword(event.target.value)}
              value={password !== null ? password : ''}
              error={password === ""}
            />
             <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="confirmpassword"
              name="confirmpassword"
              type="password"
              label={confirmpassword !== "" ? "Confirm password" : "Please enter password"}
              onChange={(event) => setConfirmPassword(event.target.value)}
              value={confirmpassword !== null ? confirmpassword : ''}
              error={confirmpassword === ""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Reset Password"}
            </Button>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default ResetPassword;