const vehicleReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_VEHICLE":
      return action.vehicle;
    case "ADD_VEHICLE":
      return [...state, action.vehicleData];
    case "UPDATE_VEHICLE":
      return state.map(vehicle => vehicle.id === action.vehicleData.id ? action.vehicleData : vehicle);
    default:
      return state;
  }
};

export default vehicleReducer;