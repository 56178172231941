const orderReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_ORDER":
      return action.order;
    case "ADD_ORDER":
      return [...state, action.orderData];
    case "UPDATE_ORDER":
      return state.map(order => order.id === action.orderData.id ? action.orderData : order);
    default:
      return state;
  }
};

export default orderReducer;