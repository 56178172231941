import axios from "../api/instance";
import { message } from "antd";

export const setAppointment = (appointment) => ({
  type: "SET_APPOINTMENT",
  appointment
});

export const createAppointment = (appointmentData) => ({
  type: "ADD_APPOINTMENT",
  appointmentData
});

export const createAppointmentAsync = (appointment, setLoading, setIsModal) => {

  return async (dispatch) => {
    try {
      const response = await axios.post(`/client/${appointment.clientId}/vehicle/${appointment.vehicleId}/appointment`, appointment);
      const { data } = response;
      dispatch(createAppointment(data));
      message.success("Appointment has been created successfully!");
      setLoading && setLoading(false);
      setIsModal && setIsModal(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create Appointment');
      setLoading && setLoading(false);
      setIsModal && setIsModal(false);
    }
  };
};

export const updateAppointment = (appointmentData) => ({
  type: "UPDATE_APPOINTMENT",
  appointmentData
});

export const updateAppointmentAsync = (appointment,  setLoading,setFormData,) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/client/${appointment.clientId}/vehicle/${appointment.vehicleId}/appointment/${appointment.id}`, appointment);
      const { data } = response;
      dispatch(updateAppointment(data));
      message.success("Appointment has been updated successfully!");
      setLoading && setLoading(false);
      setFormData && setFormData(null);
    } catch(err) {
      console.log(err);
      message.error('Failed to update Appointment');
      setLoading && setLoading(false);
      setFormData && setFormData(null);
    }
  };
};

export const deleteAppointment = (appointmentId) => ({
  type: "DELETE_APPOINTMENT",
  appointmentId
});

export const deleteAppointmentAsync =  async (appointmentId, setLoading, setIsModal) => {
    try {
      setLoading(true);
      await axios.delete(`/appointment/${appointmentId}`);
      setLoading(false);
      setIsModal && setIsModal(false);
      message.success("Appointment has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete appointment');
      setLoading(false);
      setIsModal && setIsModal(false);
    }
};