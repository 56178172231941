/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Switch, Table, Input, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import ProductForm from './ProductForm';
import { setProduct, updateProductAsync } from '../../../actions/product'

const Product = (props) => {
  const [isProductModalvisible, setIsProductModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterProduct, setFilterProduct] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let product = useSelector((state) => state.product);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/product');
        dispatch(setProduct(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch product");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [product]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Size',
      dataIndex: 'size',
      key: 'size',
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Price',
      dataIndex: 'price',
      key: 'price',
      render: (price) => `$${price}`,
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Retail Price',
      dataIndex: 'retailPrice',
      key: 'retailPrice',
      render: (retailPrice) => `$${retailPrice}`,
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => <Switch checked={isActive} loading={loading} onChange={() => {
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isActive = !isActive;
        dispatch(updateProductAsync(newRecord, null, setLoading));
      }} />,
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsProductModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '10%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = product.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterProduct(filterTable);
    } else {
      setFilterProduct(null);
    }
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsProductModalVisible(true); setFormData(null) }} style={{ marginRight: 10 }}>
          Create Product
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterProduct == null ? product : filterProduct}
        rowKey={obj => obj.id}
        size="small"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Product" : "Create Product"}
        centered
        destroyOnClose
        visible={isProductModalvisible}
        onOk={() => setIsProductModalVisible(false)}
        onCancel={() => setIsProductModalVisible(false)}
        width={800}
        footer={null}
      >
        <ProductForm formData={formData} setIsProductModalVisible={setIsProductModalVisible} />
      </Modal>
    </>
  );
}

export default Product;