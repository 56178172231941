const supplyReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_SUPPLY":
      return action.supply;
    case "ADD_SUPPLY":
      return [...state, action.supplyData];
    case "UPDATE_SUPPLY":
      return state.map(supply => supply.id === action.supplyData.id ? action.supplyData : supply);
    default:
      return state;
  }
};

export default supplyReducer;