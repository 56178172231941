/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, Row, Col, Switch } from 'antd';
// import { useStyles } from './styles'
import { updateSupplierAsync, createSupplierAsync } from '../../../actions/supplier';

const SupplierForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    if (formData) {
      const supplierData = {...formData, ...values.supplier};
      dispatch(updateSupplierAsync(supplierData, props.setIsSupplierModalVisible, setLoading));
    } else {
      dispatch(createSupplierAsync(values.supplier, props.setIsSupplierModalVisible, setLoading));
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="supplier_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'name']}
            label={'Name'}
            initialValue={formData ? formData['name'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Name'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'contactName']}
            label={'Contact Name'}
            initialValue={formData ? formData['contactName'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Contact Name'} maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'addressLine1']}
            label={'Address Line 1'}
            initialValue={formData ? formData['addressLine1'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Address Line 1'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'addressLine2']}
            label={'Address Line 2'}
            initialValue={formData ? formData['addressLine2'] : ''}
          >
            <Input placeholder={'Address Line 2'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'city']}
            label={'City'}
            initialValue={formData ? formData['city'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'City'} maxLength={30}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'state']}
            label={'State/Province'}
            initialValue={formData ? formData['state'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'State/Province'} maxLength={30}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'zipcode']}
            label={'Zip/Postal Code'}
            initialValue={formData ? formData['zipcode'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Zip Code/Postal Code'} maxLength={10}/>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'phone']}
            label={'Phone'}
            initialValue={formData ? formData['phone'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Phone'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'email']}
            label={'Email'}
            initialValue={formData ? formData['email'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Email'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'fax']}
            label={'Fax'}
            initialValue={formData ? formData['fax'] : ''}
          >
            <Input placeholder={'Fax'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supplier', 'isActive']}
            label="Status"
            initialValue={formData ? formData.isActive : true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SupplierForm;