const adminCDProductReducer = (state = [], action) => {
    switch (action.type) {
      case "SET_ADMIN_CD_PRODUCT":
        return action.cdProduct;
      case "ADD_ADMIN_CD_PRODUCT":
        return [...state, action.cdProduct];
      case "UPDATE_ADMIN_CD_PRODUCT":
        return state.map(adminCDProduct => adminCDProduct.id === action.cdProduct.id ? action.cdProduct : adminCDProduct);
      case "DELETE_ADMIN_CD_PRODUCT":
        return state.filter(adminCDProduct => adminCDProduct.id !== action.cdProductId);
  
      default:
        return state;
    }
  };
  
  export default adminCDProductReducer;