/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Input, Switch, Button, Row, Col } from 'antd';
// import { useStyles } from './styles'
import { createShopAsync, updateShopAsync } from '../../../actions/adminShop';

const ShopForm = (props) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const onFinish = (values) => {
    setLoading(true);
    if (formData) {
      values.user.shopId = formData.shopId;
      dispatch(updateShopAsync(values.user, props.onCloseShopDrawer, setLoading));
    } else {
      dispatch(createShopAsync(values.user, props.onCloseShopDrawer, setLoading));
    }
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  return (
    <Form
      {...formItemLayout}
      name="shop_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'shopName' : 'shopName']}
            label="Shop Name"
            initialValue={formData ? formData.shopName : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Shop Name'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'shopEmail' : 'shopEmail']}
            label="Shop Email"
            initialValue={formData ? formData.shopEmail : null}
            rules={[
              {
                type: 'email',
                required: true,
              },
            ]}
          >
            <Input placeholder={'Shop Email'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'shopAddressLine1' : 'shopAddressLine1']} label="Address Line 1" initialValue={formData ? formData.shopAddressLine1 : null} >
            <Input placeholder={'Address Line 1'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'shopAddressLine2' : 'shopAddressLine2']} label="Address Line 2" initialValue={formData ? formData.shopAddressLine2 : null}>
            <Input placeholder={'Address Line 2'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'shopCity' : 'shopCity']}
            label={'City'}
            initialValue={formData ? formData['shopCity'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'City'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'shopState' : 'shopState']}
            label={'State/Province'}
            initialValue={formData ? formData['shopState'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'State/Province'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'shopZipcode' : 'shopZipcode']}
            label={'Zip/Postal Code'}
            initialValue={formData ? formData['shopZipcode'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Zip Code/Postal Code'} maxLength={10} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'shopTelephone' : 'shopTelephone']} label="Shop Telephone" initialValue={formData ? formData.shopTelephone : null}>
            <Input placeholder={'Shop Telephone'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'ownerFirstname' : 'ownerFirstname']} label="Owner Firstname" rules={[{ required: true }]} initialValue={formData ? formData.ownerFirstname : null}>
            <Input placeholder={'Owner Firstname'} maxLength={25} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'ownerLastname' : 'ownerLastname']} label="Owner Lastname" initialValue={formData ? formData.ownerLastname : null}>
            <Input placeholder={'Owner Lastname'} maxLength={25} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['user', formData ? 'ownerEmail' : 'ownerEmail']}
            label="Owner Email"
            rules={[
              {
                type: 'email',
                required: true,
              },
            ]}
            initialValue={formData ? formData.ownerEmail : null}
          >
            <Input placeholder={'Owner Email'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', formData ? 'website' : 'website']} label="Website" initialValue={formData ? formData.website : null}>
            <Input placeholder={'Website'} maxLength={255} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', 'isActive']} valuePropName="checked" initialValue={formData ? formData.isActive : true} label="Shop Status">
            <Switch />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name={['user', 'isDemoShop']} valuePropName="checked" initialValue={formData ? formData.isDemoShop : true} label="Demo Shop">
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ShopForm;