/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Popconfirm, Select,  Input, Card, Avatar, Row, Col, Tooltip } from 'antd';
import { DeleteOutlined, EyeOutlined, DownloadOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from "./styles";
import BrochureForm from './BrochureForm';
import pdfIconImage from '../../../assets/pdf-icon.jpg';
import { deleteBrochureAsync, fetchBrochureAsync } from '../../../actions/adminBrochure';

const { Meta } = Card;
const { Option } = Select;

const Brochure = () => {
  const [filterBrochuresArray, setFilterBrochuresArray] = useState([]);
  const [brochureData, setBrochureData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [language, setLanguage] = useState('All');
  const adminBrochure = useSelector((state) => state.adminBrochure);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchBrochureAsync(setLoading));
  }, []);

  useEffect(() => {
    let filterArray = language !== 'All' ? adminBrochure.filter(obj => obj.language === language) : adminBrochure;
    filterArray = filterArray.filter(obj => obj.title.toLowerCase().includes(searchValue.toLowerCase()));
    setFilterBrochuresArray(filterArray);
  }, [searchValue, language, adminBrochure]);

  return (
    <>
      <Row span={24} style={{ margin: "10px 0px", display: 'flex' }}>
        <Col span={16}>
          <BrochureForm brochureData={brochureData} />
        </Col>
        <Col span={8}>
          <Select defaultValue="All" style={{ width: 160, marginRight: 10 }} onChange={setLanguage}>
            <Option value="All">All</Option>
            <Option value="ENGLISH">English</Option>
            <Option value="FRENCH">French</Option>
          </Select>
          <Input.Search
            style={{ width: 300 }}
            placeholder="Search Form"
            enterButton
            onChange={(event) => setSearchValue(event.target.value)}
          />
        </Col>
      </Row>
      <div className={classes.brochureWrapper}>
        <Row gutter={[16, 16]}>
          {filterBrochuresArray.map((obj, index) => (
            <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }} xxl={{ span: 4 }} key={index}>
              <Card
                hoverable
                actions={[
                  <Tooltip title='View'>
                    <EyeOutlined onClick={(event) => { event.stopPropagation(); window.open(`${process.env.REACT_APP_API_BASE_URL}/brochure/${obj.id}/view/${obj.fileName}`, '_blank') }} />
                  </Tooltip>,
                  <Tooltip title='Download'>
                    <DownloadOutlined onClick={(event) => { event.stopPropagation(); window.open(`${process.env.REACT_APP_API_BASE_URL}/brochure/${obj.id}/download/${obj.fileName}`, '_blank') }} />
                  </Tooltip>,
                  // <Tooltip title='Edit'>
                  //   <EditOutlined onClick={() => { setBrochureData(obj); brochureDrawer() }} />
                  // </Tooltip>,
                  <Popconfirm title="Are you sure want to delete ?" okText="Yes" cancelText="No" onConfirm={() => { dispatch(deleteBrochureAsync(obj.id, setLoading)) }}>
                    <Tooltip title='Delete'>
                      <DeleteOutlined />
                    </Tooltip>
                  </Popconfirm>,
                ]}
              >
                <a href={obj.location} target="_blank" rel="noreferrer">
                  <Tooltip title={obj.title}>
                    <Meta
                      avatar={<Avatar src={pdfIconImage} size="large" />}
                      title={obj.title}
                      description={obj.language}
                      className={classes.meta}
                    />
                  </Tooltip>
                </a>
              </Card>
            </Col>
          ))}
        </Row>
      </div>
    </>
  )
}
export default Brochure;