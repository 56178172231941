import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from 'react-redux'
import { Layout, Typography } from "antd";
import Link from '@material-ui/core/Link';
import ShopMenu from "../../components/header/ShopManu";
import ShopHeader from "../../components/header/ShopHeader";
import Dashboard from "./dashboard/Dashboard";
import Client from "./client/Client";
import Vehicle from "./vehicle/Vehicle";
import VehicleService from "./vehicle/vehicleService/VehicleService";
import Vendor from "./vendor/Vendor";
import Supply from "./supply/Supply";
import Product from "./product/Product";
import Forms from "./forms/forms";
import Tax from "./tax/Tax";
import Report from "./report/Report";
import Profile from "./Profile/profile";
import Setting from "./setting/Setting";
import Order from "./order/Order";
import Appointment from "./appointment/Appointment";
import AppointmentCalendar from "./appointment/AppointmentCalendar";

const { Content, Footer } = Layout;

const Shop = (props) => {
  const isCollapsed = useSelector(state => state.isCollapsed);
  return (
    <Layout style={{ minHeight: '100vh', minWidth: '100vw' }}>
      <ShopMenu />
      <Layout>
        <ShopHeader />
        <Content style={{ marginTop: 64, marginRight: 16, marginLeft: isCollapsed ? 80 + 16 : 240 + 16 }}>
          <Switch>
            <Route exact path="/shop/clients" component={Client} />
            <Route exact path="/shop/register-appointment" component={Appointment} />
            <Route exact path="/shop/register-appointment/:appointmentId" component={Appointment} />
            <Route exact path="/shop/clients/:clientId" component={Vehicle} />
            <Route exact path="/shop/clients/:clientId/vehicle/:vehicleId" component={VehicleService} />
            <Route exact path="/shop/vendors" component={Vendor} />
            <Route exact path="/shop/products" component={Product} />
            <Route exact path="/shop/reports" component={Report} />
            <Route exact path="/shop/taxes" component={Tax} />
            <Route exact path="/shop/supply" component={Supply} />
            <Route exact path="/shop/forms" component={Forms} />
            <Route exact path="/shop/user/profile" component={Profile} />
            <Route exact path="/shop/setting" component={Setting} />
            <Route exact path="/shop/order" component={Order} />
            <Route exact path="/shop/appointment-calendar" component={AppointmentCalendar} />
            <Route component={Dashboard} />
          </Switch>
        </Content>
        <Footer style={{ textAlign: 'center', marginLeft: isCollapsed ? 80 : 240}}>
          <Typography variant="body2" color="textSecondary" align="center">
            {`© ${new Date().getFullYear()} `}
            <Link color="inherit" href="https://www.corrosiondefence.com/" target="_blank" >
              Corrosion Defense Inc. &nbsp;
            </Link>
            All Rights Reserved
          </Typography>
        </Footer>
      </Layout>
    </Layout>
  );
}

export default Shop;