const adminProductReducer = (state = [], action) => {
    switch (action.type) {
      case "SET_ADMIN_PRODUCT":
        return action.Product;
      case "ADD_ADMIN_PRODUCT":
        return [...state, action.Product];
      case "UPDATE_ADMIN_PRODUCT":
        return state.map(adminProduct => adminProduct.id === action.Product.id ? action.Product : adminProduct);
      case "DELETE_ADMIN_PRODUCT":
        return state.filter(adminProduct => adminProduct.id !== action.ProductId);
  
      default:
        return state;
    }
  };
  
  export default adminProductReducer;