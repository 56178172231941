/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Select, Row, Col, message, Input, Tag, DatePicker, Modal } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useReactToPrint } from 'react-to-print';
import ReactExport from 'react-export-excel';
import axios from '../../../api/instance';
import { setClient } from "../../../actions/client";
import { setProduct } from "../../../actions/product";
// import { history } from '../../../App';
import { useStyles } from './styles';
import Invoice from '../../../components/invoice/Invoice';
import InvoiceFrench from '../../../components/invoice/InvoiceFrench';
import InvoiceSpanish from '../../../components/invoice/InvoiceSpanish';

const { Option } = Select;
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const InvoiceReport = () => {
  const [clientIds, setClientIds] = useState([]);
  const [productIds, setProductIds] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [dateStrings, setdateStrings] = useState(['', '']);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filterInvoice, setFilterInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [isPrintInvoiceModalvisible, setIsPrintInvoiceModalvisible] = useState(false);
  const [formData, setFormData] = useState({});
  const [clientData, setClientData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [page, setPage] = useState(1);
  const clients = useSelector(state => state.client);
  const products = useSelector((state) => state.product);
  const dispatch = useDispatch();
  const componentRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client');
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
    }
    if (clients.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/product');
        dispatch(setProduct(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch product");
      }
    }
    if (products.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    clientIds.length === 0 && setInvoiceData([]); setFilterInvoice(null);
  }, [clientIds]);

  useEffect(() => {
    search();
  }, [invoiceData]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'clientSequenceId',
      key: 'clientSequenceId',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'clientFirstname',
      key: 'clientFirstname',
      render: (text, record) => `${record.client.firstname} ${record.client.lastname}`,
      ellipsis: true
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'vehicleModel',
      key: 'vehicleModel',
      render: (text, record) => `${record.vehicle.model}`,
      ellipsis: true
    },
    {
      title: 'Vehicle Make',
      dataIndex: 'vehicleMake',
      key: 'vehicleMake',
      render: (text, record) => `${record.vehicle.make}`,
      ellipsis: true
    },

    {
      title: 'Warranty',
      dataIndex: 'isOnWarranty',
      key: 'isOnWarranty',
      render: (text, record) => record.vehicle.isOnWarranty ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>,
      ellipsis: true,
    },
    {
      title: 'Warranty Ex',
      dataIndex: 'warrantyExpiryDate',
      key: 'warrantyExpiryDate',
      render: (text, record) => record.vehicle.warrantyExpiryDate,
      ellipsis: true
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (text, record) => `$${record.invoice.taxAmount1 + record.invoice.taxAmount2 + record.invoice.serviceAmount}`,
      ellipsis: true
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => setViewInvoiceData(record)}>View</Button>,
      ellipsis: true,
      width: '8%'
    },
  ];

  const setViewInvoiceData = (record) => {
    setFormData(record.invoice);
    setClientData(record.client);
    setVehicleData(record.vehicle);
    setIsPrintInvoiceModalvisible(true);
  }

  const search = value => {
    if (searchValue) {
      const filterTable = invoiceData.filter(o =>
        Object.keys(o).some(k => {
          return o[k] && Object.keys(o[k]).some(j =>
            String(o[k][j])
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
        })
      );
      setFilterInvoice(filterTable);
    } else {
      setFilterInvoice(null);
    }
  };

  const fetchClient = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/shop/report/search-invoice', {
        params: {
          clientIds: clientIds.join(),
          productIds: productIds.join(),
          paymentStatus,
          fromInvoiceDate: dateStrings[0],
          toInvoiceDate: dateStrings[1]
        }
      });
      setInvoiceData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch report");
      setLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  let exportData = filterInvoice == null ? invoiceData : filterInvoice;
  exportData = exportData.map(obj => ({ ...obj.client, ...obj.invoice, ...obj.vehicle }));

  return (
    <>
      <Row gutter={[12, 0]}>
        <Col span={6}>Client</Col>
        <Col span={6}>Product</Col>
        <Col span={4}>Payment Status</Col>
        <Col span={6}>Date</Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginBottom: 10 }}>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select Client"
            onChange={(clientIdArray) => setClientIds(clientIdArray)}
          >
            {clients.map(obj => <Option key={obj.id} value={obj.id}>{`${obj.firstname} ${obj.lastname}`}</Option>)}
          </Select>
        </Col>
        <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select Product"
            onChange={(productIdArray) => setProductIds(productIdArray)}
          >
            {products.filter(obj => obj.isActive === true).map(obj => <Option key={obj.id} value={obj.id}>{`${obj.description}-${obj.size}`}</Option>)}
          </Select>
        </Col>
        <Col span={4}>
          <Select onChange={(value) => setPaymentStatus(value)} style={{ width: '100%' }} >
            <Option value=''>All</Option>
            <Option value='PAID'>Paid</Option>
            <Option value='PENDING'>Pending</Option>
          </Select>
        </Col>
        <Col span={6}>
          <RangePicker onChange={(_, dateStrings) => setdateStrings(dateStrings)} style={{ width: '100%' }} />
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={fetchClient} style={{ width: '100%' }} loading={loading}>
            Search
          </Button>
        </Col>
      </Row>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px', marginLeft: 12 }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <ExcelFile element={<CloudDownloadOutlined style={{ fontSize: 25, cursor: 'pointer' }} />}>
          <ExcelSheet data={exportData} name="Clients">
            <ExcelColumn label="First Name" value="firstname" />
            <ExcelColumn label="Last Name" value="lastname" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Address Line 1" value="addressLine1" />
            <ExcelColumn label="Address Line 2" value="addressLine2" />
            <ExcelColumn label="City" value="city" />
            <ExcelColumn label="Zip Code" value="zipcode" />
            <ExcelColumn label="Telephone" value="telephone" />
            <ExcelColumn label="Invoice Language" value="invoiceLanguage" />
            <ExcelColumn label="Mail Preference" value="mailPreference" />

            <ExcelColumn label="Vehicle Make" value="make" />
            <ExcelColumn label="Vehicle Model" value="model" />
            <ExcelColumn label="Mileage" value="mileage" />
            <ExcelColumn label="Vin" value="vin" />
            <ExcelColumn label="Year" value="year" />
            <ExcelColumn label="Warranty Status" value="isOnWarranty" />
            <ExcelColumn label="Warranty Expiry Date" value="warrantyExpiryDate" />

            <ExcelColumn label="Invoice Date" value="invoiceDate" />
            <ExcelColumn label="Payment Status" value="paymentStatus" />
            <ExcelColumn label="Payment Term" value="paymentTerm" />
            <ExcelColumn label="Service Amount" value="serviceAmount" />
            <ExcelColumn label="Discount Amount" value="discountAmount" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Table
        columns={columns}
        dataSource={filterInvoice == null ? invoiceData : filterInvoice}
        rowKey={obj => obj.invoice.id}
        size="small"
        // onRow={(record, rowIndex) => {
        //   return {
        //     onClick: event => {
        //       history.push(`/shop/clients/${record.clientId}/vehicle/${record.vehicleId}`);
        //     },
        //   };
        // }}
        // style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        bordered
      />
      <Modal
        title={<Button type="primary" onClick={handlePrint}>Print</Button>}
        centered
        destroyOnClose
        visible={isPrintInvoiceModalvisible}
        onOk={() => setIsPrintInvoiceModalvisible(false)}
        onCancel={() => setIsPrintInvoiceModalvisible(false)}
        width={800}
        footer={null}
      >
        <div ref={componentRef}>
          {
            clientData.invoiceLanguage === 'FRENCH' ?
              <InvoiceFrench formData={formData} client={clientData} vehicle={vehicleData} /> :
              clientData.invoiceLanguage === 'SPANISH' ?
                <InvoiceSpanish formData={formData} client={clientData} vehicle={vehicleData} /> :
                <Invoice formData={formData} client={clientData} vehicle={vehicleData} />
          }
        </div>
      </Modal>
    </>
  );
}

export default InvoiceReport;