import React from 'react';
import { Divider, Row, Col } from 'antd';
import { useStyles } from './styles';
import InvoiceEnglishCDLogo from '../../assets/InvoiceEnglishCDLogo.png'

const InvoiceSpanish = (props) => {
  const { client, vehicle, formData, shopData } = props;
  let auth = localStorage.getItem('auth');
  auth = auth ? JSON.parse(auth) : null;
  // const { shop } = auth;
  const shop = shopData ? shopData : auth.shop
  const classes = useStyles();
  const paymentDisplay = {
    "CASH": "Al Contado",
    "CREDIT_CARD": "Tarjeta de crédito",
    "THIRTY_DAY_TERM": "30 Días",
    "PAID": "Pagado",
    "PENDING": "Pendiente",
  };

  return (
    <div style={{ width: '100%', backgroundColor: 'white', padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img
          src={InvoiceEnglishCDLogo}
          alt="logo"
          style={{ height: 100 }}
        />
        <h1 style={{ fontSize: 40 }}>FACTURA</h1>
      </div>
      <Divider style={{ height: 1, backgroundColor: 'black', margin: '12px 0' }} />
      <div className={classes.colPadding}>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <b>Empresa</b><br />
            {shop.name}<br />
            {`${shop.addressLine1}${shop.addressLine2 ? `, ${shop.addressLine2}` : ''}`}<br />
            {`${shop.city}, ${shop.state}, ${shop.zipcode}`}<br />
            {shop.telephone}{shop.telephone && <br />}
            {shop.email}
          </Col>
          <Col span={12}>
            Fecha: {formData.invoiceDate}<br />
            Factura #: {formData.sequenceId}<br />
            Cliente #: {client.sequenceId}<br />
            Empresa #: {shop.businessNumber || 'Not applicable'}
          </Col>
        </Row>
      </div>
      <Divider style={{ height: 1, backgroundColor: 'black', margin: '12px 0' }} />
      <div>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <b>Cliente</b><br />
            {`${client.firstname} ${client.lastname}`}<br />
            {client.company}{client.company && <br />}
            {`${client.addressLine1} ${client.addressLine2 ? `, ${client.addressLine2}` : ''}`}<br />
            {`${client.city}, ${client.zipcode}`}<br />
            {client.telephone}
          </Col>
          <Col span={12}>
            <b>Vehiculo</b><br />
            {vehicle.make}<br />
            {vehicle.model}<br />
            {vehicle.year}{vehicle.year && <br />}
            {vehicle.vin}<br />
          </Col>
        </Row>
      </div>
      <table style={{ width: '100%', marginTop: 10 }} className={classes.root} cellPadding="5px">
        <thead>
          <tr>
            <th>#</th>
            <th>Descripción</th>
            <th>Cant</th>
            <th>Precio Unitario</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {formData.invoiceLineItems.map((obj, index) => (
            <tr key={obj.id}>
              <td style={{ textAlign: 'center' }}>{index + 1}</td>
              <td>{obj.product.description} - {obj.product.size}</td>
              <td style={{ textAlign: 'center' }}>{obj.quantity}</td>
              <td style={{ textAlign: 'right' }}>${Number(obj.product.retailPrice).toFixed(2)}</td>
              {/* <td style={{ textAlign: 'right' }}>${Number(obj.totalAmount).toFixed(2)}</td> */}
              <td style={{ textAlign: 'right' }}>${Number(obj.quantity * obj.product.retailPrice).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          {formData.taxRate1 ?
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3} style={{ textAlign: 'right' }}>{`${formData.taxRate1 ? formData.taxRate1.name : '(Taxes 1)'} - (${formData.taxRate1 ? formData.taxRate1.percentage : 0}%)`}</td>
              <td style={{ textAlign: 'right' }}>${Number(formData.taxAmount1).toFixed(2)}</td>
            </tr> : null}
          {formData.taxRate2 ?
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3} style={{ textAlign: 'right' }}>{`${formData.taxRate2 ? formData.taxRate2.name : '(Taxes 2)'} - (${formData.taxRate2 ? formData.taxRate2.percentage : 0}%)`}</td>
              <td style={{ textAlign: 'right' }}>${Number(formData.taxAmount2).toFixed(2)}</td>
            </tr> : null}
          {
            formData?.discountAmount > 0.0 && (
              <tr>
                <td>&nbsp;</td>
                <td colSpan={3} style={{ textAlign: 'right' }}>Discount </td>
                <td style={{ textAlign: 'right' }}>${formData?.discountAmount ? Number(formData?.discountAmount).toFixed(2) : 0}</td>
              </tr>
            )
          }
          <tr>
            <td colSpan={2} style={{ border: 0, marginRight: 10, fontWeight: 'bold' }}>Términos de Pago: {paymentDisplay[formData.paymentTerm]}</td>
            <td colSpan={2} style={{ border: 0, textAlign: 'right', marginRight: 10, fontWeight: 'bold' }}></td>
            <td style={{ textAlign: 'right', fontWeight: '600' }}>${Number(formData.serviceAmount).toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: 0, marginRight: 10, fontWeight: 'bold' }}>Estado de Pago: {paymentDisplay[formData.paymentStatus]}</td>
            <td colSpan={2} style={{ border: 0, textAlign: 'right', marginRight: 10, fontWeight: 'bold' }}>Total</td>
            <td style={{ textAlign: 'right', fontWeight: '600' }}>${Number(formData.serviceAmount + formData.taxAmount1 + formData.taxAmount2 - formData?.discountAmount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <br />

      <footer><center><strong>www.corrosiondefence.com</strong></center></footer>
    </div>
  )
};

export default InvoiceSpanish;