import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import "normalize.css/normalize.css";
import './index.css';
import "antd/dist/antd.css";
import reportWebVitals from './reportWebVitals';
import App from './App';
import { login } from "./actions/auth";
import { history } from "./App";
import queryString from 'query-string';

const rootElement = document.getElementById("root");
const store = configureStore();

const jsx = (
  <Provider store={store}>
    <App />
  </Provider>
);

let hasRendered = false;
const renderApp = () => {
  if (!hasRendered) {
    ReactDOM.render(jsx, rootElement);
    hasRendered = true;
  }
};

ReactDOM.render(<div>Loading...</div>, rootElement);

let auth = localStorage.getItem('auth');
if (auth) {
  auth = JSON.parse(auth);
  store.dispatch(login(auth));
  renderApp();
} else {
  const path = history.location.pathname; 
  const params = queryString.parse(history.location.search);
  const { email, token } = params;
  if (path === '/') {
    history.push('/');
  } else if (path === '/reset-password') {    
    history.push(`/reset-password?email=${email}&token=${token}`);
  } else {
    history.push({
      pathname: '/',
      search: `?r=${path}`,
    });
  }
  renderApp();
}

reportWebVitals();
