import axios from "../api/instance";
import { message } from "antd";

export const setSupplier = (supplier) => ({
  type: "SET_SUPPLIER",
  supplier
});

export const createSupplier = (supplierData) => ({
  type: "ADD_SUPPLIER",
  supplierData
});

export const createSupplierAsync = (supplier, setIsSupplierModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/supplier', supplier);
      const { data } = response;
      dispatch(createSupplier(data));
      message.success("Supplier has been created successfully!");
      setIsSupplierModalVisible && setIsSupplierModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create supplier');
      setLoading && setLoading(false);
    }
  };
};

export const updateSupplier = (supplierData) => ({
  type: "UPDATE_SUPPLIER",
  supplierData
});

export const updateSupplierAsync = (supplier, setIsSupplierModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/supplier/${supplier.id}`, supplier);
      const { data } = response;
      dispatch(updateSupplier(data));
      message.success("Supplier has been updated successfully!");
      setIsSupplierModalVisible && setIsSupplierModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update supplier');
      setLoading && setLoading(false);
    }
  };
};