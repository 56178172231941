import React from "react";
import { Switch, Route } from "react-router-dom";
import { Layout, Typography } from "antd";
import Link from '@material-ui/core/Link';
import AdminManu from "../../components/header/AdminManu";
import { useStyles } from "./styles";
import Shop from "./shop/Shop";
import Report from "./report/Report";
import DashBoard from "./dashboard/Dashboard";
import Profile from "../shop/Profile/profile";
import Order from "./order/Order";
import Brochure from "./Brochure/Brochure";
import CDProduct from "./CDProducts/CDProduct";
import Product from "./product/Product";

const { Content, Footer } = Layout;

const Admin = (props) => {
  const classes = useStyles();
  return (
    <Layout style={{ minHeight: '100vh', minWidth: '100vw' }}>
      <AdminManu />
      <Content className={classes.content}>
        <Switch>
          <Route exact path="/admin/orders" component={Order} />
          <Route exact path="/admin/shops" component={Shop} />
          <Route exact path="/admin/reports" component={Report} />
          <Route exact path="/admin/profile" component={Profile} />
          <Route exact path="/admin/brochure" component={Brochure} />
          <Route exact path="/admin/cd-product" component={CDProduct} />
          <Route exact path="/admin/default-product" component={Product} />
          <Route component={DashBoard} />
        </Switch>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary" align="center">
          {`© ${new Date().getFullYear()} `}
          <Link color="inherit" href="https://www.corrosiondefence.com/" target="_blank" >
            Corrosion Defense Inc. &nbsp;
            </Link>
            All Rights Reserved
          </Typography>
      </Footer>
    </Layout>
  );
}

export default Admin;