import React from 'react';
import Supplier from '../supplier/Supplier';

const Vendor = () => {
  return (
    <>
      <Supplier />
    </>
  );
}

export default Vendor;