/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Modal, Switch, Table, Input, message, PageHeader, Tag, Row, Space } from 'antd';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import { useReactToPrint } from 'react-to-print';
import axios from '../../../../api/instance';
import { useStyles } from './styles';
import VehicleServiceForm from './VehicleServiceForm';
import { setVehicleService, updateVehicleServiceAsync } from '../../../../actions/vehicleService';
import { setInvoiceFile, addInvoiceFile, deleteInvoiceFileAsync } from '../../../../actions/invoiceFile';
import { setClient } from '../../../../actions/client';
import { setVehicle } from "../../../../actions/vehicle";
import { setTax } from "../../../../actions/tax";
import { setProduct } from "../../../../actions/product";
import clientAvatar from "../../../../assets/client.jpeg";
import VehicleServiceInfo from "./VehicleServiceInfo";
import FileUpload from "../../../../components/file/FileUpload";
import FileDisplay from "../../../../components/file/FileDisplay";
import Invoice from '../../../../components/invoice/Invoice';
import InvoiceFrench from '../../../../components/invoice/InvoiceFrench';
import InvoiceSpanish from '../../../../components/invoice/InvoiceSpanish';

import VehicleTransfer from './VehicleTransfer';

const VehicleService = (props) => {
  const [isVehicleServiceModalvisible, setIsVehicleServiceModalVisible] = useState(false);
  const [isPrintInvoiceModalvisible, setIsPrintInvoiceModalvisible] = useState(false);
  const [isVehicleTransferModalvisible, setIsVehicleTransferModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterVehicle, setFilterVehicle] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [contentType, setcontentType] = useState('info');
  const [page, setPage] = useState(1);
  const componentRef = useRef();
  const dispatch = useDispatch();
  const vehicleService = useSelector((state) => state.vehicleService);
  const clients = useSelector((state) => state.client);
  const vehicles = useSelector((state) => state.vehicle);
  const product = useSelector((state) => state.product);
  const invoiceFile = useSelector((state) => state.invoiceFile);
  const tax = useSelector((state) => state.tax);
  const classes = useStyles();
  const { clientId, vehicleId } = props.match.params;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client');
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
    }
    if (clients.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/vehicle`);
        dispatch(setVehicle(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch vehicle");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/product');
        dispatch(setProduct(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch product");
      }
    }
    if (product.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/taxrate');
        dispatch(setTax(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch tax");
      }
    }
    if (tax.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/vehicle/${vehicleId}/service`);
        dispatch(setVehicleService(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch vehicle invoice");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/vehicle/${vehicleId}/file`);
        dispatch(setInvoiceFile(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch invoice files");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [vehicleService]);

  let client = clients.find(client => client.id === parseInt(clientId));
  client = client ? client : {};

  let vehicle = vehicles.find(vehicle => vehicle.id === parseInt(vehicleId));
  vehicle = vehicle ? vehicle : {};

  const columns = [
    {
      title: 'Id',
      dataIndex: 'sequenceId',
      key: 'sequenceId',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Service Amount',
      dataIndex: 'serviceAmount',
      key: 'serviceAmount',
      render: (serviceAmount) => `$${serviceAmount}`,
      ellipsis: true
    },
    {
      title: 'Tax Amount',
      dataIndex: 'taxAmount',
      key: 'taxAmount',
      render: (_, record) => `$${Number(record.taxAmount1 + record.taxAmount2).toFixed(2)}`,
      ellipsis: true
    },
    {
      title: 'Discount Amount',
      dataIndex: 'discountAmount',
      key: 'discountAmount',
      render: (_, record) => record.discountAmount > 0.0 ? `$${Number(record.discountAmount).toFixed(2)}` : " - ",
      ellipsis: true
    },
    {
      title: 'Total',
      dataIndex: 'total',
      key: 'total',
      render: (_, record) => `$${Number(record.serviceAmount + record.taxAmount1 + record.taxAmount2 - record.discountAmount).toFixed(2)}`,
      ellipsis: true
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true,
      width: '12%'
    },
    {
      title: 'Is Deleted',
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      render: (isDeleted, record) => <Switch checked={isDeleted} loading={loading} onChange={() => {
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isDeleted = !isDeleted;
        dispatch(updateVehicleServiceAsync(newRecord, null, setLoading, clientId, vehicleId));
      }} />,
      ellipsis: true,
      width: '8%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => (
        <Space>
          <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsVehicleServiceModalVisible(true) }}>Edit</Button>
          <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsPrintInvoiceModalvisible(true) }}>Print</Button>
        </Space>
      ),
      ellipsis: true,
      width: '8%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = vehicleService.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterVehicle(filterTable);
    } else {
      setFilterVehicle(null);
    }
  };

  const routes = [
    // {
    //   path: 'shop',
    //   breadcrumbName: 'Home',
    // },
    {
      path: 'shop/clients',
      breadcrumbName: 'Clients',
    },
    {
      path: clientId,
      breadcrumbName: 'Vehicles',
    },
    {
      path: 'service',
      breadcrumbName: 'Invoices',
    }
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    const absolutePaths = paths.map((path) => `/${path}`);
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={absolutePaths.join('')}>{route.breadcrumbName}</Link>
    );
  }

  const Content = ({ children, extraContent }) => (
    <Row style={{ display: collapse ? 'none' : 'flex' }}>
      <div style={{ flex: 1 }}>{children}</div>
      <div className={classes.image}>{extraContent}</div>
    </Row>
  );

  const uploadFile = async (fileList, fileTitle, fileDescription, setUploadfileLoading) => {
    setUploadfileLoading(true);
    const formData = new FormData();
    formData.append('file', fileList[0]);
    const vehicleFile = { title: fileTitle, description: fileDescription };
    formData.append('vehicleFile', JSON.stringify(vehicleFile));
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    try {
      const response = await axios.post(`/client/${clientId}/vehicle/${vehicleId}/file`, formData, { headers: headers });
      setUploadfileLoading(false);
      dispatch(addInvoiceFile(response.data));
      message.success("File has been uploaded successfully!");
    } catch (err) {
      console.log(err);
      message.error("Failed to upload file");
      setUploadfileLoading(false);
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <>
      <PageHeader
        title={client.firstname || 'firstname'}
        className="site-page-header"
        subTitle={client.company || 'company'}
        tags={client.isActive ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>}
        extra={[
          <Button key="1" type="dashed" onClick={() => setIsVehicleTransferModalVisible(true)} danger >Transfer Vehicle</Button>,
          <Button key="2" type={contentType === 'info' ? 'primary' : 'default'} onClick={() => setcontentType('info')}>Info</Button>,
          <Button key="3" type={contentType === 'file' ? 'primary' : 'default'} onClick={() => setcontentType('file')}>Files({invoiceFile.length})</Button>,
          <Button key="4" type={contentType === 'upload' ? 'primary' : 'default'} onClick={() => setcontentType('upload')}>Upload</Button>,
          <Button key="5" shape="circle" icon={collapse ? <DownOutlined /> : <UpOutlined />} onClick={() => setCollapse(!collapse)} />,
        ]}
        avatar={{ src: clientAvatar }}
        breadcrumb={{ itemRender, routes }}
        style={{ backgroundColor: 'white', border: '1px solid #d6d2d2', marginTop: 16 }}
      >
        <Content
          extraContent={
            <img
              src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
              alt="content"
              width="100%"
            />
          }
        >
          {contentType === 'info' && <VehicleServiceInfo vehicle={vehicle} />}
          {contentType === 'file' && <FileDisplay fileList={invoiceFile} deleteFile={(fileId, setDeleteFileLoading) => dispatch(deleteInvoiceFileAsync(clientId, vehicleId, fileId, setDeleteFileLoading))} downloadFile={`client/${clientId}/vehicle/${vehicleId}/file`} />}
          {contentType === 'upload' && <FileUpload uploadFile={uploadFile} />}
        </Content>
      </PageHeader>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsVehicleServiceModalVisible(true); setFormData(null) }} style={{ marginRight: 10 }}>
          Create Invoice
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterVehicle == null ? vehicleService : filterVehicle}
        rowKey={obj => obj.id}
        size="small"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Invoice" : "Create Invoice"}
        centered
        destroyOnClose
        visible={isVehicleServiceModalvisible}
        onOk={() => setIsVehicleServiceModalVisible(false)}
        onCancel={() => setIsVehicleServiceModalVisible(false)}
        width={800}
        footer={null}
      >
        <VehicleServiceForm
          formData={formData}
          setFormData={setFormData}
          setIsVehicleServiceModalVisible={setIsVehicleServiceModalVisible}
          setIsPrintInvoiceModalvisible={setIsPrintInvoiceModalvisible}
          clientId={clientId}
          vehicleId={vehicleId}
          product={product}
          tax={tax}
        />
      </Modal>
      <Modal
        title={<Button type="primary" onClick={handlePrint}>Print</Button>}
        centered
        destroyOnClose
        visible={isPrintInvoiceModalvisible}
        onOk={() => { setIsPrintInvoiceModalvisible(false); setIsVehicleServiceModalVisible(false); }}
        onCancel={() => { setIsPrintInvoiceModalvisible(false); setIsVehicleServiceModalVisible(false); }}
        width={800}
        footer={null}
      >
        <div ref={componentRef}>
          {
            client.invoiceLanguage === 'FRENCH' ?
              <InvoiceFrench formData={formData} client={client} vehicle={vehicle} /> :
              client.invoiceLanguage === 'SPANISH' ?
                <InvoiceSpanish formData={formData} client={client} vehicle={vehicle} /> :
                <Invoice formData={formData} client={client} vehicle={vehicle} />
          }
        </div>
      </Modal>
      <Modal
        title="Transfer Vehicle"
        centered
        destroyOnClose
        visible={isVehicleTransferModalvisible}
        onOk={() => setIsVehicleTransferModalVisible(false)}
        onCancel={() => setIsVehicleTransferModalVisible(false)}
        width={1000}
        footer={null}
      >
        <VehicleTransfer currentClient={client} currentVehicle={vehicle} clients={clients} vehicles={vehicles} setIsVehicleTransferModalVisible={setIsVehicleTransferModalVisible} />
      </Modal>
    </>
  );
}

export default VehicleService;