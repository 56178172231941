const authReducer = (state = {}, action) => {
  switch (action.type) {
    case "LOGIN":
      return {
        userAuth: action.auth
      };
    case "LOGOUT":
      return {};
    case "UPDATE_PROFILE":
      return {
        userAuth: { ...state.userAuth, ...action.profileData }
      };
    case "UPDATE_SHOP":
      return {
        userAuth: { ...state.userAuth, shop: action.shopData }
      };
    default:
      return state;
  }
};

export default authReducer;