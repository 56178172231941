const supplierReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_SUPPLIER":
      return action.supplier;
    case "ADD_SUPPLIER":
      return [...state, action.supplierData];
    case "UPDATE_SUPPLIER":
      return state.map(supplier => supplier.id === action.supplierData.id ? action.supplierData : supplier);
    default:
      return state;
  }
};

export default supplierReducer;