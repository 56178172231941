/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Button, Input, Row, Col, Switch, Select } from 'antd';
// import { useStyles } from './styles'
import { updateClientAsync, createClientAsync } from '../../../actions/client';

const { Option } = Select;

const ClientForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const auth = useSelector(state => state.auth);
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    if (formData) {
      const clientData = { ...formData, ...values.client };
      dispatch(updateClientAsync(clientData, props.setIsClientModalVisible, setLoading));
    } else {
      dispatch(createClientAsync(values.client, props.setIsClientModalVisible, setLoading , props?.setClientIdInAppointmentForm));
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="client_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['client', 'firstname']}
            label={'Firstname'}
            initialValue={formData ? formData['firstname'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Firstname'} maxLength={25} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'lastname']}
            label={'Lastname'}
            initialValue={formData ? formData['lastname'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Lastname'} maxLength={25} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'company']}
            label={'Company'}
            initialValue={formData ? formData['company'] : null}
          >
            <Input placeholder={'Company'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'email']}
            label={'Email'}
            initialValue={formData ? formData['email'] : null}
          >
            <Input placeholder={'Email'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'addressLine1']}
            label={'Address Line 1'}
            initialValue={formData ? formData['addressLine1'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Address Line 1'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'addressLine2']}
            label={'Address Line 2'}
            initialValue={formData ? formData['addressLine2'] : null}
          >
            <Input placeholder={'Address Line 2'} maxLength={100} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'city']}
            label={'City'}
            initialValue={formData ? formData['city'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'City'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'state']}
            label={'State/Province'}
            initialValue={formData ? formData['state'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'State/Province'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'zipcode']}
            label={'Zip/Postal Code'}
            initialValue={formData ? formData['zipcode'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Zip Code/Postal Code'} maxLength={10} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'telephone']}
            label={'Telephone'}
            initialValue={formData ? formData['telephone'] : null}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Telephone'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'mailPreference']}
            label={'Mail Preference'}
            initialValue={formData ? formData['mailPreference'] : 'EMAIL'}
          >
            <Select>
              <Option value="EMAIL">Email</Option>
              <Option value="REGULAR_MAIL">Regular mail</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'invoiceLanguage']}
            label={'Invoice Lang'}
            initialValue={formData ? formData['invoiceLanguage'] : auth.userAuth.shop.defaultInvoiceLanguage || 'ENGLISH'}
          >
            <Select>
              <Option value="ENGLISH">English</Option>
              <Option value="FRENCH">French</Option>
              <Option value="SPANISH">Spanish</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['client', 'isActive']}
            label="Client Status"
            initialValue={formData ? formData.isActive : true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientForm;