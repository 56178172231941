import axios from "../api/instance";
import { message } from "antd";

export const setProduct = (product) => ({
  type: "SET_PRODUCT",
  product
});

export const createProduct = (productData) => ({
  type: "ADD_PRODUCT",
  productData
});

export const createProductAsync = (product, setIsProductModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/product', product);
      const { data } = response;
      dispatch(createProduct(data));
      message.success("Product has been created successfully!");
      setIsProductModalVisible && setIsProductModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create product');
      setLoading && setLoading(false);
    }
  };
};

export const updateProduct = (productData) => ({
  type: "UPDATE_PRODUCT",
  productData
});

export const updateProductAsync = (product, setIsProductModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/product/${product.id}`, product);
      const { data } = response;
      dispatch(updateProduct(data));
      message.success("Product has been updated successfully!");
      setIsProductModalVisible && setIsProductModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update product');
      setLoading && setLoading(false);
    }
  };
};