/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Table, message, Modal } from 'antd';
import axios from "../../../api/instance";

const Order = () => {
  //   const [isShopModalvisible, setIsShopModalvisible] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState([]);
  const [isOrderModalVisible, setIsOrderModalVisible] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/admin/report/order-form');
        setOrder(response.data);
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch order");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Shop Id',
      dataIndex: 'shopId',
      key: 'shopid',
      ellipsis: true
    },
    {
      title: 'Shop Name',
      dataIndex: 'shopName',
      key: 'shopname',
      ellipsis: true
    },
    {
      title: 'Contact Name',
      dataIndex: 'orderForm',
      key: 'contactname',
      render: (orderForm, record) => orderForm.contactName,
      ellipsis: true
    },
    {
      title: 'Contact Email',
      dataIndex: 'orderForm',
      key: 'contactemail',
      render: (orderForm, record) => orderForm.contactEmail,
      ellipsis: true
    },
    {
      title: 'Contact Phone',
      dataIndex: 'orderForm',
      key: 'contactphone',
      render: (orderForm, record) => orderForm.contactPhone,
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'orderForm',
      key: 'status',
      render: (orderForm, record) => orderForm.orderFormStatus,
      ellipsis: true
    },
    {
      title: '# Products',
      dataIndex: 'orderForm',
      key: 'countofproduct',
      render: (orderForm, record) => orderForm.orderFormLineItems.length,
      ellipsis: true
    },
    {
      title: 'Message',
      dataIndex: 'orderForm',
      key: 'message',
      render: (orderForm, record) => orderForm.message,
      ellipsis: true
    },
  ];
  return (
    <>
      <Table
        columns={columns}
        dataSource={order}
        rowKey={(obj) => obj.orderForm.id}
        size="small"
        loading={tableLoading}
        bordered
        style={{ marginTop: 10 }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              console.log(record);
              setSelectedOrder(record);
              setIsOrderModalVisible(true);
            },
          };
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
      />
      <Modal 
        title={`Order by ${selectedOrder?.orderForm?.contactName}`}
        visible={isOrderModalVisible} 
        onOk={() => setIsOrderModalVisible(false)} 
        onCancel={() => setIsOrderModalVisible(false)}
      >
        {selectedOrder?.orderForm?.orderFormLineItems.length && <span style={{ color: 'blue' }}>Order list</span>}
        {selectedOrder?.orderForm?.orderFormLineItems?.map(obj => obj.productDescription && <p key={obj.id}>{obj.productDescription} - {obj.quantity}</p>)}
        {selectedOrder?.orderForm?.message && 
          <>
            <span style={{ color: 'blue' }}>Order message</span>
            <p>{selectedOrder?.orderForm?.message}</p>
          </>
        }
        
      </Modal>
    </>
  );
}

export default Order;