import React, { useState, useEffect } from 'react';
import { Card, Row, Col } from 'antd';
import axios from '../../../api/instance';
import { numberWithCommas } from '../../shop/helper';
import { useStyles } from './styles';

const defaultDashBoardReport = {
  totalShops: 0,
  dailyAppointments: 0,
  totalInvoices: 0,
  weeklyAppointments: 0,
  totalVehicles: 0,
  totalVehicleOnWarranty: 0
};

const DashBoard = () => {
  const [dashBoardReport, setDashBoardReport] = useState(defaultDashBoardReport);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/admin/report/dashboard-tiles');
        setDashBoardReport(response.data);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  return (
    <div className={classes.root}>
      <Row gutter={[16, 16]}>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.totalShops)}</span>
              <span>Total Shops</span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.dailyAppointments)}</span>
              <span>Daily Appointments</span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.weeklyAppointments)}</span>
              <span>Weekly Appointments</span>
            </div>
          </Card>
        </Col>
      </Row>
      <Row gutter={[10, 40]}>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.totalInvoices)}</span>
              <span>Total Invoices</span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.totalVehicles)}</span>
              <span>Total Vehicles</span>
            </div>
          </Card>
        </Col>
        <Col span={8}>
          <Card bodyStyle={{ height: '100%' }}>
            <div className={classes.status}>
              <span style={{ fontSize: 34 }}>{numberWithCommas(dashBoardReport.totalVehicleOnWarranty)}</span>
              <span>Total Vehicle On Warranty</span>
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default DashBoard;
