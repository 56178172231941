/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Switch, Table, Input, message, Row, Col } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import ReactExport from 'react-export-excel';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import ClientForm from './ClientForm';
import { setClient, updateClientAsync } from '../../../actions/client';
import { history } from "../../../App";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const Client = (props) => {
  const [isClientModalvisible, setIsClientModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterClient, setFilterClient] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let client = useSelector((state) => state.client);
  const classes = useStyles();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client');
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [client]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text, record) => `${record.firstname} ${record.lastname}`,
      ellipsis: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true
    },
    {
      title: 'Telephone',
      dataIndex: 'telephone',
      key: 'telephone',
      ellipsis: true
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true
    },
    {
      title: 'Mail Pref.',
      dataIndex: 'mailPreference',
      key: 'mailPreference',
      ellipsis: true
    },
    {
      title: 'Invoice Lang.',
      dataIndex: 'invoiceLanguage',
      key: 'invoiceLanguage',
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => <Switch checked={isActive} loading={loading} onChange={(_, event) => {
        event.stopPropagation();
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isActive = !isActive;
        dispatch(updateClientAsync(newRecord, null, setLoading));
      }} />,
      ellipsis: true,
      width: '8%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={(event) => { event.stopPropagation(); setFormData(record); setIsClientModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '8%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = client.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterClient(filterTable);
    } else {
      setFilterClient(null);
    }
  };

  return (
    <>
      <Row span={24} style={{ margin: "10px 0px", display: "flex" }}>
        <Col span={15}>
        </Col>
        <Col span={9} className={classes.searchWrapper}>
          <div>
            <Input.Search style={{ width: "300px" }} placeholder="Search here..." enterButton onSearch={search} onChange={(event) => setSearchValue(event.target.value)} />
            <Button
              type="primary"
              onClick={() => {
                setIsClientModalVisible(true);
                setFormData(null);
              }}
              style={{ marginRight: 10, marginLeft: 10 }}
            >
              Create Client
            </Button>
            <ExcelFile element={<CloudDownloadOutlined style={{ fontSize: 25, cursor: "pointer" }} />}>
              <ExcelSheet data={client} name="Clients">
                <ExcelColumn label="Firstname" value="firstname" />
                <ExcelColumn label="Lastname" value="lastname" />
                <ExcelColumn label="Email" value="email" />
                <ExcelColumn label="Telephone" value="telephone" />
                <ExcelColumn label="Address Line 1" value="addressLine1" />
                <ExcelColumn label="address Line 2" value="addressLine2" />
                <ExcelColumn label="City" value="city" />
                <ExcelColumn label="State" value="state" />
                <ExcelColumn label="Zipcode" value="zipcode" />
                <ExcelColumn label="Mail Preference" value="mailPreference" />
                <ExcelColumn label="Invoice Language" value="invoiceLanguage" />
                <ExcelColumn label="Company" value="company" />
              </ExcelSheet>
            </ExcelFile>
          </div>
        </Col>
      </Row>
      <Table
        columns={columns}
        dataSource={filterClient == null ? client : filterClient}
        rowKey={(obj) => obj.id}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              props.history.push(`/shop/clients/${record.id}`);
            },
          };
        }}
        style={{ cursor: "pointer" }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["10", "15", "20", "25"],
          onChange(current) {
            setPage(current);
          },
        }}
        loading={tableLoading}
        bordered
      />
      <Modal title={formData ? "Edit Client" : "Create Client"} centered destroyOnClose visible={isClientModalvisible} onOk={() => setIsClientModalVisible(false)} onCancel={() => setIsClientModalVisible(false)} width={800} footer={null}>
        <ClientForm formData={formData} setIsClientModalVisible={setIsClientModalVisible} />
      </Modal>
    </>
  );
}

export default Client;