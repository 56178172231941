/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, Row, Col, Switch, InputNumber, DatePicker } from 'antd';
import moment from 'moment';
// import { useStyles } from './styles'
import { updateVehicleAsync, createVehicleAsync } from '../../../actions/vehicle';

const VehicleForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [warrantyStatus, setWarrantyStatus] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  useEffect(() => {
    formData && setWarrantyStatus(formData.isOnWarranty);
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    const { clientId } = props;
    values.vehicle.warrantyExpiryDate = values.vehicle.warrantyExpiryDate ? values.vehicle.warrantyExpiryDate.format().slice(0, 10) : null;
    if (formData) {
      const vehicleData = { ...formData, ...values.vehicle };
      dispatch(updateVehicleAsync(vehicleData, props.setIsVehicleModalVisible, setLoading, clientId));
    } else {
      dispatch(createVehicleAsync(values.vehicle, props.setIsVehicleModalVisible, setLoading, clientId, props?.setVehicleIdInAppointmentForm));
    }
  };

  const onChangeWarrantyStatus = (checked) => {
    form.setFieldsValue({ vehicle: { warrantyExpiryDate: checked ? (formData && formData.warrantyExpiryDate) ? moment(formData['warrantyExpiryDate'], 'YYYY-MM-DD') : moment() : null } });
    setWarrantyStatus(checked);
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="vehicle_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'make']}
            label={'Make'}
            initialValue={formData ? formData['make'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Make'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'model']}
            label={'Model'}
            initialValue={formData ? formData['model'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Model'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'vin']}
            label={'Vin'}
            initialValue={formData ? formData['vin'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Vin'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'year']}
            label={'Year'}
            initialValue={formData ? formData['year'] : ''}
          >
            <InputNumber placeholder={'Year'} precision={0} style={{ width: '100%' }} maxLength={4} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'mileage']}
            label={'Mileage'}
            initialValue={formData ? formData['mileage'] : ''}
          >
            <InputNumber placeholder={'Mileage'} precision={2} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'warrantyExpiryDate']}
            label={'Warranty Ex'}
            initialValue={(formData && formData.warrantyExpiryDate) ? moment(formData['warrantyExpiryDate'], 'YYYY-MM-DD') : null}
          >
            <DatePicker style={{ width: '100%' }} allowClear={false} disabled={!warrantyStatus} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'isOnWarranty']}
            label={'Warranty Status'}
            initialValue={formData ? formData['isOnWarranty'] : false}
            valuePropName="checked"
          >
            <Switch onChange={onChangeWarrantyStatus} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['vehicle', 'isActive']}
            label="Vehicle Status"
            initialValue={formData ? formData['isActive'] : true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default VehicleForm;