import axios from "../api/instance";
import { message } from "antd";

export const setTax = (tax) => ({
  type: "SET_TAX",
  tax
});

export const createTax = (taxData) => ({
  type: "ADD_TAX",
  taxData
});

export const createTaxAsync = (tax, setIsTaxModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/taxrate', tax);
      const { data } = response;
      dispatch(createTax(data));
      message.success("Tax has been created successfully!");
      setIsTaxModalVisible && setIsTaxModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create tax');
      setLoading && setLoading(false);
    }
  };
};

export const updateTax = (taxData) => ({
  type: "UPDATE_TAX",
  taxData
});

export const updateTaxAsync = (tax, setIsTaxModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/taxrate/${tax.id}`, tax);
      const { data } = response;
      dispatch(updateTax(data));
      message.success("Tax has been updated successfully!");
      setIsTaxModalVisible && setIsTaxModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update tax');
      setLoading && setLoading(false);
    }
  };
};