import axios from "../api/instance";
import { history } from "../App";
import { message } from "antd";

export const login = (auth) => ({
  type: "LOGIN",
  auth
});

export const loginAsync = ({ email, password, remember, lastPath, setLoading }) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/auth/signin', { username: email, password });
      const { data } = response;
      localStorage.setItem('auth', JSON.stringify(data));
      if (remember) {
        const encodedPassword = window.btoa(password);
        localStorage.setItem('remember', JSON.stringify({ email, password: encodedPassword }));
      } else {
        localStorage.removeItem('remember');
      }
      dispatch(login(data));
      if (lastPath === '/') {
        data.roles.indexOf("ROLE_SUPERADMIN") >= 0 ? history.push("/admin") : history.push("/shop");
      } else {
        history.push(`${lastPath}`);
      }
    } catch (err) {
      console.log(err.message);
      message.error('Invalid username or password');
      setLoading(false);
    }
  };
};

export const logout = () => ({
  type: "LOGOUT"
});

export const logoutAsync = () => {
  return (dispatch) => {
    localStorage.removeItem('auth');
    dispatch(logout());
    history.push("/")
  };
};

export const updateProfile = (profileData) => ({
  type: "UPDATE_PROFILE",
  profileData
});

export const updateProfileAsyc = (profile, setProfileLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put('/profile', profile);
      const { data } = response;
      dispatch(updateProfile(data));
      let authData = localStorage.getItem('auth');
      if (authData) {
        const auth = JSON.parse(authData);
        auth.firstname = profile.firstname;
        auth.lastname = profile.lastname;
        auth.email = profile.email;
        localStorage.setItem('auth', JSON.stringify(auth));
      }
      message.success('Profile successfully changed');
      setProfileLoading && setProfileLoading(false);
    } catch (err) {
      setProfileLoading && setProfileLoading(false);
      message.error('Not able to change profile');
    }
  };
};

export const updateShop = (shopData) => ({
  type: "UPDATE_SHOP",
  shopData
});

export const updateShopAsyc = (shopData, setIsEditMode, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put('/shop/profile', shopData);
      const { data } = response;
      dispatch(updateShop(data));
      let auth = localStorage.getItem('auth');
      if (auth) {
        auth = JSON.parse(auth);
        auth.shop = data;
        localStorage.setItem('auth', JSON.stringify(auth));
      }
      setIsEditMode(false);
      setLoading(false);
      message.success("Profile has been updated successfully!");
    } catch (err) {
      console.log(err);
      message.error("Failed to update profile");
      setLoading(false);
    }
  };
};