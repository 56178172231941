/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from "react-router-dom";
import { Button, Modal, Switch, Table, Input, message, PageHeader, Tag, Row } from 'antd';
import { UpOutlined, DownOutlined } from '@ant-design/icons';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import VehicleForm from './VehicleForm';
import { setVehicle, updateVehicleAsync } from '../../../actions/vehicle';
import { setClient } from "../../../actions/client";
import { setClientFile, addClientFile, deleteClientFileAsync } from "../../../actions/clientFile";
import clientAvatar from "../../../assets/client.jpeg";
import InfoContent from "./Info";
import FileUpload from "../../../components/file/FileUpload";
import FileDisplay from "../../../components/file/FileDisplay";

const Vehicle = (props) => {
  const [isVehicleModalvisible, setIsVehicleModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterVehicle, setFilterVehicle] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [collapse, setCollapse] = useState(false);
  const [contentType, setcontentType] = useState('info');
  const [clientSummary, setClientSummary] = useState({});
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const vehicle = useSelector((state) => state.vehicle);
  const clients = useSelector((state) => state.client);
  const clientFile = useSelector((state) => state.clientFile);
  const classes = useStyles();
  const { clientId } = props.match.params;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client');
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
    }
    if (clients.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/summary`);
        setClientSummary(response.data);
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client summary");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/file`);
        dispatch(setClientFile(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client files");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/client/${clientId}/vehicle`);
        dispatch(setVehicle(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch vehicle");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [vehicle]);

  let client = clients.find(client => client.id === parseInt(clientId));
  client = client ? client : {}

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Make',
      dataIndex: 'make',
      key: 'make',
      ellipsis: true
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
      ellipsis: true
    },
    {
      title: 'Vin',
      dataIndex: 'vin',
      key: 'vin',
      ellipsis: true
    },
    {
      title: 'Mileage',
      dataIndex: 'mileage',
      key: 'mileage',
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Year',
      dataIndex: 'year',
      key: 'year',
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Warranty Ex',
      dataIndex: 'warrantyExpiryDate',
      key: 'warrantyExpiryDate',
      ellipsis: true,
      width: '12%'
    },
    {
      title: 'Warranty',
      dataIndex: 'isOnWarranty',
      key: 'isOnWarranty',
      render: (isOnWarranty, record) => isOnWarranty ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>,
      ellipsis: true,
      width: '8%'
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => <Switch checked={isActive} loading={loading} onChange={(_, event) => {
        event.stopPropagation();
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isActive = !isActive;
        dispatch(updateVehicleAsync(newRecord, null, setLoading, clientId));
      }} />,
      ellipsis: true,
      width: '8%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={(event) => { event.stopPropagation(); setFormData(record); setIsVehicleModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '8%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = vehicle.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterVehicle(filterTable);
    } else {
      setFilterVehicle(null);
    }
  };

  const routes = [
    // {
    //   path: 'shop',
    //   breadcrumbName: 'Home',
    // },
    {
      path: 'shop/clients',
      breadcrumbName: 'Clients',
    },
    {
      path: 'vehicle',
      breadcrumbName: 'Vehicles',
    }
  ];

  const itemRender = (route, params, routes, paths) => {
    const last = routes.indexOf(route) === routes.length - 1;
    const absolutePaths = paths.map((path) => `/${path}`);
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
        <Link to={absolutePaths.join('')}>{route.breadcrumbName}</Link>
      );
  }

  const Content = ({ children, extraContent }) => (
    <Row style={{ display: collapse ? 'none' : 'flex' }}>
      <div style={{ flex: 1 }}>{children}</div>
      <div className={classes.image}>{extraContent}</div>
    </Row>
  );

  const uploadFile = async (fileList, fileTitle, fileDescription, setUploadfileLoading) => {
    setUploadfileLoading(true);
    const formData = new FormData();
    formData.append('file', fileList[0]);
    const clientFile = { title: fileTitle, description: fileDescription };
    formData.append('clientFile', JSON.stringify(clientFile));
    const headers = {
      'Content-Type': 'multipart/form-data'
    };
    try {
      const response = await axios.post(`/client/${clientId}/file`, formData, { headers: headers });
      setUploadfileLoading(false);
      dispatch(addClientFile(response.data));
      message.success("File has been uploaded successfully!");
    } catch (err) {
      console.log(err);
      message.error("Failed to upload file");
      setUploadfileLoading(false);
    }
  };

  return (
    <>
      <PageHeader
        title={`${client.firstname} ${client.lastname}` || 'Client name'}
        className="site-page-header"
        subTitle={client.company || 'company'}
        tags={client.isActive ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>}
        extra={[
          <Button key="1" type={contentType === 'info' ? 'primary' : 'default'} onClick={() => setcontentType('info')}>Info</Button>,
          <Button key="2" type={contentType === 'file' ? 'primary' : 'default'} onClick={() => setcontentType('file')}>Files({clientFile.length})</Button>,
          <Button key="3" type={contentType === 'upload' ? 'primary' : 'default'} onClick={() => setcontentType('upload')}>Upload</Button>,
          <Button key="4" shape="circle" icon={collapse ? <DownOutlined /> : <UpOutlined />} onClick={() => setCollapse(!collapse)} />,
        ]}
        avatar={{ src: clientAvatar }}
        breadcrumb={{ itemRender, routes }}
        style={{ backgroundColor: 'white', border: '1px solid #d6d2d2', marginTop: 16 }}
      >
        <Content
          extraContent={
            <img
              src="https://gw.alipayobjects.com/zos/antfincdn/K%24NnlsB%26hz/pageHeader.svg"
              alt="content"
              width="100%"
            />
          }
        >
          {contentType === 'info' && <InfoContent client={client} clientSummary={clientSummary} />}
          {contentType === 'file' && <FileDisplay fileList={clientFile} deleteFile={(fileId, setDeleteFileLoading) => dispatch(deleteClientFileAsync(clientId, fileId, setDeleteFileLoading))} downloadFile={`client/${clientId}/file`} />}
          {contentType === 'upload' && <FileUpload uploadFile={uploadFile} />}
        </Content>
      </PageHeader>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsVehicleModalVisible(true); setFormData(null) }} style={{ marginRight: 10 }}>
          Create Vehicle
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterVehicle == null ? vehicle : filterVehicle}
        rowKey={obj => obj.id}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              props.history.push(`${props.location.pathname}/vehicle/${record.id}`);
            },
          };
        }}
        style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Vehicle" : "Create Vehicle"}
        centered
        destroyOnClose
        visible={isVehicleModalvisible}
        onOk={() => setIsVehicleModalVisible(false)}
        onCancel={() => setIsVehicleModalVisible(false)}
        width={800}
        footer={null}
      >
        <VehicleForm formData={formData} setIsVehicleModalVisible={setIsVehicleModalVisible} clientId={clientId} />
      </Modal>
    </>
  );
}

export default Vehicle;