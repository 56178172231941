import axios from "../api/instance";
import { message } from "antd";
import { b64toBlob } from '../helper';

export const setBrochure = (brochure) => ({
  type: "SET_ADMIN_BROCHURE",
  brochure
});

export const fetchBrochureAsync = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.get('/brochure');
      const { data } = response;
      dispatch(setBrochure(data));
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to fetch Brochure');
      setLoading && setLoading(false);
    }
  };
};

export const createBrochure = (brochure) => ({
  type: "ADD_ADMIN_BROCHURE",
  brochure
});

export const createBrochureAsync = (brochureData, onCloseBrochureDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const brochure = JSON.stringify(brochureData);
      const brochureRecordBlob = new Blob([brochure], {
        type: 'application/json'
      });
      const brochureValue = new FormData();
      brochureValue.append("brochure", brochureRecordBlob);

      // appending file to the request if file is attached.
      if (brochure.b64Data) {
        const b64Data = brochure.b64Data.split(',')
        const blob = b64toBlob(b64Data[1]);
        brochureValue.append("file", blob);
      } else {
        brochureValue.append("file", brochureData.fileList[0]);
      }
      const response = await axios.post('/brochure', brochureValue);     
      const { data } = response;
      dispatch(createBrochure(data));
      message.success("Brochure has been created successfully!");
      onCloseBrochureDrawer && onCloseBrochureDrawer(); 
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create Brochure. Please make sure file is attached');
      setLoading && setLoading(false);
    }
  };
};

export const updateBrochure = (brochure) => ({
  type: "UPDATE_ADMIN_BROCHURE",
  brochure
});

export const updateBrochureAsync = (brochureData, onCloseBrochureDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const brochure = JSON.stringify(brochureData);
      const brochureRecordBlob = new Blob([brochure], {
        type: 'application/json'
      });
      const brochureValue = new FormData();
      brochureValue.append("brochure", brochureRecordBlob);

      // appending file to the request if file is attached.
      if (brochure.b64Data) {
        const b64Data = brochure.b64Data.split(',')
        const blob = b64toBlob(b64Data[1]);
        brochureValue.append("file", blob);
      } else {
        if(brochure.fileList){
          brochureValue.append("file", brochureData.fileList[0]);
        }
      }
      const response = await axios.put(`/brochure/${brochureData.id}`, brochureValue);
      const { data } = response;
      dispatch(updateBrochure(data));
      message.success("Brochure has been updated successfully!");    
      onCloseBrochureDrawer && onCloseBrochureDrawer();  
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update brochure');
      setLoading && setLoading(false);
    }
  };
};

export const deleteBrochure = (brochureId) => ({
  type: "DELETE_ADMIN_BROCHURE",
  brochureId
});

export const deleteBrochureAsync = (brochureId, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      await axios.delete(`/brochure/${brochureId}`);
      setLoading(false);
      dispatch(deleteBrochure(brochureId));
      message.success("Brochure has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete brochure');
      setLoading(false);
    }
  };
};
