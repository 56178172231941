import axios from "../api/instance";
import { message } from "antd";

export const setVehicle = (vehicle) => ({
  type: "SET_VEHICLE",
  vehicle
});

export const createVehicle = (vehicleData) => ({
  type: "ADD_VEHICLE",
  vehicleData
});

export const createVehicleAsync = (vehicle, setIsVehicleModalVisible, setLoading, clientId, setVehicleIdInAppointmentForm) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/client/${clientId}/vehicle`, vehicle);
      const { data } = response;
      dispatch(createVehicle(data));
      setVehicleIdInAppointmentForm && setVehicleIdInAppointmentForm(data.id);
      message.success("Vehicle has been created successfully!");
      setIsVehicleModalVisible && setIsVehicleModalVisible(false);
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to create vehicle');
      setLoading && setLoading(false);
    }
  };
};

export const updateVehicle = (vehicleData) => ({
  type: "UPDATE_VEHICLE",
  vehicleData
});

export const updateVehicleAsync = (vehicle, setIsVehicleModalVisible, setLoading, clientId) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/client/${clientId}/vehicle/${vehicle.id}`, vehicle);
      const { data } = response;
      dispatch(updateVehicle(data));
      message.success("Vehicle has been updated successfully!");
      setIsVehicleModalVisible && setIsVehicleModalVisible(false);
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to update vehicle');
      setLoading && setLoading(false);
    }
  };
};