/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Table, Button, Select, Row, Col, message, Input, Tag } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import ReactExport from 'react-export-excel';
import axios from '../../../api/instance';
import { setClient } from "../../../actions/client";
import { history } from '../../../App';
import { useStyles } from './styles';

const { Option } = Select;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ClientReport = () => {
  const [clientIds, setClientIds] = useState([]);
  const [clientData, setClientData] = useState([]);
  const [filterClient, setFilterClient] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const clients = useSelector(state => state.client);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/client');
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
    }
    if (clients.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    clientIds.length === 0 && setClientData([]); setFilterClient(null);
  }, [clientIds]);

  useEffect(() => {
    search();
  }, [clientData]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'clientSequenceId',
      key: 'clientSequenceId',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'clientFirstname',
      key: 'clientFirstname',
      render: (text, record) => `${record.clientFirstname} ${record.clientLastname}`,
      ellipsis: true
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'vehicleModel',
      key: 'vehicleModel',
      ellipsis: true
    },
    {
      title: 'Vehicle Make',
      dataIndex: 'vehicleMake',
      key: 'vehicleMake',
      ellipsis: true
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      ellipsis: true
    },
    {
      title: 'Warranty',
      dataIndex: 'isOnWarranty',
      key: 'isOnWarranty',
      render: (text, record) => text ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>,
      ellipsis: true
    },
    {
      title: 'Warranty Ex',
      dataIndex: 'warrantyExpiryDate',
      key: 'warrantyExpiryDate',
      ellipsis: true
    },
    {
      title: 'Total Services',
      dataIndex: 'totalServices',
      key: 'totalServices',
      ellipsis: true
    },
    {
      title: 'Total Charges',
      dataIndex: 'totalCharges',
      key: 'totalCharges',
      //render: (text, record) => Number(text).toFixed(2),
      render: (totalCharges) => `$${totalCharges}`,
      ellipsis: true
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = clientData.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterClient(filterTable);
    } else {
      setFilterClient(null);
    }
  };

  const fetchClient = async () => {
    setLoading(true);
    try {
      const response = await axios.get('shop/report/client-vehicle-invoice', {
        params: {
          clientIds: clientIds.join()
        }
      });
      setClientData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch report");
      setLoading(false);
    }
  }

  return (
    <>
      <Row gutter={[10, 0]} style={{ marginTop: 10, marginBottom: 10 }}>
        <Col span={22}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select client"
            onChange={(clientIdArray) => setClientIds(clientIdArray)}
          >
            {clients.map(obj => <Option key={obj.id} value={obj.id}>{`${obj.firstname} ${obj.lastname}`}</Option>)}
          </Select>
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={fetchClient} style={{ width: '100%' }} loading={loading} disabled={!clientIds.length}>
            Search
          </Button>
        </Col>
      </Row>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px', marginLeft: 12 }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <ExcelFile element={<CloudDownloadOutlined style={{ fontSize: 25, cursor: 'pointer' }} />}>
          <ExcelSheet data={filterClient == null ? clientData : filterClient} name="Clients">
            <ExcelColumn label="Firstname" value="clientFirstname" />
            <ExcelColumn label="Lastname" value="clientLastname" />
            <ExcelColumn label="Vehicle Make" value="vehicleMake" />
            <ExcelColumn label="Vehicle Model" value="vehicleModel" />
            <ExcelColumn label="Created At" value="createdAt" />
            <ExcelColumn label="Warranty" value="isOnWarranty" />
            <ExcelColumn label="Warranty Expiry Date" value="warrantyExpiryDate" />
            <ExcelColumn label="Total Services" value="totalServices" />
            <ExcelColumn label="Total Charges" value="totalCharges" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Table
        columns={columns}
        dataSource={filterClient == null ? clientData : filterClient}
        rowKey={obj => `${obj.totalCharges}-${obj.vehicleModel}-${obj.vehicleMake}`}
        size="small"
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              history.push(`/shop/clients/${record.clientId}/vehicle/${record.vehicleId}`);
            },
          };
        }}
        style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        bordered
      />
    </>
  );
}

export default ClientReport;