const adminBrochureReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_ADMIN_BROCHURE":
      return action.brochure;
    case "ADD_ADMIN_BROCHURE":
      return [...state, action.brochure];
    case "UPDATE_ADMIN_BROCHURE":
      return state.map(adminBrochure => adminBrochure.id === action.brochure.id ? action.brochure : adminBrochure);
    case "DELETE_ADMIN_BROCHURE":
      return state.filter(adminBrochure => adminBrochure.id !== action.brochureId);

    default:
      return state;
  }
};

export default adminBrochureReducer;