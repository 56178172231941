/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Switch, Table, Input, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import TaxForm from './TaxForm';
import { setTax, updateTaxAsync } from '../../../actions/tax'

const Tax = (props) => {
  const [isTaxModalvisible, setIsTaxModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterTax, setFilterTax] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let tax = useSelector((state) => state.tax);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/taxrate');
        dispatch(setTax(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch tax");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [tax]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Percentage',
      dataIndex: 'percentage',
      key: 'percentage',
      render: (percentage) => `${percentage} %`,
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => <Switch checked={isActive} loading={loading} onChange={() => {
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isActive = !isActive;
        dispatch(updateTaxAsync(newRecord, null, setLoading));
      }} />,
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsTaxModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '10%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = tax.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterTax(filterTable);
    } else {
      setFilterTax(null);
    }
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsTaxModalVisible(true); setFormData(null) }} style={{ marginRight: 10 }}>
          Create Tax
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterTax == null ? tax : filterTax}
        rowKey={obj => obj.id}
        size="small"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Tax" : "Create Tax"}
        centered
        destroyOnClose
        visible={isTaxModalvisible}
        onOk={() => setIsTaxModalVisible(false)}
        onCancel={() => setIsTaxModalVisible(false)}
        width={800}
        footer={null}
      >
        <TaxForm formData={formData} setIsTaxModalVisible={setIsTaxModalVisible} />
      </Modal>
    </>
  );
}

export default Tax;