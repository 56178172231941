import React from 'react';
import { Tabs } from 'antd';
import ClientReport from './ClientReport';
import InvoiceReport from './InvoiceReport';

const { TabPane } = Tabs;

const Report = () => {
  return (
    <Tabs defaultActiveKey="1">
    <TabPane tab="Search Invoice" key="1">
      <InvoiceReport />
    </TabPane>
      <TabPane tab="Client Report" key="2">
        <ClientReport />
      </TabPane>
    </Tabs>
  );
};

export default Report;