import React from 'react';
import { Divider, Row, Col } from 'antd';
import { useStyles } from './styles';
import InvoiceFrenchCDLogo from '../../assets/InvoiceFrenchCDLogo.png'

const InvoiceFrench = (props) => {
  const { client, vehicle, formData, shopData } = props;
  let auth = localStorage.getItem('auth');
  auth = auth ? JSON.parse(auth) : null;
  // const { shop } = auth;
  const shop = shopData ? shopData : auth.shop
  const classes = useStyles();
  const paymentDisplay = {
    "CASH": "Comptant",
    "CREDIT_CARD": "Carte de crédit",
    "THIRTY_DAY_TERM": "30 jours",
    "PAID": "Payé",
    "PENDING": "En Attente",
  };

  return (
    <div style={{ width: '100%', backgroundColor: 'white', padding: 20 }}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <img
          src={InvoiceFrenchCDLogo}
          alt="logo"
          style={{ height: 100 }}
        />
        <h1 style={{ fontSize: 40 }}>FACTURE</h1>
      </div>
      <Divider style={{ height: 1, backgroundColor: 'black', margin: '12px 0' }} />
      <div className={classes.colPadding}>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <b>Centre Antirouille</b><br />
            {shop.name}<br />
            {`${shop.addressLine1}${shop.addressLine2 ? `, ${shop.addressLine2}` : ''}`}<br />
            {shop.telephone}{shop.telephone && <br />}
            {shop.email}
          </Col>
          <Col span={12}>
            Date: {formData.invoiceDate}<br />
            Facture #: {formData.sequenceId}<br />
            Client #: {client.id}<br />
            Entreprise #: {shop.businessNumber || 'Not applicable'}
          </Col>
        </Row>
      </div>
      <Divider style={{ height: 1, backgroundColor: 'black', margin: '12px 0' }} />
      <div>
        <Row gutter={[16, 4]}>
          <Col span={12}>
            <b>Client</b><br />
            {`${client.firstname} ${client.lastname}`}<br />
            {client.company}{client.company && <br />}
            {`${client.addressLine1}${client.addressLine2 ? `, ${client.addressLine2}` : ''}`}<br />
            {`${client.city}, ${client.zipcode}`}<br />
            {client.telephone}
          </Col>
          <Col span={12}>
            <b>Véhicule</b><br />
            {vehicle.make}<br />
            {vehicle.model}<br />
            {vehicle.year}{vehicle.year && <br />}
            {vehicle.vin}<br />
          </Col>
        </Row>
      </div>
      <table style={{ width: '100%', marginTop: 10 }} className={classes.root} cellPadding="5px">
        <thead>
          <tr>
            <th>#</th>
            <th>Description</th>
            <th>QTE</th>
            <th>Prix Unitaire</th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          {formData.invoiceLineItems.map((obj, index) => (
            <tr key={obj.id}>
              <td style={{ textAlign: 'center' }}>{index + 1}</td>
              <td>{obj.product.description} - {obj.product.size}</td>
              <td style={{ textAlign: 'center' }}>{obj.quantity}</td>
              <td style={{ textAlign: 'right' }}>$ {Number(obj.product.retailPrice).toFixed(2)}</td>
              {/* <td style={{ textAlign: 'right' }}>$ {Number(obj.totalAmount).toFixed(2)}</td> */}
              <td style={{ textAlign: 'right' }}>$ {Number(obj.quantity * obj.product.retailPrice).toFixed(2)}</td>
            </tr>
          ))}
          <tr>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
          {formData.taxRate1 ?
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3} style={{ textAlign: 'right' }}>{`${formData.taxRate1 ? formData.taxRate1.name : '(Taxes 1)'} - (${formData.taxRate1 ? formData.taxRate1.percentage : 0}%)`}</td>
              <td style={{ textAlign: 'right' }}>$ {Number(formData.taxAmount1).toFixed(2)}</td>
            </tr> : null}
          {formData.taxRate2 ?
            <tr>
              <td>&nbsp;</td>
              <td colSpan={3} style={{ textAlign: 'right' }}>{`${formData.taxRate2 ? formData.taxRate2.name : '(Taxes 2)'} - (${formData.taxRate2 ? formData.taxRate2.percentage : 0}%)`}</td>
              <td style={{ textAlign: 'right' }}>$ {Number(formData.taxAmount2).toFixed(2)}</td>
            </tr> : null}
          {
            formData?.discountAmount > 0.0 && (
              <tr>
                <td>&nbsp;</td>
                <td colSpan={3} style={{ textAlign: 'right' }}>Discount </td>
                <td style={{ textAlign: 'right' }}>${formData?.discountAmount ? Number(formData?.discountAmount).toFixed(2) : 0}</td>
              </tr>
            )
          }
          <tr>
            <td colSpan={2} style={{ border: 0, marginRight: 10, fontWeight: 'bold' }}>Mode de Paiement: {paymentDisplay[formData.paymentTerm]}</td>
            <td colSpan={2} style={{ border: 0, textAlign: 'right', marginRight: 10, fontWeight: 'bold' }}>Sous-Total</td>
            <td style={{ textAlign: 'right', fontWeight: '600' }}>${Number(formData.serviceAmount).toFixed(2)}</td>
          </tr>
          <tr>
            <td colSpan={2} style={{ border: 0, marginRight: 10, fontWeight: 'bold' }}>Statut de Paiement: {paymentDisplay[formData.paymentStatus]}</td>
            <td colSpan={2} style={{ border: 0, textAlign: 'right', marginRight: 10, fontWeight: 'bold' }}>Total</td>
            <td style={{ textAlign: 'right', fontWeight: '600' }}>${Number(formData.serviceAmount + formData.taxAmount1 + formData.taxAmount2 - formData?.discountAmount).toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <br />
      <footer><center><strong>www.corrosiondefense.ca</strong></center></footer>
    </div>
  )
};

export default InvoiceFrench;