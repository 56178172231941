const productReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_PRODUCT":
      return action.product;
    case "ADD_PRODUCT":
      return [...state, action.productData];
    case "UPDATE_PRODUCT":
      return state.map(product => product.id === action.productData.id ? action.productData : product);
    default:
      return state;
  }
};

export default productReducer;