import React, { useState } from 'react';
import { Layout, Button } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Menu as MaterialMenu, MenuItem, IconButton } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
// import { useStyles } from "./shopStyles";
import { logoutAsync } from "../../actions/auth";
import { history } from "../../App";

const { Header } = Layout;

const ShopHeader = () => {
  // const classes = useStyles();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const user = useSelector(state => state.auth);
  const isCollapsed = useSelector(state => state.isCollapsed);
  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const goRegisterAppointment = () => {
    history.push(`/shop/register-appointment`);
  }

  const renderMenu = (
    <MaterialMenu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); history.push('/shop/user/profile'); }}>Profile</MenuItem>
      <MenuItem onClick={() => dispatch(logoutAsync())}>Logout</MenuItem>
    </MaterialMenu>
  );

  return (
    <Header
      style={{
        width: isCollapsed ? 'calc(100% - 80px)' : 'calc(100% - 240px)', marginLeft: isCollapsed ? '80px' : '240px',
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'white',
        boxShadow: '0 2px 4px 0 #d4d5d9',
        position: 'fixed',
        zIndex: 3
      }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', fontSize: 20 }}>
        <span style={{ marginLeft: 18, minWidth: '15%' }}>{user.userAuth.shop.name}</span>
        <div style={{ display: 'flex', justifyContent: 'end', width: '100%', fontSize: 20 }}>
          <span style={{ marginLeft: 60, cursor: 'pointer' }} onClick={() => { goRegisterAppointment() }}>
            <Button
                  type="primary"
                  style={{ marginRight: 10, marginLeft: 10 ,cursor :'pointer'}}
                >
              Register Appointment
            </Button>
          </span>
          <span style={{ marginLeft: 36 }}>{user.userAuth.firstname}</span>
        </div>
      </div>
      <IconButton
        edge="end"
        aria-label="account of current user"
        aria-controls={menuId}
        aria-haspopup="true"
        onClick={handleProfileMenuOpen}
        color="inherit"
        style={{ marginRight: 5 }}
      >
        <AccountCircle />
      </IconButton>
      {renderMenu}
      {/* <Menu theme="light" mode="horizontal" defaultSelectedKeys={['2']}>
            <Menu.Item key="1">nav 1</Menu.Item>
            <Menu.Item key="2">nav 2</Menu.Item>
            <Menu.Item key="3">nav 3</Menu.Item>
          </Menu> */}
    </Header>
  );
}

export default ShopHeader;