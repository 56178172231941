const clientFileReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_CLIENT_FILE":
      return action.clientfile;
    case "ADD_CLIENT_FILE":
      return [...state, action.clientfile];
    case "DELETE_CLIENT_FILE":
      return state.filter(clientfile => clientfile.id !== action.fileId);
    default:
      return state;
  }
};

export default clientFileReducer;