/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Row, Col, Input, Button, message, Select, InputNumber } from 'antd';
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { filterOption } from "../../shop/helper";
import { updateOrderAsync, createOrderAsync } from '../../../actions/order';
import { fetchCDProductAsync } from '../../../actions/adminCDProduct';

const { Option } = Select;
const baseOrderItem = {
  productDescription: '',
  quantity: 0,

};

const OrderForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [orderFormLineItems, setOrderFormLineItems] = useState([{ ...baseOrderItem }]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { formData } = props;
  const adminCDProductData = useSelector((state) => state.adminCDProduct);


  useEffect(() => {
    formData && formData.orderFormLineItems && setOrderFormLineItems(formData.orderFormLineItems);
    dispatch(fetchCDProductAsync(setLoading));
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 9 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    if (formData) {
      const orderData = { ...formData, ...values.order, orderFormLineItems };
      dispatch(updateOrderAsync(orderData, props.setIsOrderModalVisible, setLoading));
    } else {
      dispatch(createOrderAsync({ ...values.order, orderFormLineItems }, props.setIsOrderModalVisible, setLoading));
    }
  };

  const removeOrder = (index) => {
    let newOrderFormLineItems = JSON.parse(JSON.stringify(orderFormLineItems));
    newOrderFormLineItems.splice(index, 1);
    setOrderFormLineItems(newOrderFormLineItems);
  }

  const onChangeOrder = (value, index, key) => {
    const newOrderFormLineItems = JSON.parse(JSON.stringify(orderFormLineItems));
    newOrderFormLineItems[index][key] = value;
    setOrderFormLineItems(newOrderFormLineItems);
  };

  const renderOrder = (obj, index) => {
    if (formData) {
      form.setFieldsValue({ itemList: { [`product${index}`]: obj.productDescription } });
      form.setFieldsValue({ itemList: { [`quantity${index}`]: obj.quantity } });
    };

    return (
      <Row key={index}>
        {orderFormLineItems.length === (index + 1) ?
          <Col span={1} style={{ textAlign: 'right' }}>
            <PlusCircleOutlined

              style={{ fontSize: 22, marginTop: 6 }}
              onClick={() => {
                const lastOrderFormLineItems = orderFormLineItems[orderFormLineItems.length - 1];
                (lastOrderFormLineItems.quantity && lastOrderFormLineItems.productDescription) ? setOrderFormLineItems([...orderFormLineItems, { ...baseOrderItem }]) : message.info('First fill above item')
              }
              }
            />
          </Col> :
          <Col span={1}></Col>
        }
        <Col span={18}>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 18 }}
            name={['itemList', `product${index}`]}
            label={'Product'}
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Product"
              onChange={(value) => onChangeOrder(value, index, 'productDescription')}
              showSearch
              filterOption={filterOption}
            >
              {adminCDProductData.map(product => <Option key={product.productName} value={product.productName}>{product.productName}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item
            name={['itemList', `quantity${index}`]}
            label={'Quantity'}
            labelCol={{ span: 0 }}
            wrapperCol={{ span: 16 }}
          >
            <InputNumber placeholder={'Quantity'} precision={0} style={{ width: '100%' }} onChange={(quantity) => onChangeOrder(quantity, index, 'quantity')} />
          </Form.Item>
        </Col>
        {orderFormLineItems.length >= 2 &&
          <Col span={1} style={{ textAlign: 'right' }}>
            <MinusCircleOutlined style={{ fontSize: 22, marginTop: 4 }} onClick={() => {
              removeOrder(index)
            }} />
          </Col>
        }
      </Row>
    );
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="order_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
      style={{ marginTop: 16 }}
    >
      <Row gutter={24}>
        <Col span={10} style={{ textAlign: 'left' }}>
          <Form.Item
            name={['order', 'contactName']}
            label={'Contact Details'}
            initialValue={formData ? formData.contactName : ''}
            labelCol={{ span: 10 }}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Contact Name'} maxLength={50} />
          </Form.Item>
        </Col>
        <Col span={8} style={{ textAlign: 'center' }}>
          <Form.Item
            name={['order', 'contactEmail']}
            initialValue={formData ? formData.contactEmail : ''}
            wrapperCol={{ span: 24 }}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Contact Email'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={6} style={{ textAlign: 'right' }}>
          <Form.Item
            name={['order', 'contactPhone']}
            initialValue={formData ? formData.contactPhone : ''}
            rules={[{ required: true }]}
            wrapperCol={{ span: 20 }}
          >
            <Input placeholder={'Contact Phone'} maxLength={30} />
          </Form.Item>
        </Col>
      </Row>
      {orderFormLineItems.map((obj, index) => renderOrder(obj, index))}
      <Row gutter={24}>
        <Col span={18} style={{ textAlign: 'right' }}>
          <Form.Item
            name={['order', 'message']}
            label={'Message'}
            initialValue={formData ? formData.message : ''}
            labelCol={{ span: 5 }}
            wrapperCol={{ span: 24 }}
          >
            <Input.TextArea placeholder={'Message'} maxLength={255} />
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            name={['order', 'orderFormStatus']}
            label={'Status'}
            initialValue={formData ? formData.orderFormStatus : 'CREATED'}
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 12 }}
          >
            <Select disabled={!formData}>
              <Option value="CREATED">Created</Option>
              <Option value="CANCELLED">Cancel</Option>
            </Select>

          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={23} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
}

export default OrderForm;