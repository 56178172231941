import axios from "../api/instance";
import { message } from "antd";

export const setOrder = (order) => ({
  type: "SET_ORDER",
  order
});

export const createOrder = (orderData) => ({
  type: "ADD_ORDER",
  orderData
});

export const createOrderAsync = (order, setIsOrderModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/order-form', order);
      const { data } = response;
      dispatch(createOrder(data));
      message.success("Order has been created successfully!");
      setIsOrderModalVisible && setIsOrderModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create order');
      setLoading && setLoading(false);
    }
  };
};

export const updateOrder = (orderData) => ({
  type: "UPDATE_ORDER",
  orderData
});

export const updateOrderAsync = (order, setIsOrderModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/order-form/${order.id}`, order);
      const { data } = response;
      dispatch(updateOrder(data));
      message.success("Order has been updated successfully!");
      setIsOrderModalVisible && setIsOrderModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update order');
      setLoading && setLoading(false);
    }
  };
};