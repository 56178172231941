import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  searchWrapper: {
    display: 'flex',
    margin: '10px 0',
    flexDirection: 'row-reverse'
  },
  exampleLink: {
    lineHeight: '24px',
    marginRight: '16px',
  },
  exampleLinkIcon: {
    width: '20px',
    height: '20px',
    marginRight: 5,
  },
  image: {
    margin: '0 0 0 60px',
    display: 'flex',
    alignItems: 'center',
  },
  font:{
    '& span':{
      fontSize:'16px',
    }    
  }
}));