import axios from "../api/instance";
import { message } from "antd";

export const setInvoiceFile = (invoiceFile) => ({
  type: "SET_INVOICE_FILE",
  invoiceFile
});

export const addInvoiceFile = (invoiceFile) => ({
  type: "ADD_INVOICE_FILE",
  invoiceFile
});

export const deleteInvoiceFile = (fileId) => ({
  type: "DELETE_INVOICE_FILE",
  fileId
});

export const deleteInvoiceFileAsync = (clientId, vehicleId, fileId, setDeleteFileLoading) => {
  return async (dispatch) => {
    try {
      setDeleteFileLoading(true);
      await axios.delete(`/client/${clientId}/vehicle/${vehicleId}/file/${fileId}`);
      setDeleteFileLoading(false);
      dispatch(deleteInvoiceFile(fileId));
      message.success("File has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete file');
      setDeleteFileLoading(false);
    }
  };
};

export const downloadInvoiceFileAsync = (clientId, vehicleId, fileId, setDownloadFileLoading) => {
  return async (dispatch) => {
    try {
      setDownloadFileLoading(true);
      const response = await axios.get(`/client/${clientId}/vehicle/${vehicleId}/file/${fileId}/download`);
      window.open(response.data, "_blank");
      setDownloadFileLoading(false);
      message.success("File has been downloaded successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to download file');
      setDownloadFileLoading(false);
    }
  };
};

