/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Collapse, Form, Input, Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { updateProfileAsyc } from '../../../actions/auth';
const { Panel } = Collapse;

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const [profileLoading, setProfileLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [form] = Form.useForm();
  const [formProfile] = Form.useForm();
  const dispatch = useDispatch();
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
  };
  const tailLayout = {
    wrapperCol: { offset: 8, span: 8 },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/profile');
        setProfile(response.data);
        formProfile.resetFields();
      } catch (err) {
        console.log(err);
        message.error('Failed to fetch profile');
      }
    }
    fetchData();
  }, []);

  const onUpdateProfile = async (values) => {
    setProfileLoading(true);
    dispatch(updateProfileAsyc(values, setProfileLoading));
  };

  const onFinish = async (values) => {
    if (values.newPassword === values.confirmPassword) {
      setLoading(true);
      try {
        await axios.put('/profile/change-password', { oldPassword: values.currentPassword, newPassword: values.newPassword });
        message.success('Password successfully changed');
        setLoading(false);
        form.resetFields();
      } catch (err) {
        console.log(err.message);
        message.error('Not able to change password');
        setLoading(false);
      }
    } else {
      message.info('Password are not same');
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  return (
    <>
      <Collapse accordion style={{ marginTop: 16 }} defaultActiveKey={['1']}>
        <Panel header="Change Profile" key="1" showArrow={false}>
          <Form
            {...layout}
            form={formProfile}
            name="basicProfile"
            onFinish={onUpdateProfile}
          >
            <Form.Item
              label={'First name'}
              name={"firstname"}
              initialValue={profile ? profile.firstname : ""}
              rules={[{ required: true, message: 'Please input your first name!' }]}
            >
              <Input placeholder={'First name'} maxLength={25} />
            </Form.Item>

            <Form.Item
              name={'lastname'}
              label={'Last name'}
              initialValue={profile.lastname}
              rules={[{ required: true, message: 'Please input your last name!' }]}
            >
              <Input placeholder={'Last name'} maxLength={25} />
            </Form.Item>

            <Form.Item
              name={'email'}
              label={'Email'}
              initialValue={profile.email}
              rules={[{ required: true, message: 'Please input your email!' }]}
            >
              <Input placeholder={'Email'} disabled={true} maxLength={64} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={profileLoading} >
                Change Profile
            </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
      <Collapse accordion style={{ marginTop: 16 }} defaultActiveKey={['1']}>
        <Panel header="Change Password" key="1" showArrow={false}>
          <Form
            {...layout}
            name="basic"
            initialValues={{ remember: true }}
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
          >
            <Form.Item
              label="Current Password"
              name="currentPassword"
              rules={[{ required: true, message: 'Please input your current password!' }]}
            >
              <Input.Password minLength={5} maxLength={20} />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newPassword"
              rules={[{ required: true, message: 'Please input your new password!' }]}
            >
              <Input.Password minLength={5} maxLength={20} />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="confirmPassword"
              rules={[{ required: true, message: 'Please input your confirm password!' }]}
            >
              <Input.Password  minLength={5} maxLength={20} />
            </Form.Item>

            <Form.Item {...tailLayout}>
              <Button type="primary" htmlType="submit" loading={loading}>
                Change Password
            </Button>
            </Form.Item>
          </Form>
        </Panel>
      </Collapse>
    </>
  );
};




export default Profile;