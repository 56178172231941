/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col, InputNumber, Switch } from "antd";
import { createProductAsync, updateProductAsync } from '../../../actions/adminProduct';

const ProductForm = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const { productData, setProductData } = props;

    const formItemLayout = {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const onFinish = (values) => {
        setLoading(true);
        if (productData) {
            const UpdatedProductData = { ...productData, ...values.product };
            dispatch(updateProductAsync(UpdatedProductData, props.onCloseProductDrawer, setLoading));
            setProductData(null);
            form.resetFields();
        } else {
            dispatch(createProductAsync(values.product, props.onCloseProductDrawer, setLoading));
            form.resetFields();
        }
    };

    useEffect(() => {
        if (productData) {
            form.setFieldsValue({
                product: {
                    description: productData?.description,
                    price: productData?.price,
                    retailPrice: productData?.retailPrice,
                    active: productData?.active,
                    size: productData?.size,
                }
            });
        }
    }, [productData])


    const onClearData = () => {
        setProductData(null);
        form.setFieldsValue({
            product: {
                description: '',
                price: '',
                retailPrice: '',
                active: '',
                size: ''
            }
        })
    }

    return (
        <Form {...formItemLayout} form={form} name="Product_form" onFinish={onFinish} >
            <Row gutter={24}>
                <Col span={4}>
                    <Form.Item
                        name={['product', 'description']}
                        initialValue={productData ? productData['description'] : ''}
                        rules={[{ required: true, message: 'Description is required!' }]}
                    >
                        <Input placeholder={'Description'} maxLength={255} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={['product', 'size']}
                        initialValue={productData ? productData['size'] : ''}
                        rules={[{ required: true, message: 'Size is required!' }]}
                    >
                        <Input placeholder={'Size'} maxLength={30} />
                    </Form.Item>
                </Col>
                <Col span={2}>
                    <Form.Item
                        name={['product', 'price']}
                        initialValue={productData ? productData['price'] : ''}
                        rules={[{ required: true, message: 'Price is required!' }]}
                    >
                        <InputNumber placeholder={'Price'} precision={2} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Form.Item
                        name={['product', 'retailPrice']}
                        initialValue={productData ? productData['retailPrice'] : ''}
                        rules={[{ required: true, message: 'Retail Price is required!' }]}
                    >
                        <InputNumber placeholder={'Retail Price'} precision={2} style={{ width: '100%' }} />
                    </Form.Item>
                </Col>
                <Col span={1}>
                    <Form.Item
                        name={['product', 'active']}
                        initialValue={productData ? productData.active : true}
                        valuePropName="checked"
                    >
                        <Switch />
                    </Form.Item>
                </Col>
                <Col span={4}>
                    <Col style={{ display: 'flex' }}>
                        <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft: '10px' }}>
                            {productData ? 'Update' : 'Create'}
                        </Button>
                        <Button type="primary" loading={loading} style={{ marginLeft: '10px' }} onClick={() => onClearData()}>
                            Clear
                        </Button>
                    </Col>
                </Col>
            </Row>

        </Form>
    );
};

export default ProductForm;
