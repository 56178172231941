import React from 'react';
import { Space, Divider, Tooltip } from 'antd';
import { useStyles } from './styles';
import { abbreviateNumber } from "../helper";

const Info = (props) => {
  const { client, clientSummary } = props;
  const classes = useStyles();

  const IconLink = ({ src, text, hoverText }) => (
    <Tooltip title={hoverText}>
      <span className={classes.exampleLink} style={{ color: '#1890ff' }}>
        <img className={classes.exampleLinkIcon} src={src} alt={text} />
        {text}
      </span>
    </Tooltip>
  );

  return (
    <>
      <Space split={<Divider type="vertical" />}>
        <Tooltip title="Address">
          <span>{client.addressLine1}<br />
          {client.addressLine2}<br />
          {client.city},{client.state}, {client.zipcode}
          </span>
        </Tooltip>
        <Tooltip title="Contact">
          <span>{client.telephone}<br />
          {client.email}
          </span>
        </Tooltip>
      </Space>
      <div style={{ marginTop: 10 }}>
        <IconLink
          src="https://gw.alipayobjects.com/zos/rmsportal/NbuDUAuBlIApFuDvWiND.svg"
          text={clientSummary.countOfVehicle || 0}
          hoverText="# of Vehicles"
        />
        <IconLink
          src="https://gw.alipayobjects.com/zos/rmsportal/MjEImQtenlyueSmVEfUD.svg"
          text={clientSummary.countOfInvoice || 0}
          hoverText="# of invoices"
        />
        <IconLink
          src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg"
          text={`$${abbreviateNumber(clientSummary.totalAmount) || 0}`}
          hoverText="Total Amount"
        />
        <IconLink
          src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg"
          text={`$${abbreviateNumber(clientSummary.dueAmount) || 0}`}
          hoverText="Due Amount"
        />
        <IconLink
          src="https://gw.alipayobjects.com/zos/rmsportal/ohOEPSYdDTNnyMbGuyLb.svg"
          text={clientSummary.lastVisitedDate || 'none'}
          hoverText="Last Visited"
        />
      </div>
    </>
  );
}

export default Info;