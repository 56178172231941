/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col, Upload, Select } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { createBrochureAsync, updateBrochureAsync } from '../../../actions/adminBrochure';

const { Option } = Select;

const BrochureBrochure = (props) => {
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [fileErrorMessage, setFileErrorMessage] = useState('');
  const dispatch = useDispatch();
  const [formBrochure] = Form.useForm();
  const { brochureData } = props;

  useEffect(() => {
    if (!fileList && !fileList.length) {
      setFileErrorMessage('File is required');
    } else {
      setFileErrorMessage('');
    }
  }, []);

  const handleBeforeUpload = (uploadFile, fileList) => {
    // Limit the number of uploaded files
    // Only to show One recent uploaded files, and old ones will be replaced by the new
    if(fileList){
      fileList = fileList.slice(-1);
      setFileList(fileList);
      setFileErrorMessage('');
    }
    return false;
  };

  const formItemLayout = {
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 24 },
    },
  };

  const onFinish = (values) => {
    values.context = "BROCHURE"
    setLoading(true);
    if (brochureData) {
      dispatch(updateBrochureAsync({ ...values, ...brochureData, fileList }, props.onCloseBrochureDrawer, setLoading));
      formBrochure.resetFields();
      setFileList('');
    } else {

      if (fileList == [] && fileList =='') {
        setFileErrorMessage('File is required');
        setLoading(false);
        return 0;
      }
      const brochureData = values
      dispatch(createBrochureAsync({ ...brochureData, fileList }, props.onCloseBrochureDrawer, setLoading));
      setFileErrorMessage("");
      formBrochure.resetFields();
      setFileList('');
    }
  };

  const validateMessages = {
    required: "${name} is required!",
    types: {
      email: "${name} is not a valid email!",
      number: "${name} is not a valid number!",
    },
    number: {
      range: "${name} must be between ${min} and ${max}",
    },
  };

  return (
    <Form {...formItemLayout} form={formBrochure} name="brochure_form" onFinish={onFinish}>
      <Row gutter={24}>
        <Col span={10}>
          <Form.Item  name={ brochureData ? "title" : "title"} initialValue={brochureData ? brochureData.title : null} rules={[{ required: true }]}>
            <Input placeholder={"Brochure Title"} maxLength={255} />
          </Form.Item>
        </Col>
        <Col span={4}>
          <Form.Item name={brochureData ? "language" : "language"} initialValue={brochureData ? brochureData.language : "ENGLISH"} rules={[{ required: true }]}>
            <Select>
              <Option value="ENGLISH">English</Option>
              <Option value="FRENCH">French</Option>
            </Select>
          </Form.Item>
        </Col>
        <Col style={{ display: 'flex' }}>
          <Form.Item name={"file"}>
            <Upload onRemove={() => setFileList([])}
              beforeUpload={handleBeforeUpload}>
              <Button icon={<UploadOutlined />}
                // fileList={fileList}
              >Select File</Button>
            </Upload>
            <span style={{color:'red'}}>{fileErrorMessage}</span>
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft:'10px' }}>
            Submit
          </Button>
        </Col>
      </Row>

    </Form>
  );
};

export default BrochureBrochure;
