import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '16px',
    '& .ant-card': {
      height: '35vh',
    }
  },
  status: {
    display: 'flex', 
    flexDirection: 'column',
    alignItems: 'center', 
    justifyContent: 'center',
    height: '90%'
  },
}));
