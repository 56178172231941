export const abbreviateNumber = (number) => {
  const SI_POSTFIXES = ["", "k", "M", "G", "T", "P", "E"];
  const tier = Math.log10(Math.abs(number)) / 3 | 0;
  if(tier === 0) return number;
  const postfix = SI_POSTFIXES[tier];
  const scale = Math.pow(10, tier * 3);
  const scaled = number / scale;
  let formatted = scaled.toFixed(2) + '';
  if (/\.0$/.test(formatted))
    formatted = formatted.substr(0, formatted.length - 2);
  return formatted + postfix;
};

export const numberWithCommas = (number) => {
  return number?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const filterOption = (inputValue, option) => {
  return option.children.toLowerCase().includes(inputValue.toLowerCase());
};

export const filterOptionAutoComplete = (inputValue, option) => {
  return option?.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
}