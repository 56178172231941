import React, { useState, useEffect, useRef } from "react";
import { Popconfirm, message, Modal, Button, Spin, Select, Card, Col } from "antd";
import { EditOutlined, DeleteOutlined, ManOutlined } from '@ant-design/icons'
import moment from "moment";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import axios from "../../../api/instance";
import { deleteAppointmentAsync } from "../../../actions/appointment";
import { useSelector, useDispatch } from "react-redux";
import { setClient } from "../../../actions/client";
import { setVehicle } from "../../../actions/vehicle";
import "../../../App.css";
import { history } from '../../../App';
import { filterOption } from "../../shop/helper";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";

const { Option } = Select;
const { Meta } = Card;

const AppointmentCalendar = (props) => {
  const [loading, setLoading] = useState(false);
  const [isModal, setIsModal] = useState(false);
  const [clientId, setClientId] = useState('');
  const [vehicleId, setVehicleId] = useState('');
  const [selectOption, setSelectOption] = useState({});
  const calendarRef = useRef();
  const dispatch = useDispatch();
  let client = useSelector((state) => state.client);
  let vehicles = useSelector((state) => state.vehicle);
  let isLoading = false;

  const { setFormData, isFormWith, nowIndicatorTime, setNowIndicatorTime } = props;

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/client");
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  const fetchVehicleData = async (clientId) => {
    setLoading(true);
    try {
      const response = await axios.get(`/client/${clientId}/vehicle`);
      dispatch(setVehicle(response.data));
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch vehicle");
      setLoading(false);
    }
  };

  const fetchCalendarData = async (start) => {
    const fromDate = moment(start.start).format("YYYY-MM-DD");
    const toDate = moment(start.end).format("YYYY-MM-DD");
    const arrDate = [];
    try {
      const response = await axios.get(`appointment?clientId=${clientId ? clientId : ''}&vehicleId=${vehicleId ? vehicleId : ''}&fromDate=${fromDate}&toDate=${toDate}`);
      const { data } = await response;
      data?.forEach((item) => {
        let obj = {};
        obj.id = item.id;
        obj.title = item.subject;
        obj.vehicleId = item.vehicleId;
        isLoading = true;
        obj.clientId = item.clientId;
        obj.appointmentDate = item.appointmentDate + " " + item.appointmentTime;
        obj.start = item.appointmentDate + " " + item.appointmentTime;
        arrDate.push(obj);
      });
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch appointment");
    }
    isLoading = false;
    return arrDate;
  };

  const events = (info, success, error) => {
    const datesResult = fetchCalendarData(info);
    return datesResult;
  }

  const editAppointment = async (item) => {
    try {
      const response = await axios.get(`appointment/${item.id}`);
      const { data } = await response;
      if (isFormWith) {
        setFormData(data);
      } else {
        history.push({ pathname: `/shop/register-appointment`, state: data });
      }
      setIsModal(false);
      // modal.destroy();
    } catch (err) {
      console.log(err);
      setIsModal(false);
      // message.error("Failed to fetch appointment Details");
      // modal.destroy();
    }
  };

  const eventClick = (eventClick) => {
    setSelectOption(eventClick?.event);
    fetchVehicleData(eventClick?.event?.extendedProps?.clientId)
    setIsModal(true);
  };

  const dateTimeSelected = (selected) => {
    const appointmentDate = (moment(selected?.date).format("YYYY-MM-DD")?.toString());
    const appointmentTime = (moment(selected?.date).format("HH:MM"))?.toString();
    setFormData && setFormData({ appointmentDate, appointmentTime });
  }

  useEffect(() => {
    if (nowIndicatorTime) {
      const formDate = moment(nowIndicatorTime).format("YYYY-MM-DD");
      calendarRef.current.getApi().gotoDate(formDate);
      setNowIndicatorTime(null);
    }
  }, [nowIndicatorTime]);

  const onChangeClient = (client) => {
    setClientId(client);
    setVehicleId('');
    client && fetchVehicleData(client);
  }

  const onChangeVehicle = (vehicleId) => {
    setVehicleId(vehicleId ? vehicleId : '');
  }
  useEffect(() => {
    setLoading(isLoading);
  }, [isLoading])

  return (
    <>
      <div style={{ margin: "10px 0px" }}>
        <Spin spinning={loading || isLoading}>
          {!isFormWith && (
            <div style={{ display: 'flex', margin: "10px 0px" }}>
              <Col span={15}>

                <Button
                  type="primary"
                  onClick={() => {
                    history.push(`/shop/register-appointment`);
                  }}
                  style={{ marginRight: 10, marginLeft: 10 ,cursor :'pointer', display: 'none'}}
                >
                  Register Appointment
                </Button>
              </Col>
              <Col span={9} style={{ display: 'flex', justifyContent: 'space-around' }}>
                <Select
                  style={{ width: "80%" }}
                  allowClear
                  showSearch
                  label={"Client"}
                  filterOption={filterOption}
                  onChange={(clientId) => onChangeClient(clientId)}
                  defaultValue=""
                >
                  <Option value=''>All Client</Option>
                  {client.map((client) => (
                    <Option key={client.id} value={client.id}>{`${client.firstname
                      } ${client.lastname || ""}`}</Option>
                  ))}
                </Select>
                <Select
                  style={{ width: "80%", margin: "0px 10px" }}
                  allowClear
                  showSearch
                  defaultValue={vehicleId}
                  label={"Vehicle"}
                  filterOption={filterOption}
                  disabled={!clientId}
                  onChange={(vehicleId) => onChangeVehicle(vehicleId)}
                >
                  <Option value=''>All Vehicle</Option>
                  {vehicles.map((vehicle) => (
                    <Option key={vehicle.id} value={vehicle.id}>
                      {vehicle.model}
                    </Option>
                  ))}
                </Select>

              </Col>
            </div>
          )}

          <FullCalendar
            style={{ backgroudColor: "white" }}
            plugins={
              isFormWith
                ? [timeGridPlugin, interactionPlugin]
                : [dayGridPlugin, timeGridPlugin, interactionPlugin]
            }
            eventDisplay="block"
            initialView={!isFormWith ? "dayGridMonth" : "timeGridDay"}
            headerToolbar={{
              end: `${!isFormWith
                ? "dayGridMonth,timeGridWeek,timeGridDay,today prev,next"
                : "today prev,next"
                }`,
            }}

            allDaySlot={false}
            eventClick={eventClick}
            eventDurationEditable={false}
            events={events}
            rerenderDelay={10}
            ref={calendarRef}
            // nowIndicator={true}
            slotDuration={`00:30:00`}
            // now={nowIndicatorTime}
            dateClick={dateTimeSelected}
            slotLabelInterval={30}
            loading={(loading) => {
              isLoading = loading
            }}
          />
        </Spin>
        <Modal
          centered
          destroyOnClose
          visible={isModal}
          onOk={() => setIsModal(false)}
          onCancel={() => setIsModal(false)}
          width={400}
          footer={null}
          loading={loading || isLoading}
        >
          <>
            <Card
              bordered={false}
              actions={[
                <EditOutlined key="edit" onClick={() => editAppointment(selectOption)} />,
                <Popconfirm
                  title="Are you sure to delete appointment?"
                  onConfirm={() => {
                    deleteAppointmentAsync(selectOption.id, setLoading, setIsModal);
                  }}
                  okText="Yes"
                  cancelText="No"
                >
                  <DeleteOutlined key="delete" loading={loading} />,
                </Popconfirm>
              ]}
            >
              <Meta
                title={selectOption.title}
                description={
                  <>
                    <p style={{ color: 'black' }}>{moment(selectOption?.extendedProps?.appointmentDate)?.format('DD-MMM-YYYY ddd')}  {moment(selectOption?.extendedProps?.appointmentDate)?.format('hh:mm')} </p>
                    <p style={{ color: 'black' }}><b>Client : </b><span style={{ cursor: "pointer" }} onClick={() => { history.push(`clients/${selectOption?.extendedProps?.clientId}`) }}>{client.map(item => item.id == selectOption?.extendedProps?.clientId ? item.firstname + " " + item.lastname : '  ')} <ManOutlined style={{ paddingLeft: '10px' }} /></span></p>
                    <p style={{ color: 'black' }}><b>Vehicle :</b> <span style={{ cursor: "pointer" }} onClick={() => { history.push(`clients/${selectOption?.extendedProps?.clientId}/vehicle/${selectOption?.extendedProps?.vehicleId}`) }}>{vehicles.map(item => item.id == selectOption?.extendedProps?.vehicleId ? item.model : ' ')}  <ManOutlined /></span></p>
                  </>}
              />
            </Card>
          </>
        </Modal>
      </div>
    </>
  );
};

export default AppointmentCalendar;
