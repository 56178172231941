import React from "react";
import { AppBar, Toolbar, ListItem, ListItemText, IconButton, Typography, Menu, MenuItem } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { logoutAsync } from "../../actions/auth";
import { useAdminStyles } from "./Styles";
import { history } from "../../App";
import InvoiceEnglishCDLogo from '../../assets/InvoiceEnglishCDLogo.png'

const AdminManu = (props) => {
  const classes = useAdminStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const menuId = 'primary-search-account-menu';

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    // handleMobileMenuClose();
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={() => { handleMenuClose(); history.push('/admin/profile'); }}>Profile</MenuItem>
      <MenuItem onClick={props.logoutAsync}>Logout</MenuItem>
    </Menu>
  );

  return (
    <div className={`${classes.root} ${classes.toolbar}`}>
      <AppBar position="fixed" color="inherit" style={{ zIndex: 1 }}>
        <Toolbar>
          <Link to="/admin">
            <ListItem button >
              <img
                src={InvoiceEnglishCDLogo}
                alt="logo"
                style={{ height: 40 }}
              />
            </ListItem>
          </Link>
          <Typography variant="h6" className={classes.title}>
            Super Admin
          </Typography>
          <Link to="/admin" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Dashboard" />
            </ListItem>
          </Link>
          <Link to="/admin/orders" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Orders" />
            </ListItem>
          </Link>
          <Link to="/admin/default-product" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Default Product" />
            </ListItem>
          </Link>
          <Link to="/admin/cd-product" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="CD Product" />
            </ListItem>
          </Link>
          <Link to="/admin/shops" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Shops" />
            </ListItem>
          </Link>
          <Link to="/admin/brochure" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Forms" />
            </ListItem>
          </Link>
          <Link to="/admin/reports" className={classes.noStyle}>
            <ListItem button >
              <ListItemText primary="Reports" />
            </ListItem>
          </Link>
          <IconButton
            edge="end"
            aria-label="account of current user"
            aria-controls={menuId}
            aria-haspopup="true"
            onClick={handleProfileMenuOpen}
            color="inherit"
          >
            <AccountCircle />
          </IconButton>
        </Toolbar>
        {renderMenu}
      </AppBar>
    </div>
  );
}


const mapDispatchToProps = dispatch => {
  return {
    logoutAsync: () => dispatch(logoutAsync()),
  }
}

export default connect(null, mapDispatchToProps)(AdminManu);