/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, Row, Col, Switch, InputNumber } from 'antd';
// import { useStyles } from './styles'
import { updateProductAsync, createProductAsync } from '../../../actions/product';

const ProductForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    if (formData) {
      const productData = {...formData, ...values.product};
      dispatch(updateProductAsync(productData, props.setIsProductModalVisible, setLoading));
    } else {
      dispatch(createProductAsync(values.product, props.setIsProductModalVisible, setLoading));
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="product_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['product', 'description']}
            label={'Description'}
            initialValue={formData ? formData['description'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Description'} maxLength={255} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['product', 'size']}
            label={'Size'}
            initialValue={formData ? formData['size'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Size'} maxLength={30} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['product', 'price']}
            label={'Price'}
            initialValue={formData ? formData['price'] : ''}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={'Price'} precision={2} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['product', 'retailPrice']}
            label={'Retail Price'}
            initialValue={formData ? formData['retailPrice'] : ''}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={'Retail Price'} precision={2} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['product', 'isActive']}
            label="Status"
            initialValue={formData ? formData.isActive : true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default ProductForm;