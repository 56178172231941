import axios from "../api/instance";
import { message } from "antd";

export const setCDProduct = (cdProduct) => ({
  type: "SET_ADMIN_CD_PRODUCT",
  cdProduct
});

export const fetchCDProductAsync = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.get('/corrosion-defence/product');
      const { data } = response;
      dispatch(setCDProduct(data));
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to fetch CD Product');
      setLoading && setLoading(false);
    }
  };
};

export const createCDProduct = (cdProduct) => ({
  type: "ADD_ADMIN_CD_PRODUCT",
  cdProduct
});

export const createCDProductAsync = (cdProductData, onCloseCDProductDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.post('/corrosion-defence/product', cdProductData);     
      const { data } = response;
      dispatch(createCDProduct(data));
      message.success("CD Product has been created successfully!");
      onCloseCDProductDrawer && onCloseCDProductDrawer(); 
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create CD Product');
      setLoading && setLoading(false);
    }
  };
};

export const updateCDProduct = (cdProduct) => ({
  type: "UPDATE_ADMIN_CD_PRODUCT",
  cdProduct
});

export const updateCDProductAsync = (cdProductData, onCloseCDProductDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.put(`/corrosion-defence/product/${cdProductData.id}`, cdProductData);
      const { data } = response;
      dispatch(updateCDProduct(data));
      message.success("CD Product has been updated successfully!");    
      onCloseCDProductDrawer && onCloseCDProductDrawer();  
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update CD Product');
      setLoading && setLoading(false);
    }
  };
};

export const deleteCDProduct = (cdProductId) => ({
  type: "DELETE_ADMIN_CD_PRODUCT",
  cdProductId
});

export const deleteCDProductAsync = (cdProductId, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      await axios.delete(`/corrosion-defence/product/${cdProductId}`);
      setLoading(false);
      dispatch(deleteCDProduct(cdProductId));
      message.success("CD Product has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete CD Product');
      setLoading(false);
    }
  };
};
