import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL
});

instance.interceptors.request.use(
  (config) => {
    let auth = localStorage.getItem('auth');
    if (auth) {
      auth = JSON.parse(auth);
      config.headers.Authorization = `${auth.accessToken}`;
    }
    return config;
  },
  (err) => {
    return new Promise.reject(err);
  }
);

instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response;
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    if (error.response.status === 401) {
      localStorage.removeItem('auth');
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

export default instance;