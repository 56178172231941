import axios from "../api/instance";
import { message } from "antd";

export const setVehicleService = (vehicleService) => ({
  type: "SET_VEHICLE_SERVICE",
  vehicleService
});

export const createVehicleService = (vehicleServiceData) => ({
  type: "ADD_VEHICLE_SERVICE",
  vehicleServiceData
});

export const createVehicleServiceAsync = (vehicleService, setIsVehicleServiceModalVisible, setLoading, clientId, vehicleId, setIsPrintInvoiceModalvisible, setFormData) => {
  return async (dispatch) => {
    try {
      const response = await axios.post(`/client/${clientId}/vehicle/${vehicleId}/service`, vehicleService);
      const { data } = response;
      dispatch(createVehicleService(data));
      message.success("Invoice has been created successfully!");
      setFormData(data);
      setIsPrintInvoiceModalvisible(true);
      // setIsVehicleServiceModalVisible && setIsVehicleServiceModalVisible(false);
      // setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create Invoice');
      setLoading && setLoading(false);
    }
  };
};

export const updateVehicleService = (vehicleServiceData) => ({
  type: "UPDATE_VEHICLE_SERVICE",
  vehicleServiceData
});

export const updateVehicleServiceAsync = (vehicleService, setIsVehicleServiceModalVisible, setLoading, clientId, vehicleId, setIsPrintInvoiceModalvisible, setFormData) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/client/${clientId}/vehicle/${vehicleId}/service/${vehicleService.id}`, vehicleService);
      const { data } = response;
      dispatch(updateVehicleService(data));
      message.success("Invoice has been updated successfully!");
      setFormData && setFormData(data);
      setIsPrintInvoiceModalvisible && setIsPrintInvoiceModalvisible(true);
      // setIsVehicleServiceModalVisible && setIsVehicleServiceModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update Invoice');
      setLoading && setLoading(false);
    }
  };
};