/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from 'react';
import { PlusCircleOutlined, MinusCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { Form, Button, Row, Col, DatePicker, InputNumber, Select, Switch, message } from 'antd';
import moment from 'moment';
import axios from '../../../../api/instance';
import { updateVehicleServiceAsync, createVehicleServiceAsync } from '../../../../actions/vehicleService';
// import { useStyles } from './styles'

const { Option } = Select;
const baseInvoiceItem = {
  product: { retailPrice: 0 },
  quantity: 0,
};

const VehicleServiceForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [taxRate1, setTaxRate1] = useState(['None', null, 0]);
  const [taxRate2, setTaxRate2] = useState(['None', null, 0]);
  const [amount, setAmount] = useState(0);
  const [discountAmount, setDiscountAmount] = useState(0);
  const [invoiceLineItems, setInvoiceLineItems] = useState([{ ...baseInvoiceItem, trackId: Math.floor(Math.random() * 100000) }]);
  const [nextInvoiceId, setNextInvoiceId] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  useEffect(() => {
    const { tax } = props;
    if (tax.length === 1) {
      setTaxRate1([props.tax[0]['name'], props.tax[0]['id'], props.tax[0]['percentage']]);
    } else if (tax.length >= 2) {
      setTaxRate1([props.tax[0]['name'], props.tax[0]['id'], props.tax[0]['percentage']]);
      setTaxRate2([props.tax[1]['name'], props.tax[1]['id'], props.tax[1]['percentage']]);
    }
  }, []);

  useEffect(() => {
    if (formData) {
      setAmount(formData.serviceAmount);
      setNextInvoiceId(formData.sequenceId);
      const newInvoiceLineItems = formData.invoiceLineItems.map(obj => ({ ...obj, trackId: obj.id }));
      newInvoiceLineItems.length && setInvoiceLineItems(newInvoiceLineItems);
      const taxObj = formData.taxRate1 ? props.tax.find(obj => obj.id === formData.taxRate1.id) : { id: null, percentage: 0, name: 'None' };
      const taxObj2 = formData.taxRate2 ? props.tax.find(obj => obj.id === formData.taxRate2.id) : { id: null, percentage: 0, name: 'None' };
      setTaxRate1([taxObj.name, taxObj.id, taxObj.percentage]);
      setTaxRate2([taxObj2.name, taxObj2.id, taxObj2.percentage]);
      // form.setFieldsValue({vehicleService: {taxRateId: [taxObj.name, taxObj.id, taxObj.percentage]}});
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop/next-invoice-id');
        setNextInvoiceId(response.data.nextInvoiceId);
      } catch (err) {
        console.log(err);
      }
    }
    if (!formData) {
      fetchData();
    }
  }, []);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    const { clientId, vehicleId } = props;
    const taxAmount1 = Number(Number((amount * taxRate1[2]) / 100).toFixed(2));
    const taxAmount2 = Number(Number((amount * taxRate2[2]) / 100).toFixed(2));
    values.vehicleService.invoiceDate = values.vehicleService.invoiceDate.format().slice(0, 10);
    values.vehicleService.taxAmount1 = taxAmount1;
    values.vehicleService.taxAmount2 = taxAmount2;
    const filterInvoiceLineItems = invoiceLineItems.filter(obj => obj.product.id);
    values.vehicleService.invoiceLineItems = filterInvoiceLineItems;
    let taxObj = props.tax.find(obj => obj.id === taxRate1[1]);
    taxObj = taxObj ? taxObj : null;
    values.vehicleService.taxRate1 = taxObj;

    let taxObj2 = props.tax.find(obj => obj.id === taxRate2[1]);
    taxObj2 = taxObj2 ? taxObj2 : null;
    values.vehicleService.taxRate2 = taxObj2;

    if (formData) {
      const vehicleServiceData = { ...formData, ...values.vehicleService };
      dispatch(updateVehicleServiceAsync(vehicleServiceData, props.setIsVehicleServiceModalVisible, setLoading, clientId, vehicleId, props.setIsPrintInvoiceModalvisible, props.setFormData));
    } else {
      dispatch(createVehicleServiceAsync(values.vehicleService, props.setIsVehicleServiceModalVisible, setLoading, clientId, vehicleId, props.setIsPrintInvoiceModalvisible, props.setFormData));
    }
  };

  const onChangeProduct = (productId, invoiceObj) => {
    const product = props.product.find(obj => obj.id === productId);
    const newInvoiceLineItems = invoiceLineItems.map(obj => obj.trackId === invoiceObj.trackId ? { ...obj, product } : obj);
    const serviceAmount = newInvoiceLineItems.reduce((sum, invoiceItem) => sum + (invoiceItem.product.retailPrice * invoiceItem.quantity), 0);
    setAmount(serviceAmount);
    setInvoiceLineItems(newInvoiceLineItems);
    form.setFieldsValue({ vehicleService: { serviceAmount: serviceAmount } });
  }

  const onChangeQuantity = (quantity, invoiceObj) => {
    const newInvoiceLineItems = invoiceLineItems.map(obj => obj.trackId === invoiceObj.trackId ? { ...obj, quantity } : obj);
    const serviceAmount = newInvoiceLineItems.reduce((sum, invoiceItem) => sum + (invoiceItem.product.retailPrice * invoiceItem.quantity), 0);
    setAmount(serviceAmount);
    setInvoiceLineItems(newInvoiceLineItems);
    form.setFieldsValue({ vehicleService: { serviceAmount: serviceAmount } });
  }

  const renderProduct = (obj, index) => {
    if (formData) {
      form.setFieldsValue({ invoice: { [`product${obj.trackId}`]: obj.product.description && `${obj.product.description} - ${obj.product.size}` } });
      form.setFieldsValue({ invoice: { [`quantity${obj.trackId}`]: obj.quantity } });
    }
    return (
      <Row key={obj.trackId}>
        {invoiceLineItems.length === (index + 1) ?
          <Col span={1} style={{ textAlign: 'right' }}>
            <PlusCircleOutlined
              style={{ fontSize: 22, marginTop: 6 }}
              onClick={() => {
                const lastinvoiceLineItem = invoiceLineItems[invoiceLineItems.length - 1];
                lastinvoiceLineItem.quantity ? setInvoiceLineItems([...invoiceLineItems, { ...baseInvoiceItem, trackId: Math.floor(Math.random() * 100000) }]) : message.info('First fill above item')
              }
              }
            />
          </Col> :
          <Col span={1}></Col>
        }
        <Col span={16}>
          <Form.Item
            labelCol={{ span: 4 }}
            wrapperCol={{ span: 20 }}
            name={['invoice', `product${obj.trackId}`]}
            label={'Product'}
          >
            <Select
              style={{ width: '100%' }}
              placeholder="Product"
              onChange={(productId) => onChangeProduct(productId, obj)}
            >
              {props.product.filter(obj => obj.isActive === true).map(obj => <Option key={obj.id} value={obj.id}>{`${obj.description} - ${obj.size}`}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={6}>
          <Form.Item
            labelCol={{ span: 10 }}
            name={['invoice', `quantity${obj.trackId}`]}
            label={'Quantity'}
          >
            <InputNumber placeholder={'Quantity'} precision={0} style={{ width: '100%' }} onChange={(quantity) => onChangeQuantity(quantity, obj)} />
          </Form.Item>
        </Col>
        {invoiceLineItems.length >= 2 &&
          <Col span={1} style={{ textAlign: 'right' }}>
            <MinusCircleOutlined style={{ fontSize: 22, marginTop: 4 }} onClick={() => removeProduct(obj.trackId)} />
          </Col>
        }
      </Row>
    );
  }

  const removeProduct = (id) => {
    const newInvoiceLineItems = invoiceLineItems.filter(obj => obj.trackId !== id);
    const serviceAmount = newInvoiceLineItems.reduce((sum, invoiceItem) => sum + (invoiceItem.product.retailPrice * invoiceItem.quantity), 0);
    setAmount(serviceAmount);
    setInvoiceLineItems(newInvoiceLineItems);
    form.setFieldsValue({ vehicleService: { serviceAmount: serviceAmount } });
  }

  return (
    <div>
      <Form
        {...formItemLayout}
        form={form}
        name="vehicle_form"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row gutter={24}>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'invoiceDate']}
              label={'Invoice Date'}
              initialValue={formData ? moment(formData['invoiceDate'], 'YYYY-MM-DD') : moment()}
              rules={[{ required: true }]}
            >
              <DatePicker style={{ width: '100%' }} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'nextInvoiceId']}
              label={'Invoice Id'}
            >
              {nextInvoiceId ? <div>{nextInvoiceId}</div> : <LoadingOutlined />}
            </Form.Item>
          </Col>
          <Col span={24}>
            {invoiceLineItems.map((obj, index) => renderProduct(obj, index))}
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'serviceAmount']}
              label={'Service Amount'}
              initialValue={formData ? formData['serviceAmount'] : ''}
              rules={[{ required: true }]}
            >
              <div>${Number(form.getFieldValue(['vehicleService', 'serviceAmount'])).toFixed(2)}</div>
            </Form.Item>
          </Col>
          <Col span={12}></Col>
          {props.tax.length >= 1 &&
            <Col span={12}>
              <Form.Item
                name={['vehicleService', 'taxAmount1']}
                label={props.tax[0]['name']}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>${Number((amount * taxRate1[2]) / 100).toFixed(2)} ({`${taxRate1[2]}%`})</div>
                  <Switch checked={taxRate1[1] !== null} onChange={(checked) => checked === true ? setTaxRate1([props.tax[0]['name'], props.tax[0]['id'], props.tax[0]['percentage']]) : setTaxRate1(['None', null, 0])} />
                </div>
              </Form.Item>
            </Col>
          }
          {props.tax.length >= 2 &&
            <Col span={12}>
              <Form.Item
                name={['vehicleService', 'taxAmount2']}
                label={props.tax[1]['name']}
              >
                <div style={{ display: 'flex' }}>
                  <div style={{ marginRight: 10 }}>${Number((amount * taxRate2[2]) / 100).toFixed(2)} ({`${taxRate2[2]}%`})</div>
                  <Switch checked={taxRate2[1] !== null} onChange={(checked) => checked === true ? setTaxRate2([props.tax[1]['name'], props.tax[1]['id'], props.tax[1]['percentage']]) : setTaxRate2(['None', null, 0])} />
                </div>
              </Form.Item>
            </Col>
          }
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'paymentStatus']}
              label={'Payment Status'}
              rules={[{ required: true }]}
              initialValue={formData ? formData.paymentStatus : 'PAID'}
            >
              <Select style={{ width: '100%' }}>
                <Option value='PAID'>Paid</Option>
                <Option value='PENDING'>Pending</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'paymentTerm']}
              label={'Payment Term'}
              rules={[{ required: true }]}
              initialValue={formData ? formData.paymentTerm : 'CASH'}
            >
              <Select style={{ width: '100%' }}>
                <Option value='CASH'>Cash</Option>
                <Option value='CREDIT_CARD'>Credit Card</Option>
                <Option value='THIRTY_DAY_TERM'>30 Day Term</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'discountAmount']}
              label={'Discount'}
              initialValue={formData ? formData['discountAmount'] : '0'}
              rules={[{ required: false }]}
            >
              <InputNumber placeholder={'Discount Amount'} precision={2} max={amount} min={0} style={{ width: '100%' }} onChange={setDiscountAmount} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'totalAmount']}
              label={'Total Amount'}
              initialValue={formData ? formData['totalAmount'] : ''}
            >
              <div>${Number(amount + ((amount * taxRate1[2]) / 100) + ((amount * taxRate2[2]) / 100) - form.getFieldValue(['vehicleService', 'discountAmount'])).toFixed(2)}</div>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'isCDTreatment']}
              label={'CD Treatment'}
              valuePropName="checked"
              initialValue={formData ? formData['isCDTreatment'] : true}
            >
              <Switch />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name={['vehicleService', 'isReminderOn']}
              label={'Reminder Letter'}
              valuePropName="checked"
              initialValue={formData ? formData['isReminderOn'] : true}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Col>
            <Button type="primary" htmlType="submit" loading={loading}>
              Preview and Print
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default VehicleServiceForm;