/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Table, Button, Select, Row, Col, message, Input, Tag, DatePicker } from 'antd';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
// import { useReactToPrint } from 'react-to-print';
import ReactExport from 'react-export-excel';
import axios from '../../../api/instance';
import { setShop } from "../../../actions/adminShop";
// import { history } from '../../../App';
import { useStyles } from './styles';
// import Invoice from '../../../components/invoice/Invoice';

const { Option } = Select;
const { RangePicker } = DatePicker;
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const VehicleReport = () => {
  // const [clientIds, setClientIds] = useState([]);
  // const [productIds, setProductIds] = useState([]);
  const [shopIds, setShopIds] = useState([]);
  const [warrantyStatus, setWarrantyStatus] = useState('');
  const [dateStrings, setdateStrings] = useState(['', '']);
  const [invoiceData, setInvoiceData] = useState([]);
  const [filterInvoice, setFilterInvoice] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  // const [isPrintInvoiceModalvisible, setIsPrintInvoiceModalvisible] = useState(false);
  // const [formData, setFormData] = useState({});
  // const [clientData, setClientData] = useState({});
  // const [vehicleData, setVehicleData] = useState({});
  const [page, setPage] = useState(1);
  // const clients = useSelector(state => state.client);
  // const products = useSelector(state => state.product);
  const adminShop = useSelector(state => state.adminShop);
  const dispatch = useDispatch();
  // const componentRef = useRef();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop');
        dispatch(setShop(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch shop");
      }
    }
    if (adminShop.length === 0) {
      fetchData();
    }
  }, []);

  useEffect(() => {
    search();
  }, [invoiceData]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'clientSequenceId',
      key: 'clientSequenceId',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'clientFirstname',
      key: 'clientFirstname',
      render: (text, record) => `${record.client.firstname} ${record.client.lastname}`,
      ellipsis: true
    },
    {
      title: 'Vehicle Model',
      dataIndex: 'vehicleModel',
      key: 'vehicleModel',
      render: (text, record) => `${record.vehicle.model}`,
      ellipsis: true
    },
    {
      title: 'Vehicle Make',
      dataIndex: 'vehicleMake',
      key: 'vehicleMake',
      render: (text, record) => `${record.vehicle.make}`,
      ellipsis: true
    },

    {
      title: 'Warranty',
      dataIndex: 'isOnWarranty',
      key: 'isOnWarranty',
      render: (text, record) => record.vehicle.isOnWarranty ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>,
      ellipsis: true,
    },
    {
      title: 'Warranty Ex',
      dataIndex: 'warrantyExpiryDate',
      key: 'warrantyExpiryDate',
      render: (text, record) => record.vehicle.warrantyExpiryDate,
      ellipsis: true
    },
    // {
    //   title: 'Action',
    //   dataIndex: '',
    //   key: 'x',
    //   render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => setViewInvoiceData(record)}>View</Button>,
    //   ellipsis: true,
    //   width: '8%'
    // },
  ];

  // const setViewInvoiceData = (record) => {
  //   setFormData(record.invoice);
  //   setClientData(record.client);
  //   setVehicleData(record.vehicle); 
  //   setIsPrintInvoiceModalvisible(true);
  // }

  const search = value => {
    if (searchValue) {
      const filterTable = invoiceData.filter(o =>
        Object.keys(o).some(k => {
          return o[k] && Object.keys(o[k]).some(j =>
            String(o[k][j])
              .toLowerCase()
              .includes(searchValue.toLowerCase())
          )
        })
      );
      setFilterInvoice(filterTable);
    } else {
      setFilterInvoice(null);
    }
  };

  const fetchClient = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/admin/report/search-vehicle', {
        params: {
          shopIds: shopIds.join(),
          // clientIds: clientIds.join(),
          // productIds: productIds.join(),
          warrantyStatus,
          fromDate: dateStrings[0],
          toDate: dateStrings[1]
        }
      });
      setInvoiceData(response.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch report");
      setLoading(false);
    }
  };

  // const handlePrint = useReactToPrint({
  //   content: () => componentRef.current,
  // });

  let exportData = filterInvoice == null ? invoiceData : filterInvoice;
  exportData = exportData.map(obj => ({
    ...obj.client,
    ...obj.invoice,
    ...obj.vehicle,
    shopAddressLine1: obj.shop.addressLine1,
    shopAddressLine2: obj.shop.addressLine2,
    shopBusinessNumber: obj.shop.businessNumber,
    shopEmail: obj.shop.email,
    shopName: obj.shop.name,
    shopTelephone: obj.shop.telephone,
    website: obj.shop.website,
  }));

  return (
    <>
      <Row gutter={[12, 0]} style={{ marginTop: 10 }}>
        <Col span={10}>Shop</Col>
        <Col span={4}>Warranty Status</Col>
        <Col span={6}>Date</Col>
      </Row>
      <Row gutter={[10, 0]} style={{ marginBottom: 10 }}>
        <Col span={10}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select Shop"
            onChange={(shopIdArray) => setShopIds(shopIdArray)}
          >
            {adminShop.map(obj => <Option key={obj.shopId} value={obj.shopId}>{`${obj.shopName}`}</Option>)}
          </Select>
        </Col>
        {/* <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select Client"
            onChange={(clientIdArray) => setClientIds(clientIdArray)}
          >
            {clients.map(obj => <Option key={obj.id} value={obj.id}>{`${obj.firstname} ${obj.lastname}`}</Option>)}
          </Select>
        </Col> */}
        {/* <Col span={6}>
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            allowClear
            placeholder="Select Product"
            onChange={(productIdArray) => setProductIds(productIdArray)}
          >
            {products.map(obj => <Option key={obj.id} value={obj.id}>{obj.description}</Option>)}
          </Select>
        </Col> */}
        <Col span={4}>
          <Select defaultValue={''} onChange={(value) => setWarrantyStatus(value)} style={{ width: '100%' }}>
            <Option value=''>All</Option>
            <Option value='TRUE'>Yes</Option>
            <Option value='FALSE'>No</Option>
          </Select>
        </Col>
        <Col span={8}>
          <RangePicker onChange={(_, dateStrings) => setdateStrings(dateStrings)} style={{ width: '100%' }} />
        </Col>
        <Col span={2}>
          <Button type="primary" onClick={fetchClient} style={{ width: '100%' }} loading={loading}>
            Search
          </Button>
        </Col>
      </Row>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px', marginLeft: 12 }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <ExcelFile element={<CloudDownloadOutlined style={{ fontSize: 25, cursor: 'pointer' }} />}>
          <ExcelSheet data={exportData} name="Vehicle">
            <ExcelColumn label="Shop Name" value="shopName" />
            <ExcelColumn label="Shop AddressLine 1" value="shopAddressLine1" />
            <ExcelColumn label="Shop AddressLine 2" value="shopAddressLine2" />
            <ExcelColumn label="Shop Email" value="shopEmail" />
            <ExcelColumn label="Shop Telephone" value="shopTelephone" />
            <ExcelColumn label="Shop Business Number" value="shopBusinessNumber" />
            <ExcelColumn label="website" value="website" />

            <ExcelColumn label="First Nname" value="firstname" />
            <ExcelColumn label="Last Name" value="lastname" />
            <ExcelColumn label="Email" value="email" />
            <ExcelColumn label="Address Line 1" value="addressLine1" />
            <ExcelColumn label="Address Line 2" value="addressLine2" />
            <ExcelColumn label="City" value="city" />
            <ExcelColumn label="Zip Code" value="zipcode" />
            <ExcelColumn label="Telephone" value="telephone" />
            <ExcelColumn label="Invoice Language" value="invoiceLanguage" />
            <ExcelColumn label="Mail Preference" value="mailPreference" />

            <ExcelColumn label="Vehicle Make" value="make" />
            <ExcelColumn label="Vehicle Model" value="model" />
            <ExcelColumn label="Mileage" value="mileage" />
            <ExcelColumn label="Vin" value="vin" />
            <ExcelColumn label="Year" value="year" />
            <ExcelColumn label="Warranty Status" value="isOnWarranty" />
            <ExcelColumn label="Warranty Expiry Date" value="warrantyExpiryDate" />
          </ExcelSheet>
        </ExcelFile>
      </div>
      <Table
        columns={columns}
        dataSource={filterInvoice == null ? invoiceData : filterInvoice}
        rowKey={obj => obj.vehicle?.id}
        size="small"
        style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        bordered
      />
      {/* <Modal
        title={<Button type="primary" onClick={handlePrint}>Print</Button>}
        centered
        destroyOnClose
        visible={isPrintInvoiceModalvisible}
        onOk={() => setIsPrintInvoiceModalvisible(false)}
        onCancel={() => setIsPrintInvoiceModalvisible(false)}
        width={800}
        footer={null}
      >
        <div ref={componentRef}>
          <Invoice formData={formData} client={clientData} vehicle={vehicleData} />
        </div>
      </Modal> */}
    </>
  );
}

export default VehicleReport;