/* eslint-disable no-template-curly-in-string */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, Button, Row, Col } from "antd";
import { createCDProductAsync, updateCDProductAsync } from '../../../actions/adminCDProduct';

const CDProductForm = (props) => {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const [formCDProduct] = Form.useForm();
    const { CDProductData, setCDProductData } = props;

    const formItemLayout = {
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 24 },
        },
    };

    const onFinish = (values) => {
        setLoading(true);
        if (CDProductData) {
            dispatch(updateCDProductAsync({ ...CDProductData, ...values }, props.onCloseCDProductDrawer, setLoading));
            setCDProductData(null);
            formCDProduct.resetFields();
        } else {
            dispatch(createCDProductAsync(values, props.onCloseCDProductDrawer, setLoading));
            formCDProduct.resetFields();
        }
    };
    useEffect(() => {
        if (CDProductData) {
            formCDProduct.setFieldsValue({ productName: CDProductData?.productName });
        }
    }, [CDProductData])

    const onClearData = () => {
        setCDProductData(null); 
        formCDProduct.setFieldsValue({ productName: '' })
    }
    const validateMessages = {
        required: "${name} is required!",
        types: {
            email: "${name} is not a valid email!",
            number: "${name} is not a valid number!",
        },
        number: {
            range: "${name} must be between ${min} and ${max}",
        },
    };

    return (
        <Form {...formItemLayout} form={formCDProduct} name="CDProduct_form" onFinish={onFinish}>
            <Row gutter={24}>
                <Col span={14}>
                    <Form.Item name={CDProductData ? "productName" : "productName"} initialValue={CDProductData ? CDProductData.productName : null} rules={[{ required: true , message: 'Product Name is required!'}]}>
                        <Input placeholder={"Product Name"} maxLength={255} />
                    </Form.Item>
                </Col>
                <Col style={{ display: 'flex' }}>
                    <Button type="primary" htmlType="submit" loading={loading} style={{ marginLeft: '10px' }}>
                        {CDProductData ? 'Update' : 'Create'}
                    </Button>
                    <Button type="primary" loading={loading} style={{ marginLeft: '10px' }} onClick={() => onClearData()}>
                        Clear
                    </Button>
                </Col>
            </Row>

        </Form>
    );
};

export default CDProductForm;
