import axios from "../api/instance";
import { message } from "antd";

export const setShop = (shop) => ({
  type: "SET_SHOP",
  shop
});

export const createShop = (shopData) => ({
  type: "ADD_SHOP",
  shopData
});

export const createShopAsync = (user, onCloseShopDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/shop', user);     
      const { data } = response;
      dispatch(createShop(data));
      message.success("Shop has been created successfully!");
      onCloseShopDrawer && onCloseShopDrawer();
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create shop, make sure to use unique shop name, email and telephone');
      setLoading && setLoading(false);
    }
  };
};

export const updateShop = (shopData) => ({
  type: "UPDATE_SHOP",
  shopData
});

export const updateShopAsync = (user, onCloseShopDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/shop/${user.shopId}`, user);
      const { data } = response;
      dispatch(updateShop(data));
      message.success("Shop has been updated successfully!");    
      onCloseShopDrawer && onCloseShopDrawer();  
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update shop');
      setLoading && setLoading(false);
    }
  };
};

