/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Popconfirm, Input, Row, Col, Tooltip, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from "./styles";
import CDProductForm from './CDProductForm';
import { deleteCDProductAsync, fetchCDProductAsync } from '../../../actions/adminCDProduct';

const CdProduct = () => {
    const [filterCDProductArray, setFilterCDProductArray] = useState([]);
    const [CDProductData, setCDProductData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const adminCDProductData = useSelector((state) => state.adminCDProduct);

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchCDProductAsync(setLoading));
    }, []);

    useEffect(() => {
        search();
    }, [searchValue]);

    const search = value => {
        if (searchValue) {
            const filterArray = adminCDProductData.filter(o =>
                Object.keys(o).some(k =>
                    String(o[k])
                        .toLowerCase()
                        .includes(searchValue.toLowerCase())
                )
            );
            setFilterCDProductArray(filterArray);
        } else {
            setFilterCDProductArray(null);
        }
    };
    const columns = [
        {
            title: 'Id',
            dataIndex: 'id',
            key: 'id',
            align: 'right',
            render: (text, record, index) => (page - 1) * 10 + (index + 1),
            ellipsis: true,
            width: '5%'
        },
        {
            title: 'Product Name',
            dataIndex: 'productName',
            key: 'productName',
            ellipsis: true
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (<>
                <Tooltip title='Edit' >
                    <EditOutlined style={{ padding: '0px 15px' }} onClick={() => { setCDProductData(record); }} />
                </Tooltip>
                <Popconfirm title="Are you sure want to delete ?" okText="Yes" cancelText="No" onConfirm={() => { dispatch(deleteCDProductAsync(record.id, setLoading)) }}>
                    <Tooltip title='Delete'>
                        <DeleteOutlined />
                    </Tooltip>
                </Popconfirm>
            </>),
            ellipsis: true,
            width: '8%'
        },
    ];
    return (
        <>
            <Row span={24} style={{ margin: "10px 0px", display: 'flex' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }}  >
                    <CDProductForm CDProductData={CDProductData} setCDProductData={setCDProductData} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} >
                    <Input.Search
                        style={{ maxWidth: 300 }}
                        placeholder="Search Form"
                        enterButton
                        onChange={(event) => setSearchValue(event.target.value)}
                    />
                </Col>
            </Row>
            <div className={classes.CDProductWrapper}>
                <Row gutter={[16, 16]}>
                    <Table
                        columns={columns}
                        dataSource={filterCDProductArray == null ? adminCDProductData : filterCDProductArray}
                        rowKey={(obj) => obj.id}
                        size="small"
                        style={{ cursor: "pointer" }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            onChange(current) {
                                setPage(current);
                            },
                        }}
                        loading={loading}
                        bordered
                    />
                </Row>
            </div>
        </>
    )
}
export default CdProduct;