import React, { useState } from 'react';
import { Table, Input, Button, message, Tag, Modal, Space, Statistic, Divider } from 'antd';
import axios from '../../../../api/instance';
import { useStyles } from './styles';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const VehicleTransfer = (props) => {
  const { currentClient, currentVehicle } = props;
  const [searchValue, setSearchValue] = useState('');
  const [filterClient, setFilterClient] = useState(null);
  const [selectedClientId, setSelectedClientId] = useState(currentClient.id);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const { confirm } = Modal;

  const columns = [
    {
      title: 'Name',
      dataIndex: 'firstname',
      key: 'firstname',
      render: (text, record) => `${record.firstname} ${record.lastname}`,
      ellipsis: true
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      ellipsis: true
    },
    {
      title: 'Telephone',
      dataIndex: 'telephone',
      key: 'telephone',
      ellipsis: true
    },
    {
      title: 'City',
      dataIndex: 'city',
      key: 'city',
      ellipsis: true
    },
    {
      title: 'State',
      dataIndex: 'state',
      key: 'state',
      ellipsis: true
    },
    {
      title: 'Active',
      dataIndex: 'isActive',
      key: 'isActive',
      render: (isActive, record) => isActive ? <Tag color="blue">Active</Tag> : <Tag color="red">Inactive</Tag>,
      ellipsis: true,
      width: '8%'
    }
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = props.clients.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterClient(filterTable);
    } else {
      setFilterClient(null);
    }
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedClientId(selectedRows[0]['id']);
    },
  };

  const transerVehicle = async () => {
    setLoading(true);
    try {
      await axios.put(`client/${currentClient.id}/vehicle/${currentVehicle.id}/transfer`, { toClientId: selectedClientId });
      message.success('Vehicle transfer successfull');      
      props.setIsVehicleTransferModalVisible(false);
      setLoading(false);
      history.push(`/shop/clients/${currentClient.id}`);
    } catch (err) {
      console.log(err);
      message.error("Failed to transfer vehicle");
      setLoading(false);
    }
  }

  const showConfirm = () => {
    confirm({
      title: 'Transfer Vehicle',
      icon: <ExclamationCircleOutlined />,
      content: 'Are you sure you want to Transfer Vehicle',
      okText: 'Yes',
      onOk() { transerVehicle(); },
      onCancel() { },
    });
  }

  return (
    <>
      <Space split={<Divider type="vertical" />} className={classes.font}>
        <Statistic title="Model" formatter={() => currentVehicle.model ? <span title={currentVehicle.model} >{currentVehicle.model}</span> : '-'} />
        <Statistic title="Make" formatter={() => currentVehicle.make ? <span title={currentVehicle.make} >{currentVehicle.make}</span> : '-'} />
        <Statistic title="Year" formatter={() => currentVehicle.year ? <span title={currentVehicle.year} >{currentVehicle.year}</span> : '-'} />
        <Statistic title="Mileage" formatter={() => currentVehicle.mileage ? <span title={currentVehicle.mileage}  >{currentVehicle.mileage} </span> : '-'} />
        <Statistic title="Warranty Expiry Date" formatter={() => currentVehicle.warrantyExpiryDate ? <span title={currentVehicle.warrantyExpiryDate} >{currentVehicle.warrantyExpiryDate}</span> : '  - '} />
        <Statistic title="Vin" formatter={() => currentVehicle.vin ? <span title={currentVehicle.vin}  >{currentVehicle.vin}</span> : '-'} />
        <Statistic title="Warranty Status" formatter={() => currentVehicle.isOnWarranty ? <Tag color="blue">Active</Tag> : <Tag color="red">NA</Tag>} />
      </Space>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
      </div>
      <Table
        columns={columns}
        dataSource={filterClient == null ? props.clients : filterClient}
        rowKey={obj => obj.id}
        size="small"
        bordered
        style={{ cursor: 'pointer' }}
        rowSelection={{
          type: 'radio',
          ...rowSelection,
          selectedRowKeys: [selectedClientId]
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: event => {
              setSelectedClientId(record.id);
            },
          };
        }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
        }}
      />
      <Button type="primary" style={{ display: 'flex', marginInlineStart: 'auto' }} onClick={showConfirm} loading={loading}>
        Transfer Vehicle
      </Button>
    </>
  );
}

export default VehicleTransfer;