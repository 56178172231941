import React from 'react';
import { Space, Divider, Tooltip, Tag } from 'antd';

const Info = (props) => {
  const { vehicle } = props;

  return (
    <>
      <Space split={<Divider type="vertical" />}>
        <Tooltip title="Model"><span>{vehicle.model}</span></Tooltip>
        <Tooltip title="Make"><span>{vehicle.make}</span></Tooltip>
        <Tooltip title="Year"><span>{vehicle.year}</span></Tooltip>
        <Tooltip title="Mileage"><span>{vehicle.mileage}</span></Tooltip>
        <Tooltip title="Warranty Expiry Date"><span>{vehicle.warrantyExpiryDate}</span></Tooltip>
        <Tooltip title="Vin"><span>{vehicle.vin}</span></Tooltip>
        <Tooltip title="Warranty Status"><span> {vehicle.isOnWarranty ? <Tag color="blue">Active</Tag> : <Tag color="red">NA</Tag>}</span></Tooltip>
      </Space>
    </>
  );
}

export default Info;