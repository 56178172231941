/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Popconfirm, Input, Switch, Row, Col, Tooltip, Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useStyles } from "./styles";
import ProductForm from './ProductForm';
import { deleteProductAsync, fetchProductAsync, updateProductAsync } from '../../../actions/adminProduct';

const Product = () => {
    const [filterProductArray, setFilterProductArray] = useState([]);
    const [productData, setProductData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const [page, setPage] = useState(1);
    const adminProductData = useSelector((state) => state.adminProduct);

    const dispatch = useDispatch();
    const classes = useStyles();

    useEffect(() => {
        dispatch(fetchProductAsync(setLoading));
    }, []);

    useEffect(() => {
        search();
    }, [searchValue]);

    const search = value => {
        if (searchValue) {
          const filterArray = adminProductData.filter(o =>
            Object.keys(o).some(k =>
              String(o[k])
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            )
          );
          setFilterProductArray(filterArray);
        } else {
            setFilterProductArray(null);
        }
      };

    const columns = [
        {
          title: 'Id',
          dataIndex: 'id',
          key: 'id',
          align: 'right',
          render: (text, record, index) => (page - 1) * 10 + (index + 1),
          ellipsis: true,
          width: '5%'
        },
        {
          title: 'Description',
          dataIndex: 'description',
          key: 'description',
          ellipsis: true,
          width: '25%'
        },
        {
          title: 'Size',
          dataIndex: 'size',
          key: 'size',
          ellipsis: true,
          width: '25%'
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          render: (price) => `$${price}`,
          ellipsis: true,
          width: '25%'
        },
        {
          title: 'Retail Price',
          dataIndex: 'retailPrice',
          key: 'retailPrice',
          render: (retailPrice) => `$${retailPrice}`,
          ellipsis: true,
          width: '25%'
        },
        {
          title: 'Status',
          dataIndex: 'active',
          key: 'active',
          render: (active, record) => <Switch checked={active} loading={loading} onChange={() => {
            setLoading(true);
            const newRecord = JSON.parse(JSON.stringify(record));
            newRecord.active = !active;
            dispatch(updateProductAsync(newRecord, null, setLoading));
          }} />,
          ellipsis: true,
          width: '10%'
        },
        {
            title: 'Action',
            dataIndex: '',
            key: 'x',
            render: (_, record) => (<>
                <Tooltip title='Edit' >
                    <EditOutlined style={{ padding: '0px 15px' }} onClick={() => { setProductData(record); }} />
                </Tooltip>
                <Popconfirm title="Are you sure want to delete ?" okText="Yes" cancelText="No" onConfirm={() => { dispatch(deleteProductAsync(record.id, setLoading)) }}>
                    <Tooltip title='Delete'>
                        <DeleteOutlined />
                    </Tooltip>
                </Popconfirm>
            </>),
            ellipsis: true,
            width: '8%'
        },
      ];

    return (
        <>
            <Row span={24} style={{ margin: "10px 0px", display: 'flex' }}>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 19 }}  >
                    <ProductForm productData={productData} setProductData={setProductData} />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 5 }} >
                    <Input.Search
                        style={{ maxWidth: 300 }}
                        placeholder="Search Form"
                        enterButton
                        onSearch={search}
                        onChange={(event) => setSearchValue(event.target.value)}
                    />
                </Col>
            </Row>
            <div className={classes.ProductWrapper}>
                <Row gutter={[16, 16]}>
                    <Table
                        columns={columns}
                        dataSource={filterProductArray  == null ? adminProductData : filterProductArray}
                        rowKey={(obj) => obj.id}
                        size="small"
                        style={{ cursor: "pointer" }}
                        pagination={{
                            defaultPageSize: 10,
                            showSizeChanger: true,
                            showQuickJumper: true,
                            pageSizeOptions: ["10", "15", "20", "25"],
                            onChange(current) {
                                setPage(current);
                            },
                        }}
                        loading={loading}
                        bordered
                    />
                </Row>
            </div>
        </>
    )
}
export default Product;