import axios from "../api/instance";
import { message } from "antd";

export const setProduct = (Product) => ({
  type: "SET_ADMIN_PRODUCT",
  Product
});

export const fetchProductAsync = (setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.get('/default-product');
      const { data } = response;
      dispatch(setProduct(data));
      setLoading && setLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to fetch Product');
      setLoading && setLoading(false);
    }
  };
};

export const createProduct = (Product) => ({
  type: "ADD_ADMIN_PRODUCT",
  Product
});

export const createProductAsync = (ProductData, onCloseProductDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.post('/default-product', ProductData);     
      const { data } = response;
      dispatch(createProduct(data));
      message.success("Product has been created successfully!");
      onCloseProductDrawer && onCloseProductDrawer(); 
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create Product');
      setLoading && setLoading(false);
    }
  };
};

export const updateProduct = (Product) => ({
  type: "UPDATE_ADMIN_PRODUCT",
  Product
});

export const updateProductAsync = (ProductData, onCloseProductDrawer, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading && setLoading(true);
      const response = await axios.put(`/default-product/${ProductData.id}`, ProductData);
      const { data } = response;
      dispatch(updateProduct(data));
      message.success(" Product has been updated successfully!");    
      onCloseProductDrawer && onCloseProductDrawer();  
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update Product');
      setLoading && setLoading(false);
    }
  };
};

export const deleteProduct = (ProductId) => ({
  type: "DELETE_ADMIN_PRODUCT",
  ProductId
});

export const deleteProductAsync = (ProductId, setLoading) => {
  return async (dispatch) => {
    try {
      setLoading(true);
      await axios.delete(`/default-product/${ProductId}`);
      setLoading(false);
      dispatch(deleteProduct(ProductId));
      message.success("Product has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete Product');
      setLoading(false);
    }
  };
};
