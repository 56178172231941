const clientReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_CLIENT":
      return action.client;
    case "ADD_CLIENT":
      return [...state, action.clientData];
    case "UPDATE_CLIENT":
      return state.map(client => client.id === action.clientData.id ? action.clientData : client);
    default:
      return state;
  }
};

export default clientReducer;