/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Input, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import SupplyForm from './SupplyForm';
import { setSupply } from '../../../actions/supply';
import { setProduct } from '../../../actions/product';
import { setSupplier } from '../../../actions/supplier';

const Supply = (props) => {
  const [isSupplyModalvisible, setIsSupplyModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterSupply, setFilterSupply] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let supply = useSelector((state) => state.supply);
  let product = useSelector((state) => state.product);
  let supplier = useSelector((state) => state.supplier);
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/purchase');
        dispatch(setSupply(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch purchase");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/product');
        dispatch(setProduct(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch product");
      }
    }
    if (product.length === 0) {
      fetchData();
    } 
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/supplier');
        dispatch(setSupplier(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch supplier");
      }
    }
    if (supplier.length === 0) {
      fetchData();
    } 
  }, []);

  useEffect(() => {
    search();
  }, [supply]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      key: 'quantity',
      ellipsis: true,
      width: '15%'
    },
    {
      title: 'Total Amount',
      dataIndex: 'totalAmount',
      key: 'totalAmount',
      render: (totalAmount) => `$${totalAmount}`,
      ellipsis: true,
      width: '15%'
    },
    {
      title: 'Note',
      dataIndex: 'note',
      key: 'note',
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Supplier',
      dataIndex: 'supplier',
      key: 'supplier',
      render: (_, record) => record.supplier.name,
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Product',
      dataIndex: 'product',
      key: 'product',
      render: (_, record) => record.product.description,
      ellipsis: true,
      width: '25%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsSupplyModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '10%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = supply.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterSupply(filterTable);
    } else {
      setFilterSupply(null);
    }
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsSupplyModalVisible(true); setFormData(null) }} style={{ marginRight: 10 }}>
          Create Supply
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterSupply == null ? supply : filterSupply}
        rowKey={obj => obj.id}
        size="small"
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Supply" : "Create Supply"}
        centered
        destroyOnClose
        visible={isSupplyModalvisible}
        onOk={() => setIsSupplyModalVisible(false)}
        onCancel={() => setIsSupplyModalVisible(false)}
        width={800}
        footer={null}
      >
        <SupplyForm product={product} supplier={supplier} formData={formData} setIsSupplyModalVisible={setIsSupplyModalVisible} />
      </Modal>
    </>
  );
}

export default Supply;