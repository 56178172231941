import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Layout, Menu, Divider } from 'antd';
import { logoutAsync } from "../../actions/auth";
import { isCollapsed } from "../../actions/sider";
import { useStyles } from "./Styles";
import {
  UserOutlined,
  TeamOutlined,
  DashboardOutlined,
  SettingOutlined,
  FileDoneOutlined,
  PhoneOutlined,
  LogoutOutlined,
  FilePdfOutlined,
  TableOutlined,
  SwapOutlined,
  ShoppingOutlined,
  CalendarOutlined
} from '@ant-design/icons';
import { history } from '../../App';
import InvoiceEnglishCDLogo from '../../assets/InvoiceEnglishCDLogo.png';

const { Sider } = Layout;

const ShopManu = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [currentKey, setcurrentKey] = useState();
  const dispatch = useDispatch();
  const classes = useStyles();
  const isSiderCollapsed = useSelector(state => state.isCollapsed);

  let currentRoute = history.location.pathname.split('/')[2];
  currentRoute = currentRoute || 'dashboard';
  useEffect(() => {
    setcurrentKey(currentRoute);
  }, [currentRoute]);

  const onCollapse = collapsed => {
    setCollapsed(collapsed)
    dispatch(isCollapsed(collapsed));
  };
  const margin = isSiderCollapsed ? '8px 0' : '4px 0';
  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={onCollapse}
        width={240}
        style={{
          backgroundColor: 'white',
          boxShadow: '0 0.5px 4px 0 #d4d5d9',
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          zIndex: 1,
        }}
      >
        <div className={classes.logo}>
          <img
            src={InvoiceEnglishCDLogo}
            alt="logo"
            style={{ width: '50%', marginTop: 10, marginBottom: 10, cursor: 'pointer' }}
            onClick={() => history.push('/shop')}
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', minHeight: isSiderCollapsed ? '100%' : 'calc(100% - 54px)' }}>
          <Menu theme="light" mode="inline" selectedKeys={[currentKey]} onSelect={obj => setcurrentKey(obj.key)}>
            <Menu.Item key="dashboard" icon={<DashboardOutlined />} style={{ margin: margin }}>
              <Link to="/shop">Dashboard</Link>
            </Menu.Item>
            <Menu.Item key="appointmentCalender" icon={<CalendarOutlined />} style={{ margin: margin }}>
              <Link to="/shop/appointment-calendar">Appointments</Link>
            </Menu.Item>
            <Menu.Item key="clients" icon={<UserOutlined />} style={{ margin: margin }}>
              <Link to="/shop/clients">Clients</Link>
            </Menu.Item>
            <Menu.Item key="products" icon={<ShoppingOutlined />} style={{ margin: margin }}>
              <Link to="/shop/products">Products</Link>
            </Menu.Item>
            <Menu.Item key="vendors" icon={<TeamOutlined />} style={{ margin: margin }}>
              <Link to="/shop/vendors">Vendors</Link>
            </Menu.Item>
            <Menu.Item key="supply" icon={<SwapOutlined />} style={{ margin: margin }}>
              <Link to="/shop/supply">Supply</Link>
            </Menu.Item>
            <Menu.Item key="reports" icon={<TableOutlined />} style={{ margin: margin }}>
              <Link to="/shop/reports">Reports</Link>
            </Menu.Item>
            <Menu.Item key="forms" icon={<FilePdfOutlined />} style={{ margin: margin }}>
              <Link to="/shop/forms">Forms</Link>
            </Menu.Item>
            <Menu.Item key="taxes" icon={<FileDoneOutlined />} style={{ margin: margin }}>
              <Link to="/shop/taxes">Taxes</Link>
            </Menu.Item>
          </Menu>
          <div style={{ marginBottom: 46 }}>
            <Divider style={{ margin: '12px 0' }} />
            <Menu theme="light" mode="inline" selectedKeys={[currentKey]} onSelect={obj => setcurrentKey(obj.key)}>
              <Menu.Item key="setting" icon={<SettingOutlined />} style={{ margin: margin }}>
                <Link to="/shop/setting">Setting</Link>
              </Menu.Item>
              <Menu.Item key="order" icon={<PhoneOutlined />} style={{ margin: margin }}>
                <Link to="/shop/order">Contact / Order</Link>
              </Menu.Item>
              <Menu.Item key="logout" icon={<LogoutOutlined />} onClick={() => dispatch(logoutAsync())} style={{ margin: margin }}>
                Logout
              </Menu.Item>
            </Menu>
          </div>
        </div>
      </Sider>
    </>
  );
}

export default ShopManu;