import React from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { createBrowserHistory } from 'history';
import "./App.css";
import Login from "./containers/login/Login";
import ForgotPassword from "./containers/forgotPassword/ForgotPassword";
import ResetPassword from "./containers/resetPassword/ResetPassword";
import Admin from "./containers/admin/Admin";
import Shop from "./containers/shop/Shop";

export const history = createBrowserHistory();

function App() {
  return (
    <div className="App">
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/forgot-password" component={ForgotPassword} />
          <Route exact path="/reset-password" component={ResetPassword} />
          <Route path="/admin" component={Admin} />
          <Route path="/shop" component={Shop} />
          <Route path="*" component={() => <Redirect to="/" />} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;