import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  root: {
    '& table, th, td': {
      border: '1px solid black',
    },
    '& th': {
      textAlign: 'center'
    }
  },
  colPadding: {
    '& .ant-col-12': {
      padding: '0 8px'
    }
  }
});
