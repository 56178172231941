const appointmentReducer = (state = [], action) => {
    switch (action.type) {
      case "SET_APPOINTMENT":
        return action.appointment;
      case "ADD_APPOINTMENT":
        return [...state, action.appointmentData];
      case "UPDATE_APPOINTMENT":
        return state.map(appointment => appointment.id === action.appointmentData.id ? action.appointmentData : appointment);
      default:
        return state;
    }
  };
  
  export default appointmentReducer;