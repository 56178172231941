import React, { useEffect, useState } from 'react';
import { Upload, Input, Button, Row, Col } from 'antd';
import { InboxOutlined } from '@ant-design/icons';

const { Dragger } = Upload;

const FileUpload = (props) => {
  const [fileList, setFileList] = useState(null);
  const [fileTitle, setFileTitle] = useState(null);
  const [fileDescription, setFileDescription] = useState('');
  const [uploadfileLoading, setUploadfileLoading] = useState(false);
  const [fileTitleErrorMessage, setFileTitleErrorMessage] = useState('');
  const [fileErrorMessage, setFileErrorMessage] = useState('');

  useEffect(() => {
    if (fileTitle === '') {
      setFileTitleErrorMessage('File title is required');
    } else {
      setFileTitleErrorMessage('');
    }
  }, [fileTitle]);

  useEffect(() => {
    if (fileList && !fileList.length) {
      setFileErrorMessage('File is required');
    } else {
      setFileErrorMessage('');
    }
  }, [fileList]);

  const handleBeforeUpload = (uploadFile, fileList) => {
    // Limit the number of uploaded files
    // Only to show One recent uploaded files, and old ones will be replaced by the new
    fileList = fileList.slice(-1);
    setFileList(fileList);
    return false;
  };

  const uploadFile = () => {
    !fileTitle && setFileTitleErrorMessage('File title is required');
    (fileList === null || !fileList.length) && setFileErrorMessage('File is required');
    if (fileTitle && fileList.length) {
      props.uploadFile(fileList, fileTitle, fileDescription, setUploadfileLoading);
    }
  };

  return (
    <div>
      <Row gutter={[10, 0]}>
        <Col span={6} >
          <Input placeholder="File title" onChange={event => setFileTitle(event.target.value)} maxLength={100} required />
          <span style={{ color: 'red' }}>{fileTitleErrorMessage}</span>
        </Col>
        <Col span={14}>
          <Input placeholder="File description" onChange={event => setFileDescription(event.target.value)} maxLength={255} />
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={uploadFile} style={{ width: '100%' }} loading={uploadfileLoading}>Upload</Button>
        </Col>
      </Row>
      <Dragger
        onRemove={() => setFileList([])}
        beforeUpload={handleBeforeUpload}
        fileList={fileList}
        style={{ marginTop: 10 }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">Click or drag file to this area to upload (Max 10Mb)</p>
      </Dragger>
      <span style={{ color: 'red' }}>{fileErrorMessage}</span>
    </div>
  );
}

export default FileUpload;
