/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Input, Row, Col, Switch, InputNumber } from 'antd';
// import { useStyles } from './styles'
import { updateTaxAsync, createTaxAsync } from '../../../actions/tax';

const TaxForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    if (formData) {
      const taxData = {...formData, ...values.tax};
      dispatch(updateTaxAsync(taxData, props.setIsTaxModalVisible, setLoading));
    } else {
      dispatch(createTaxAsync(values.tax, props.setIsTaxModalVisible, setLoading));
    }
  };

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="tax_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['tax', 'name']}
            label={'Name'}
            initialValue={formData ? formData['name'] : ''}
            rules={[{ required: true }]}
          >
            <Input placeholder={'Name'} maxLength={64} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['tax', 'percentage']}
            label={'Percentage'}
            initialValue={formData ? formData['percentage'] : ''}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={'Percentage'} precision={3} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['tax', 'isActive']}
            label="Status"
            initialValue={formData ? formData.isActive : true}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default TaxForm;