import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  formWrapper: {
    marginTop: '16px',
  },
  meta: {
    '& .ant-card-meta-title': {
      fontSize: '14px',
    },
  },
}));