import React, { useState, useEffect } from "react";
import { Row, Col } from "antd";
import AppointmentForm from "./AppointmentForm";
import AppointmentCalendar from "./AppointmentCalendar";
import { history } from '../../../App';

const Appointment = () => {
  const [formData, setFormData] = useState(null);
  const [isFormWith, setIsFormWith] = useState(true);
  const [nowIndicatorTime, setNowIndicatorTime] = useState(null);
  const formState = history?.location?.state;

  useEffect(() => {
    if (formState) {
      setFormData(formState);
    }
  }, [formState])

  return (
    <>
      <Row span={24} style={{ margin: "20px 0px", display: "flex" }}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
          {isFormWith && (
            <AppointmentForm
              formData={formData}
              setNowIndicatorTime={setNowIndicatorTime}
              setFormData={setFormData}
              setIsFormWith={setIsFormWith}
            />)}
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={isFormWith ? { span: 12 } : { span: 24 }}>
          <AppointmentCalendar
            setFormData={setFormData}
            formData={formData}
            nowIndicatorTime={nowIndicatorTime}
            isFormWith={isFormWith}
            setNowIndicatorTime={setNowIndicatorTime}
          />
        </Col>
      </Row>
    </>
  );
};

export default Appointment;
