import axios from "../api/instance";
import { message } from "antd";

export const setClientFile = (clientfile) => ({
  type: "SET_CLIENT_FILE",
  clientfile
});

export const addClientFile = (clientfile) => ({
  type: "ADD_CLIENT_FILE",
  clientfile
});

export const deleteClientFile = (fileId) => ({
  type: "DELETE_CLIENT_FILE",
  fileId
});

export const deleteClientFileAsync = (clientId, fileId, setDeleteFileLoading) => {
  return async (dispatch) => {
    try {
      setDeleteFileLoading(true);
      await axios.delete(`/client/${clientId}/file/${fileId}`);
      setDeleteFileLoading(false);
      dispatch(deleteClientFile(fileId));
      message.success("File has been deleted successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to delete file');
      setDeleteFileLoading(false);
    }
  };
};

export const downloadClientFileAsync = (clientId, fileId, setDownloadFileLoading) => {
  return async (dispatch) => {
    try {
      setDownloadFileLoading(true);
      const response = await axios.get(`/client/${clientId}/file/${fileId}/download`);
      window.open(response.data, "_blank");
      setDownloadFileLoading(false);
      message.success("File has been downloaded successfully!");
    } catch(err) {
      console.log(err);
      message.error('Failed to download file');
      setDownloadFileLoading(false);
    }
  };
};