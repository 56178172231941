/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Modal, Table, Input, message } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { useStyles } from './styles';
import OrderForm from './OrderForm';
import { setOrder } from '../../../actions/order';

const Order = (props) => {
  const [isOrderModalvisible, setIsOrderModalVisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterOrder, setFilterOrder] = useState(null);
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  let order = useSelector((state) => state.order);
  const classes = useStyles();
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/order-form');
        dispatch(setOrder(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch order");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  useEffect(() => {
    search();
  }, [order]);

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
      key: 'id',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      ellipsis: true,
      width: '5%'
    },
    {
      title: 'Contact Name',
      dataIndex: 'contactName',
      key: 'contactName',
      ellipsis: true
    },
    {
      title: 'Contact Email',
      dataIndex: 'contactEmail',
      key: 'contactEmail',
      ellipsis: true
    },
    {
      title: 'Contact Phone',
      dataIndex: 'contactPhone',
      key: 'contactPhone',
      ellipsis: true
    },
    {
      title: 'Order Status',
      dataIndex: 'orderFormStatus',
      key: 'orderFormStatus',
      ellipsis: true
    },
    {
      title: '# Products',
      dataIndex: 'orderFormLineItems',
      key: 'orderFormLineItems',
      render:(orderFormLineItems,record)=>orderFormLineItems.length,
      ellipsis: true,
      width: '10%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      render: (_, record) => <Button type="link" style={{ padding: 0 }} onClick={() => { setFormData(record); setIsOrderModalVisible(true) }}>Edit</Button>,
      ellipsis: true,
      width: '8%'
    },
  ];

  const search = value => {
    if (searchValue) {
      const filterTable = order.filter(o =>
        Object.keys(o).some(k =>
          String(o[k])
            .toLowerCase()
            .includes(searchValue.toLowerCase())
        )
      );
      setFilterOrder(filterTable);
    } else {
      setFilterOrder(null);
    }
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
          onChange={(event) => setSearchValue(event.target.value)}
        />
        <Button type="primary" onClick={() => { setIsOrderModalVisible(true); setFormData(null) }} style={{ marginRight: 10, marginLeft: 10 }}>
        Create Message - Order
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterOrder == null ? order : filterOrder}
        rowKey={obj => obj.id}
        size="small"
        style={{ cursor: 'pointer' }}
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
        loading={tableLoading}
        bordered
      />
      <Modal
        title={formData ? "Edit Message - Order" : "Create Message - Order "}
        centered
        destroyOnClose
        visible={isOrderModalvisible}
        onOk={() => setIsOrderModalVisible(false)}
        onCancel={() => setIsOrderModalVisible(false)}
        width={1000}
        footer={null}
      >
        <OrderForm formData={formData} setIsOrderModalVisible={setIsOrderModalVisible} />
      </Modal>
    </>
  );
}

export default Order;