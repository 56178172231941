import axios from "../api/instance";
import { message } from "antd";

export const setSupply = (supply) => ({
  type: "SET_SUPPLY",
  supply
});

export const createSupply = (supplyData) => ({
  type: "ADD_SUPPLY",
  supplyData
});

export const createSupplyAsync = (supply, setIsSupplyModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.post('/purchase', supply);
      const { data } = response;
      dispatch(createSupply(data));
      message.success("Purchase has been created successfully!");
      setIsSupplyModalVisible && setIsSupplyModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to create purchase');
      setLoading && setLoading(false);
    }
  };
};

export const updateSupply = (supplyData) => ({
  type: "UPDATE_SUPPLY",
  supplyData
});

export const updateSupplyAsync = (supply, setIsSupplyModalVisible, setLoading) => {
  return async (dispatch) => {
    try {
      const response = await axios.put(`/purchase/${supply.id}`, supply);
      const { data } = response;
      dispatch(updateSupply(data));
      message.success("Purchase has been updated successfully!");
      setIsSupplyModalVisible && setIsSupplyModalVisible(false);
      setLoading && setLoading(false);
    } catch(err) {
      console.log(err);
      message.error('Failed to update purchase');
      setLoading && setLoading(false);
    }
  };
};