const adminShopReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_SHOP":
      return action.shop;
    case "ADD_SHOP":
      return [...state, action.shopData];
    case "UPDATE_SHOP":
      return state.map(shop => shop.shopId === action.shopData.shopId ? action.shopData : shop);
    default:
      return state;
  }
};

export default adminShopReducer;