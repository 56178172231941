/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-template-curly-in-string */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import axios from '../../../api/instance';
import { updateShopAsyc } from '../../../actions/auth';
import { message, Form, Input, Button, InputNumber, Popconfirm, Row, Select } from 'antd';

const { Option } = Select;

const Setting = () => {
  const [profile, setProfile] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditModeForNextInvoice, setIsEditModeForNextInvoice] = useState(false);
  const [loading, setLoading] = useState(false);
  const [nextInvoiceIdloading, setNextInvoiceIdLoading] = useState(false);
  const [nextInvoiceId, setNextInvoiceId] = useState('');
  const [updatedNextInvoiceId, setUpdatedNextInvoiceId] = useState('');
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop/profile');
        setProfile(response.data);
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch profile");
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop/next-invoice-id');
        setNextInvoiceId(response.data.nextInvoiceId);
        setUpdatedNextInvoiceId(response.data.nextInvoiceId);
      } catch (err) {
        console.log(err);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    form.setFieldsValue({ profile });
  }, [profile]);

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = async (values) => {
    setLoading(true);
    const shopData = { ...profile, ...values.profile };
    dispatch(updateShopAsyc(shopData, setIsEditMode, setLoading));
  };

  const submitNextInvoiceId = async () => {
    if (updatedNextInvoiceId > nextInvoiceId) {
      setNextInvoiceIdLoading(true);
      try {
        console.log(nextInvoiceId, updatedNextInvoiceId);
        const response = await axios.put('/shop/next-invoice-id', { nextInvoiceId: updatedNextInvoiceId });
        setNextInvoiceId(response.data.nextInvoiceId);
        setUpdatedNextInvoiceId(response.data.nextInvoiceId);
        setNextInvoiceIdLoading(false);
        message.success('Next invoice id has been updated successfully')
      } catch (err) {
        console.log(err);
        message.error('Fail to update next invoice id');
        setNextInvoiceIdLoading(false);
      }
    } else {
      message.info('Next invoice id is not less then current invoiceId');
    }
  }

  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginTop: 16 }}>
      <Form
        {...formItemLayout}
        form={form}
        name="product_form"
        onFinish={onFinish}
        validateMessages={validateMessages}
        style={{ width: '500px' }}
      >
        <Form.Item
          name={['profile', 'name']}
          label={'Name'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Name'} maxLength={64}  disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'email']}
          label={'Email'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Email'} maxLength={64} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'website']}
          label={'Website'}
        >
          <Input placeholder={'Website'} maxLength={255} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'addressLine1']}
          label={'Address line 1'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Address line 1'} maxLength={100} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'addressLine2']}
          label={'Address line 2'}
        >
          <Input placeholder={'Address line 2'} maxLength={100} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'city']}
          label={'City'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'City'} maxLength={30} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'state']}
          label={'State/Province'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'State/Province'} maxLength={30} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'zipcode']}
          label={'Zip/Postal Code'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Zip Code/Postal Code'} maxLength={10} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'telephone']}
          label={'Telephone'}
        >
          <Input placeholder={'Telephone'} maxLength={30} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'businessNumber']}
          label={'Business number'}
        >
          <Input placeholder={'Business number'} maxLength={30} disabled={!isEditMode} />
        </Form.Item>
        <Form.Item
          name={['profile', 'createdAt']}
          label={'Created at'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Created at'} disabled />
        </Form.Item>
        <Form.Item
          name={['profile', 'updatedAt']}
          label={'Updated at'}
          rules={[{ required: true }]}
        >
          <Input placeholder={'Updated at'} disabled />
        </Form.Item>
        <Form.Item
          name={['profile', 'defaultInvoiceLanguage']}
          label={'Default Invoice Lang'}
          rules={[{ required: true }]}
        >
          <Select disabled={!isEditMode}>
            <Option value="ENGLISH">English</Option>
            <Option value="FRENCH">French</Option>
            <Option value="SPANISH">Spanish</Option>
          </Select>
        </Form.Item>
        {isEditMode ?
          <Form.Item wrapperCol={{ offset: 12 }}>
            <Row>
              <Button type="primary" htmlType="submit" loading={loading}>Submit</Button>
              <Button type="link" onClick={() => setIsEditMode(false)}>Cancel</Button>
            </Row>
          </Form.Item> :
          <Form.Item wrapperCol={{ offset: 13 }}>
            <Button type="link" onClick={() => setIsEditMode(true)}>
              Edit
            </Button>
          </Form.Item>
        }
      </Form>
      <div style={{ marginLeft: 150 }}>
        <span>Next Invoice Id</span>
        <InputNumber value={updatedNextInvoiceId} disabled={!isEditModeForNextInvoice} precision={0} onChange={setUpdatedNextInvoiceId} style={{ width: '90%' }} /><br />
        {isEditModeForNextInvoice ?
          <>
            <Popconfirm
              title="The action you are trying to perform can not be reverted."
              onConfirm={submitNextInvoiceId}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <Button type="primary" loading={nextInvoiceIdloading} style={{ marginTop: 5 }}>Update</Button>
            </Popconfirm>
            <Button type="link" onClick={() => setIsEditModeForNextInvoice(false)}>Cancel</Button>
          </> :
          <Button type="link" onClick={() => setIsEditModeForNextInvoice(true)} style={{ padding: 0 }}>Edit</Button>
        }
      </div>
    </div>
  );
}

export default Setting;