/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Button, Switch, Table, Modal, Input, message, Popconfirm, Tag } from 'antd';
import { EditOutlined, MailOutlined, LoadingOutlined } from '@ant-design/icons'
import { useSelector, useDispatch } from 'react-redux';
import axios from "../../../api/instance";
import { useStyles } from "./styles";
import { setShop, updateShopAsync } from '../../../actions/adminShop';
import ShopForm from './ShopForm'

const Shop = () => {
  const [isShopModalvisible, setIsShopModalvisible] = useState(false);
  const [formData, setFormData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);
  const [filterAdminShop, setFilterAdminShop] = useState(null);
  const [page, setPage] = useState(1);
  const adminShop = useSelector((state) => state.adminShop);
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('/shop');
        dispatch(setShop(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch shop");
      }
      setTableLoading(false);
    }
    fetchData();
  }, []);

  const sendMessage = async (shopId) => {
    setMessageLoading(true);
    try {
      await axios.put('/admin/resend-shop-invitation', { shopId });
      message.success('send mail successfully');
      setMessageLoading && setMessageLoading(false);
    } catch (err) {
      console.log(err);
      message.error('Failed to send message');
      setMessageLoading && setMessageLoading(false);
    }
  }
  const showDrawer = () => {
    setIsShopModalvisible(true);
  };

  const onCloseShopDrawer = () => {
    setIsShopModalvisible(false);
  };

  const columns = [
    {
      title: 'Id',
      dataIndex: 'shopId',
      key: 'shopId',
      align: 'right',
      render: (text, record, index) => (page - 1) * 10 + (index + 1),
      width: '5%'
    },
    {
      title: 'Name',
      dataIndex: 'shopName',
      key: 'shopName',
      ellipsis: true
    },
    {
      title: 'Email',
      dataIndex: 'shopEmail',
      key: 'shopEmail',
      ellipsis: true
    },
    {
      title: 'Telephone',
      dataIndex: 'shopTelephone',
      key: 'shopTelephone',
      ellipsis: true
    },
    {
      title: 'Address Line 2',
      dataIndex: 'shopAddressLine2',
      key: 'shopAddressLine2',
      ellipsis: true
    },
    {
      title: 'City',
      dataIndex: 'shopCity',
      key: 'shopCity',
      ellipsis: true
    },
    {
      title: 'State',
      dataIndex: 'shopState',
      key: 'shopState',
      ellipsis: true
    },
    {
      title: 'Demo Shop',
      dataIndex: 'isDemoShop',
      key: 'isDemoShop',
      render: (isDemoShop, record) => isDemoShop ? <Tag color="red">YES</Tag> : <Tag color="green">NO</Tag>,
      ellipsis: true,
      align: 'center',
      width: '8%'
    },
    {
      title: 'Status',
      dataIndex: 'isActive',
      key: 'isActive',
      align: 'center',
      render: (isActive, record) => <Switch checked={isActive} loading={loading} onChange={(_, event) => {
        event.stopPropagation();
        setLoading(true);
        const newRecord = JSON.parse(JSON.stringify(record));
        newRecord.isActive = !isActive;
        dispatch(updateShopAsync(newRecord, null, setLoading));
      }} />,
      width: '8%'
    },
    {
      title: 'Action',
      dataIndex: '',
      key: 'x',
      align: 'center',
      render: (_, record) => <><EditOutlined style={{ padding: 0, marginRight: '10px' }} onClick={() => { setFormData(record); showDrawer() }} />
        {
          messageLoading ? <LoadingOutlined /> :
            <Popconfirm
              title="Are you sure to re-send mail."
              onConfirm={() => sendMessage(record.shopId)}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <MailOutlined onChange={(_, event) => {
                event.stopPropagation();
                setMessageLoading(true);
              }}
              />
            </Popconfirm>
        }
      </>,
      width: '8%'
    },
  ];

  const search = value => {
    const filterTable = adminShop.filter(o =>
      Object.keys(o).some(k =>
        String(o[k])
          .toLowerCase()
          .includes(value.toLowerCase())
      )
    );
    setFilterAdminShop(filterTable);
  };

  return (
    <>
      <div className={classes.searchWrapper}>
        <Input.Search
          style={{ width: '300px' }}
          placeholder="Search here..."
          enterButton
          onSearch={search}
        />
        <Button type="primary" onClick={() => { setFormData(null); showDrawer() }} style={{ marginRight: 10 }}>
          Create Shop
        </Button>
      </div>
      <Table
        columns={columns}
        dataSource={filterAdminShop == null ? adminShop : filterAdminShop}
        rowKey={obj => obj.shopId}
        size="small"
        loading={tableLoading}
        bordered
        pagination={{
          defaultPageSize: 10,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onChange(current) {
            setPage(current);
          }
        }}
      />
      <Modal
        title={formData ? "Edit Shop" : "Create Shop"}
        centered
        destroyOnClose
        visible={isShopModalvisible}
        onOk={onCloseShopDrawer}
        onCancel={onCloseShopDrawer}
        width={800}
        footer={null}
      >
        <ShopForm formData={formData} onCloseShopDrawer={onCloseShopDrawer} />
      </Modal>
    </>
  );
}

export default Shop;