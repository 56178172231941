import React from 'react';
import { Tabs } from 'antd';
import VehicleReport from './VehicleReport';
import InvoiceReport from './InvoiceReport';

const { TabPane } = Tabs;

const Report = () => {
  return (
    <Tabs defaultActiveKey="1">
    <TabPane tab="Invoice Report" key="1">
      <InvoiceReport />
    </TabPane>
      <TabPane tab="Vehicle Report" key="2">
        <VehicleReport />
      </TabPane>
    </Tabs>
  );
};

export default Report;