/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-template-curly-in-string */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Button, Row, Col, Switch, InputNumber, Select, Input } from 'antd';
// import { useStyles } from './styles'
import { updateSupplyAsync, createSupplyAsync } from '../../../actions/supply';

const { Option } = Select;

const SupplyForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  // const classes = useStyles();
  const { formData } = props;

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: '${label} is required!',
    types: {
      email: '${label} is not a valid email!',
      number: '${label} is not a valid number!',
    },
    number: {
      range: '${label} must be between ${min} and ${max}',
    },
  };

  const onFinish = values => {
    setLoading(true);
    values.supply.product = props.product.find(obj => obj.id === values.supply.product);
    values.supply.supplier = props.supplier.find(obj => obj.id === values.supply.supplier)
    if (formData) {
      values.supply.id = formData.id;
      dispatch(updateSupplyAsync(values.supply, props.setIsSupplyModalVisible, setLoading));
    } else {
      dispatch(createSupplyAsync(values.supply, props.setIsSupplyModalVisible, setLoading));
    }
  };

  const onChangeProduct = id => {
    const selectedProduct = props.product.find(obj => obj.id === id);
    const fieldValue = form.getFieldValue('supply');
    const quantity = fieldValue.quantity === '' ? 0 : fieldValue.quantity;
    form.setFieldsValue({ supply: { totalAmount: quantity * selectedProduct.retailPrice } });
  };

  const onChangeQuantity = quantity => {
    const fieldValue = form.getFieldValue('supply');
    const productId = fieldValue.product ? fieldValue.product : null;
    const selectedProduct = productId ? props.product.find(obj => obj.id === productId) : {retailPrice: 0};
    form.setFieldsValue({ supply: { totalAmount: quantity * selectedProduct.retailPrice } });
  }

  return (
    <Form
      {...formItemLayout}
      form={form}
      name="supply_form"
      onFinish={onFinish}
      validateMessages={validateMessages}
    >
      <Row gutter={24}>
        <Col span={12}>
          <Form.Item
            name={['supply', 'supplier']}
            label={'Supplier'}
            initialValue={formData ? formData['supplier']['id'] : null}
            rules={[{ required: true }]}
          >
            <Select placeholder="Supplier" style={{ width: '100%' }}>
              {props.supplier.filter(obj => obj.isActive === true).map(obj => <Option key={obj.id} value={obj.id}>{obj.name}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supply', 'product']}
            label={'Product'}
            initialValue={formData ? formData['product']['id'] : null}
            rules={[{ required: true }]}
          >
            <Select placeholder="Product" style={{ width: '100%' }} onChange={onChangeProduct}>
              {props.product.filter(obj => obj.isActive === true).map(obj => <Option key={obj.id} value={obj.id}>{`${obj.description}-${obj.size}`}</Option>)}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supply', 'quantity']}
            label={'Quantity'}
            initialValue={formData ? formData['quantity'] : ''}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={'Quantity'} style={{ width: '100%' }} onChange={onChangeQuantity} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supply', 'totalAmount']}
            label={'Total Amount'}
            initialValue={formData ? formData['totalAmount'] : ''}
            rules={[{ required: true }]}
          >
            <InputNumber placeholder={'Total Amount'} precision={2} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supply', 'note']}
            label="Note"
            initialValue={formData ? formData.note : ''}
          >
            <Input placeholder={'Note'} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name={['supply', 'isCancelled']}
            label="Is Cancelled"
            initialValue={formData ? formData.isCancelled : false}
            valuePropName="checked"
          >
            <Switch />
          </Form.Item>
        </Col>
      </Row>
      <Row>
        <Col span={24} style={{ textAlign: 'right' }}>
          <Button type="primary" htmlType="submit" loading={loading}>
            Submit
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default SupplyForm;