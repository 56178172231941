import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";
import authReducer from "../reducers/auth";
import adminShopReducer from "../reducers/adminShop";
import clientReducer from "../reducers/client";
import vehicleReducer from "../reducers/vehicle";
import vehicleServiceReducer from "../reducers/vehicleService";
import siderReducer from "../reducers/sider";
import taxReducer from "../reducers/tax";
import productReducer from "../reducers/product";
import supplierReducer from "../reducers/supplier";
import clientFileReducer from "../reducers/clientFile";
import invoiceFileReducer from "../reducers/invoiceFile";
import supplyReducer from "../reducers/supply";
import orderReducer from "../reducers/order";
import adminBrochureReducer from '../reducers/adminBrochure';
import adminCDProductReducer from '../reducers/adminCDProduct';
import adminProductReducer from '../reducers/adminProduct';
import appointmentReducer from '../reducers/appointment';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const configureStore = () => {
  const store = createStore(
    combineReducers({
      auth: authReducer,
      adminShop: adminShopReducer,
      client: clientReducer,
      vehicle: vehicleReducer,
      vehicleService: vehicleServiceReducer,
      isCollapsed: siderReducer,
      tax: taxReducer,
      product: productReducer,
      supplier: supplierReducer,
      clientFile: clientFileReducer,
      invoiceFile: invoiceFileReducer,
      supply: supplyReducer,
      order: orderReducer,
      adminBrochure: adminBrochureReducer,
      adminCDProduct: adminCDProductReducer,
      adminProduct: adminProductReducer,
      appointment: appointmentReducer,
    }),
    composeEnhancers(applyMiddleware(thunk))
  );
  return store;
};

export default configureStore;