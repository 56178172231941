const invoiceFileReducer = (state = [], action) => {
  switch (action.type) {
    case "SET_INVOICE_FILE":
      return action.invoiceFile;
    case "ADD_INVOICE_FILE":
      return [...state, action.invoiceFile];
    case "DELETE_INVOICE_FILE":
      return state.filter(invoiceFile => invoiceFile.id !== action.fileId);
    default:
      return state;
  }
};

export default invoiceFileReducer;