import React, { useState } from 'react';
import { List, Card, Popconfirm } from 'antd';
 import {LoadingOutlined,CloudDownloadOutlined,DeleteOutlined}  from '@ant-design/icons'

const FileDisplay = (props) => {
  const [deletefileLoading, setDeleteFileLoading] = useState(false);
  const [downloadfileLoading, setDownloadFileLoading] = useState(false);
  const [fileId, setFileId] = useState(null);
  const { fileList = [], deleteFile, downloadFile } = props;
 
  return (
    <div>
      <List
        grid={{ gutter: 8, xs: 1, sm: 2, md: 4, lg: 6, xl: 7, xxl: 8 }}
        dataSource={fileList}
        renderItem={item => (
          <List.Item>
            <Card
              size="small"
              title={null}
              hoverable
              actions={[
                (downloadfileLoading && fileId === item.id) ? <LoadingOutlined /> : <CloudDownloadOutlined key="CloudDownloadOutlined" onClick={(event) => { event.stopPropagation(); window.open(`${process.env.REACT_APP_API_BASE_URL}/${downloadFile}/${item.id}/download/${item.name}`, '_blank')}} />,
                (deletefileLoading && fileId === item.id) ?
                  <LoadingOutlined /> :
                  <Popconfirm
                    title="Are you sure to delete this file?"
                    onConfirm={() => { setFileId(item.id); deleteFile(item.id, setDeleteFileLoading) }}
                    onCancel={() => { }}
                    okText="Yes"
                    cancelText="No"
                  >
                    <DeleteOutlined key="DeleteOutlined" />
                  </Popconfirm>,
              ]}
              bodyStyle={{ padding: 7, height: 34, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
            >
              <span title={item.title}>{item.title}</span>
            </Card>
          </List.Item>
        )}
      />
    </div>
  );
}

export default FileDisplay;
