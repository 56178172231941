import React, { useState } from 'react';
// import { useSelector, useDispatch } from 'react-redux';
import { Link as RouteLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import { message } from 'antd';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
// import queryString from 'query-string';
import { useStyles } from "./styles";
import axios from "../../api/instance";
import ShopManagerLogo from '../../assets/ShopManagerLogoBlue.png';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`© ${new Date().getFullYear()} `}
      <Link color="inherit" href="https://www.corrosiondefence.com/" target="_blank" >
        Corrosion Defense Inc. &nbsp;
      </Link>
      All Rights Reserved
    </Typography>
  );
}

const ForgotPassword = (props) => {
  const [email, setEmail] = useState(null);
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const sendResetPasswordEmail = async () => {
    try {
      await axios.post('/auth/send-reset-password-email', { email });
      message.success('Check your email for reset password link'); 
      setLoading(false);
    } catch(err) {
      console.log(err.message);
      message.error('Not able to send password reset email');
      setLoading(false);
    }
  };

  return (
    <div className={classes.rootContainer}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            className={classes.avatar}
            src={ShopManagerLogo} alt="logo" >
          </img>
          <Typography component="h1" variant="h5">
            Reset your password
          </Typography>
          <form 
            className={classes.form} 
            validate="true" 
            onSubmit={(event) => {
              event.preventDefault();
              setLoading(true);
              sendResetPasswordEmail();
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              label={email !== "" ? "Email Address" : "Please enter email"}
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
              value={email !== null ? email : ''}
              error={email === ""}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Send password reset email"}
            </Button>
            <Grid container>
              <Grid item xs>
                <RouteLink to="/">
                  Back to sign in
                </RouteLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
};

export default ForgotPassword;