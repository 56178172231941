import React, { useState, useEffect } from "react";
import {
  Button,
  Select,
  Form,
  Modal,
  message,
  Row,
  Col,
  Input,
  Calendar,
  AutoComplete,
} from "antd";
import { filterOption, filterOptionAutoComplete } from "../../shop/helper";
import { useSelector, useDispatch } from "react-redux";
import { setClient } from "../../../actions/client";
import { setVehicle } from "../../../actions/vehicle";
import axios from "../../../api/instance";
import {
  createAppointmentAsync,
  updateAppointmentAsync,
} from "../../../actions/appointment";
import moment from "moment";
import ClientForm from "../client/ClientForm";
import VehicleForm from "../vehicle/VehicleForm";
import { setProduct } from '../../../actions/product';

const { Option } = Select;

const AppointmentForm = (props) => {
  const [loading, setLoading] = useState(false);
  const [isClientModalvisible, setIsClientModalVisible] = useState(false);
  const [ClientId, setClientId] = useState(null);
  const [vehiclesList, setVehiclesList] = useState([]);
  const [selectedTime, setSelectedTime] = useState(null);
  const [productDescription, setProductDescription] = useState([]);
  const [isVehicleModalvisible, setIsVehicleModalVisible] = useState(false);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  let client = useSelector((state) => state.client);
  let vehicles = useSelector((state) => state.vehicle);
  let product = useSelector((state) => state.product);
  const { formData, setNowIndicatorTime, setFormData, setIsFormWith } = props;

  useEffect(() => {
    setVehiclesList(vehicles);
  }, [vehicles])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await axios.get("/client");
        dispatch(setClient(response.data));
      } catch (err) {
        console.log(err);
        message.error("Failed to fetch client");
      }
      setLoading(false);
    };
    fetchData();
    fetchProductData();
  }, []);

  useEffect(() => {
    setLoading(true);
    let arrDescription = []
    product?.forEach((item) => {
      let obj = {};
      obj.value = item.description + ' ' + item.size;
      arrDescription.push(obj);
    });
    setProductDescription(arrDescription);
    setLoading(false);
  }, [product])

  const fetchProductData = async () => {
    setLoading(true);
    try {
      const response = await axios.get('/product');
      dispatch(setProduct(response.data));
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch product");
    }
    setLoading(false);
  }

  const timeSlotUI = () => {
    function getTimeStops(start, end) {
      var startTime = moment(start, 'HH:mm');
      var endTime = moment(end, 'HH:mm');

      if (endTime.isBefore(startTime)) {
        endTime.add(1, 'day');
      }

      var timeStops = [];

      while (startTime <= endTime) {
        timeStops.push(new moment(startTime).format('HH:mm'));
        startTime.add(30, 'minutes');
      }
      return timeStops;
    }

    var timeStops = getTimeStops('07:00', '19:00');
    return (<> {timeStops.map((item, index) => (<Button onClick={() => onChangeTime(item)} key={index} style={{ margin: '5px' }} type={item == selectedTime && 'primary'}>{item}</Button>))} </>);
  }

  useEffect(() => {
    if (formData) {
      const appointmentDate = moment(formData?.appointmentDate)
      const appointmentTime = moment(formData?.appointmentTime, "HH:mm");
      const appointmentMin = moment(appointmentTime).format('mm')

      if (appointmentMin > 0 && appointmentMin < 30) {
        appointmentTime.startOf('hour')
      } else if (appointmentMin < 31 && appointmentMin > 59) {
        appointmentTime.add(10, 'minute').endOf('hour')
      }

      setSelectedTime(moment(appointmentTime).format('HH:mm'));
      const formDetails = formData?.id ? { id: formData?.id, clientId: formData?.clientId, vehicleId: formData?.vehicleId, subject: formData?.subject } : form.getFieldsValue()?.appointment;
      form.setFieldsValue({
        appointment: {
          ...formDetails,
          appointmentDate: appointmentDate, appointmentTime: appointmentTime
        }
      });
      appointmentDate && setNowIndicatorTime(appointmentDate);
    }
  }, [formData]);

  const onClearFormDetails = () => {
    form.setFieldsValue({
      appointment: {
        id: '',
        clientId: '', vehicleId: '', subject: '',
        appointmentDate: moment(),
        appointmentTime: ''
      }
    });
    setFormData(null);
    setSelectedTime(null);
  }

  const onChangeTime = (time) => {
    setSelectedTime(time);
    const appointmentTime = moment(time, 'HH:mm');
    form.setFieldsValue({ appointment: { appointmentTime: appointmentTime } });
  };

  const onChangeClient = (id) => {
    const fieldValue = form.getFieldValue("appointment");
    const client = fieldValue.clientId;
    setClientId(client);
    setVehiclesList([]);
    form.setFieldsValue({ appointment: { vehicleId: '' } })
    client && fetchVehicleData(client);
  };

  const onChangeAppointmentDate = (value) => {
    const fieldValue = form.getFieldValue("appointment");
    const date = value.format("YYYY-MM-DD");
    const IndicatorTime = date;
    setNowIndicatorTime(IndicatorTime);
  };

  const setClientIdInAppointmentForm = (clientId) => {
    form.setFieldsValue({ appointment: { clientId: clientId } });
    setClientId(clientId);
    fetchVehicleData(clientId);
  }

  const setVehicleIdInAppointmentForm = (vehicleId) => {
    form.setFieldsValue({ appointment: { vehicleId: vehicleId } });
  }

  const fetchVehicleData = async (clientId) => {
    setLoading(true);
    try {
      const response = await axios.get(`/client/${clientId}/vehicle`);
      dispatch(setVehicle(response.data));
      setLoading(false);
    } catch (err) {
      console.log(err);
      message.error("Failed to fetch vehicle");
      setLoading(false);
    }
  };

  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 8 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 },
    },
  };

  const validateMessages = {
    required: "${label} is required!",
    types: {
      email: "${label} is not a valid email!",
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };

  const onFinish = (values) => {

    setLoading(true);
    const testDate = values.appointment.appointmentDate.format().slice(0, 10);
    const testTime = values.appointment.appointmentTime.format().slice(11, 19);
    values.appointment.appointmentDate = testDate;
    values.appointment.appointmentTime = testTime;
    if (form.getFieldValue(["appointment", "id"])) {
      const appointmentData = { ...formData, ...values.appointment };
      dispatch(updateAppointmentAsync(appointmentData, setLoading, setFormData));
    } else {
      dispatch(createAppointmentAsync(values.appointment, setLoading));
    }
    setNowIndicatorTime(testDate);
    onClearFormDetails();
  };

  return (
    <>
      <Form
        {...formItemLayout}
        form={form}
        name="appointment_form"
        onFinish={onFinish}
        validateMessages={validateMessages}
      >
        <Row gutter={24}>
          <Col span={22} style={{ display: 'none' }}>
            <Form.Item
              name={["appointment", "id"]}
              label={"id"}
              initialValue={formData?.id}
            >
              <Input type="hidden" />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              name={["appointment", "subject"]}
              label={"Service"}
              initialValue={formData?.subject}
              // rules={[{ required: "Service is required!" }]}
              rules={[{ required: true }]}
            >
              <AutoComplete placeholder={"Service"} maxLength={255} options={productDescription} showSearch filterOption={filterOptionAutoComplete} />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              name={["appointment", "clientId"]}
              label={"Client"}
              initialValue={formData?.clientId}
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                filterOption={filterOption}
                onChange={onChangeClient}
                defaultValue=""
              >
                <Option value=''>Select Client</Option>
                {client.map((client) => (
                  <Option key={client.id} value={client.id}>{`${client.firstname
                    } ${client.lastname || ""}`}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={2}
            style={{ fontSize: "24px", cursor: "pointer" }}
            onClick={() => {
              setIsClientModalVisible(true);
            }}
          >
            +
          </Col>
          <Col span={22}>
            <Form.Item
              name={["appointment", "vehicleId"]}
              initialValue={formData?.vehicleId}
              label={"Vehicle"}
              rules={[{ required: true }]}
            >
              <Select
                style={{ width: "100%" }}
                allowClear
                showSearch
                filterOption={filterOption}
                defaultValue=""
              >
                <Option value=''>Select Vehicle</Option>
                {vehiclesList.map((vehicle) => (
                  <Option key={vehicle.id} value={vehicle.id}>
                    {vehicle.model}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col
            span={2}
            style={{ fontSize: "24px", cursor: "pointer" }}
            onClick={() => {
              form.getFieldValue(["appointment", "clientId"]) &&
                setIsVehicleModalVisible(true);
            }}
          >
            +
          </Col>
          <Col span={22}>
            <Form.Item
              name={["appointment", "appointmentDate"]}
              // defaultValue={moment(formData?.appointmentDate)}
              label={"Date"}
              rules={[{ required: true }]}
              className="site-calendar-customize-header-wrapper"
            >
              <Calendar
                fullscreen={false}
                allowClear={false}
                // defaultValue={moment(formData?.appointmentDate)}
                disabledDate={(current) =>
                  current && current <= moment().endOf("day")
                }
                onChange={(value) => onChangeAppointmentDate(value)}
              />
            </Form.Item>
          </Col>
          <Col span={22}>
            <Form.Item
              name={["appointment", "appointmentTime"]}
              initialValue={formData?.appointmentTime}
              label={"Time"}
              rules={[{ required: true }]}
            >
              {timeSlotUI()}
            </Form.Item>
          </Col>
          <Col span={24} style={{ textAlign: "center" }}>
            <Button type="primary" htmlType="submit" loading={loading}>
              {form.getFieldValue(["appointment", "id"]) ? 'Update' : 'Create'}
            </Button>
            <Button type="primary" loading={loading} onClick={() => onClearFormDetails()} style={{ marginLeft: '20px' }}>
              Cancel
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        title={"Create Client"}
        centered
        destroyOnClose
        visible={isClientModalvisible}
        onOk={() => setIsClientModalVisible(false)}
        onCancel={() => setIsClientModalVisible(false)}
        width={800}
        footer={null}
      >
        <ClientForm setIsClientModalVisible={setIsClientModalVisible} setClientIdInAppointmentForm={setClientIdInAppointmentForm} />
      </Modal>
      <Modal
        title={"Create Vehicle"}
        centered
        destroyOnClose
        visible={isVehicleModalvisible}
        onOk={() => setIsVehicleModalVisible(false)}
        onCancel={() => setIsVehicleModalVisible(false)}
        width={800}
        footer={null}
      >
        <VehicleForm
          setIsVehicleModalVisible={setIsVehicleModalVisible}
          clientId={ClientId}
          setVehicleIdInAppointmentForm={setVehicleIdInAppointmentForm}
        />
      </Modal>
    </>
  );
};

export default AppointmentForm;
