import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link as RouteLink } from 'react-router-dom';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { loginAsync } from '../../actions/auth';
import queryString from 'query-string';
import { useStyles } from "./styles";
import ShopManagerLogo from '../../assets/ShopManagerLogoBlue.png'

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {`© ${new Date().getFullYear()} `}
      <Link color="inherit" href="https://www.corrosiondefence.com/" target="_blank" >
        Corrosion Defense Inc. &nbsp;
      </Link>
      All Rights Reserved
    </Typography>
  );
}

const Login = (props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [remember, setRemember] = useState(true);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const params = queryString.parse(props.location.search);
  const lastPath = params.r;

  useEffect(() => {
    let data = localStorage.getItem('remember');
    if (data) {
      data = JSON.parse(data);
      setEmail(data.email);
      try {
        const decodedPassword = window.atob(data.password);
        setPassword(decodedPassword);
      } catch (e) {
        localStorage.removeItem('remember');
      }
    }
    if (props.userAuth) {
      props.userAuth.roles.indexOf("ROLE_SUPERADMIN") >= 0 ? props.history.push("/admin") : props.history.push("/shop");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeRemember = (event) => {
    setRemember(event.target.checked);
  };

  return (
    <div className={classes.rootContainer}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <img
            className={classes.avatar}
            src={ShopManagerLogo} alt="logo" >
          </img>
          <form 
            className={classes.form} 
            validate="true" 
            onSubmit={(event) => {
              event.preventDefault();
              setLoading(true);
              props.loginAsync({ email, password, remember, lastPath, setLoading });
            }}
          >
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              name="email"
              type="email"
              label={email !== "" ? "Email Address" : "Please enter email"}
              autoComplete="email"
              autoFocus
              onChange={(event) => setEmail(event.target.value)}
              value={email !== null ? email : ''}
              error={email === ""}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setLoading(true);
                  props.loginAsync({ email, password, remember, lastPath, setLoading });
                }
              }}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="password"
              name="password"
              type="password"
              label={password !== "" ? "Password" : "Please enter password"}
              autoComplete="current-password"
              onChange={(event) => setPassword(event.target.value)}
              value={password !== null ? password : ''}
              error={password === ""}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  setLoading(true);
                  props.loginAsync({ email, password, remember, lastPath, setLoading });
                }
              }}
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" checked={remember} onChange={handleChangeRemember} />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Sign In"}
            </Button>
            <Grid container>
              <Grid item xs>
                <RouteLink to="/forgot-password">
                  Forgot password?
                </RouteLink>
              </Grid>
            </Grid>
          </form>
        </div>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    </div>
  );
}

const mapStateToProps = state => {
  return {
    userAuth: state.auth.userAuth
  }
};

const mapDispatchToProps = dispatch => {
  return {
    loginAsync: ({ email, password, remember, lastPath, setLoading }) => dispatch(loginAsync({ email, password, remember, lastPath, setLoading })),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);